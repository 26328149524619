import PropTypes from "prop-types";
import React from "react";
import CardText from "reactstrap/es/CardText";

const CardTextEmpty = (props) => {
  return <CardText className="text-center text-muted">{props.text}</CardText>;
};

CardTextEmpty.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CardTextEmpty;
