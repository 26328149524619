import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faSave } from "@fortawesome/pro-regular-svg-icons/faSave";
import { Field, Form } from "lemo-form";
import { IsEqualToField, IsMatched, IsNotEmpty } from "lemo-validator";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

// App
import { Button, PasswordMinimalRequirements } from "../../core/components";
import { ERROR_PASSWORD_MIN_REQUIREMENTS, ERROR_PASSWORDS_ARE_NOT_EQUALS } from "../../../constants";

import { REGEX_PASSWORD } from "../../../constants/regex";

const ResetPasswordForm = (props) => {
  const { resetPasswordSubmit, resetPasswordSubmitting } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <PasswordMinimalRequirements />
      <Form onSubmit={resetPasswordSubmit}>
        <Field
          addonPrependIcon={faLock}
          label={t("title.newPassword")}
          name="password"
          type="password"
          validateIfChange={["passwordAgain"]}
          validators={[
            new IsNotEmpty(),
            new IsMatched({
              messages: {
                isMatched: ERROR_PASSWORD_MIN_REQUIREMENTS,
              },
              pattern: REGEX_PASSWORD,
            }),
          ]}
        />
        <Field
          addonPrependIcon={faLock}
          label={t("title.newPasswordAgain")}
          name="passwordAgain"
          type="password"
          validateIfChange={["password"]}
          validators={[
            new IsNotEmpty(),
            new IsEqualToField({
              field: "password",
              messages: {
                isEqualToField: ERROR_PASSWORDS_ARE_NOT_EQUALS,
              },
            }),
          ]}
        />
        <div className="text-center">
          <Button executing={resetPasswordSubmitting} icon={faSave} size="lg" text={t("action.saveNewPassword")} />
        </div>
      </Form>
    </Fragment>
  );
};

ResetPasswordForm.propTypes = {
  resetPasswordSubmit: PropTypes.func.isRequired,
  resetPasswordSubmitting: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
