import { faChevronDoubleUp } from "@fortawesome/pro-regular-svg-icons/faChevronDoubleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React from "react";
import { useTranslation } from "react-i18next";
import Col from "reactstrap/es/Col";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Row from "reactstrap/es/Row";

import { formatMac } from "../../../../utilities";

const PortDetail = (props) => {
  const { handleClick, port } = props;
  const { t } = useTranslation();

  function renderEndpoints() {
    const { endpoints } = port;

    if (!endpoints || 0 === endpoints.length) {
      return "-";
    }

    return (
      <ul className="list-unstyled">
        {endpoints.map((endpoint, index) => (
          <li key={index}>
            {formatMac(endpoint.mac)}
            {null !== endpoint.description ? " (" + endpoint.description + ")" : ""}
          </li>
        ))}
      </ul>
    );
  }

  function renderRemoteDevices() {
    const { remoteDevices } = port;

    if (!remoteDevices || 0 === remoteDevices.length) {
      return "-";
    }

    return (
      <ul className="list-unstyled">
        {remoteDevices.map((remoteDevice, index) => (
          <li key={index}>
            {formatMac(remoteDevice.mac)}
            {null !== remoteDevice.name ? " (" + remoteDevice.name + ")" : ""}
          </li>
        ))}
      </ul>
    );
  }

  function renderVlan() {
    const { vlansTagged, vlansUntagged } = port;

    let vlan = "";

    if (null !== vlansUntagged) {
      vlan += "(" + vlansUntagged + ")";
    }

    if (null !== vlansUntagged && null !== vlansTagged) {
      vlan += ",";
    }

    if (null !== vlansTagged) {
      vlan += vlansTagged;
    }

    return vlan;
  }

  return (
    <div id="port-detail">
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label>{t("title.name")}</Label>
            <div>{port.name || "-"}</div>
          </FormGroup>
          <FormGroup>
            <Label>{t("title.description")}</Label>
            <div>{port.description || "-"}</div>
          </FormGroup>
          <FormGroup>
            <Label>{t("title.mode")}</Label>
            <div>{port.mode || "-"}</div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>{t("title.endpoints")}</Label>
            <div>{renderEndpoints()}</div>
          </FormGroup>
          <FormGroup>
            <Label>{t("title.remoteDevices")}</Label>
            <div>{renderRemoteDevices()}</div>
          </FormGroup>
          <FormGroup>
            <Label>{t("title.vlan")}</Label>
            <div>{renderVlan() || "-"}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center text-muted">
          <p onClick={() => handleClick(null)}>
            <small>
              <FontAwesomeIcon icon={faChevronDoubleUp} />
              &nbsp;&nbsp;Skrýt detail portu
            </small>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default PortDetail;
