import PropTypes from "prop-types";
import React from "react";
import FormText from "reactstrap/es/FormText";

const FieldText = (props) => {
  if (!props.text) {
    return null;
  }

  return <FormText color={props.color}>{props.text}</FormText>;
};

FieldText.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
};

export default FieldText;
