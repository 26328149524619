import PropTypes from "prop-types";
import React from "react";

const FormikState = (props) => {
  const { errors, values, touched } = props.formik;

  const short = {
    errors,
    values,
    touched,
  };

  return (
    <div className="my-3">
      <pre>{JSON.stringify(true === props.short ? short : props.formik, null, 4)}</pre>
    </div>
  );
};

FormikState.defaultProps = {
  short: true,
};
FormikState.propTypes = {
  formik: PropTypes.object.isRequired,
  short: PropTypes.bool,
};

export default FormikState;
