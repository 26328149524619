export const URI_DEVICE = "/api/device";
export const URI_DEVICE_X = "/api/device/:id";
export const URI_DEVICE_X_ACCESSRULE = "/api/device/:id/access-rule";
export const URI_DEVICE_X_ACCESSRULE_X = "/api/device/:id/access-rule/:accessRuleId";
export const URI_DEVICE_X_ACCESSRULE_REFRESH = "/api/device/:id/access-rule/refresh";
export const URI_DEVICE_X_FORM = "/api/device/:id/form";
export const URI_DEVICE_X_IMPORT = "/api/device/:id/import";
export const URI_DEVICE_X_IMPORT_FORM = "/api/device/:id/import/form";
export const URI_DEVICE_X_IMPORT_X = "/api/device/:id/import/:importId";
export const URI_DEVICE_X_IMPORT_X_DOWNLOAD = "/api/device/:id/import/:importId/download";
export const URI_DEVICE_X_PORT = "/api/device/:id/port";
export const URI_DEVICE_X_TIMELINE = "/api/device/:id/timeline";
