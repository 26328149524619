import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

const FieldAddon = (props) => {
  if (!props.children && !props.icon && !props.text) {
    return null;
  }

  let content = props.text;
  if (props.icon) {
    content = (
      <FontAwesomeIcon
        fixedWidth={props.iconFixedWidth}
        icon={props.icon}
        size={props.iconSize}
        spin={props.iconSpin}
      />
    );
  }

  return (
    <InputGroupAddon addonType={props.addonType}>
      <InputGroupText>{content}</InputGroupText>
    </InputGroupAddon>
  );
};

FieldAddon.defaultProps = {
  iconFixedWidth: true,
  iconSpin: false,
};
FieldAddon.propTypes = {
  addonType: PropTypes.oneOf(["append", "prepend"]),
  children: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.object]),
  iconFixedWidth: PropTypes.bool,
  iconSize: PropTypes.string,
  iconSpin: PropTypes.bool,
  text: PropTypes.string,
};

export default FieldAddon;
