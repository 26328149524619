import { connect } from "react-redux";

// Device
import { fetchTimeline } from "../actions/deviceTimeline";
import TimelineRows from "../components/detail/TimelineRows";
import { getDataByFilter } from "../selectors/deviceTimeline";

const mapStateToProps = (state) => {
  return {
    rows: getDataByFilter(state),
  };
};

const mapDispatchToProps = {
  onLoad: fetchTimeline,
};

const DeviceTimelineRows = connect(mapStateToProps, mapDispatchToProps)(TimelineRows);

export default DeviceTimelineRows;
