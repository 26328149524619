import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

// App
import { FormikField, FormikFieldSelect } from "../../core/components/formik";
import { validateRequired } from "../../../utilities/validators";

const OrganizationFieldset = (props) => {
  const { formDataParents } = props;
  const { t } = useTranslation();

  return (
    <fieldset name="organization">
      <FormikField label={t("title.name")} name="name" required validate={validateRequired} />
      <FormikFieldSelect
        label={t("title.parent")}
        name="idParent"
        options={formDataParents}
        optionsKeyLabel="nameComposed"
        optionsKeyValue="id"
      />
    </fieldset>
  );
};

OrganizationFieldset.propTypes = {
  formDataParents: PropTypes.array.isRequired,
};

export default OrganizationFieldset;
