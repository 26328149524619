import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormikFieldMulticheckbox } from "../../core/components/formik";

const RoleFieldsetPermissions = (props) => {
  const { permissions } = props;
  const { t } = useTranslation();

  return (
    <fieldset name="permissions">
      <FormikFieldMulticheckbox
        label={t("title.permissions")}
        name="idPermissions"
        options={permissions}
        optionsKeyLabel="permission"
        optionsKeyValue="id"
        required={true}
      />
    </fieldset>
  );
};

RoleFieldsetPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
};

export default RoleFieldsetPermissions;
