import { faNetworkWired } from "@fortawesome/pro-regular-svg-icons/faNetworkWired";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import CustomInput from "reactstrap/es/CustomInput";
import Row from "reactstrap/es/Row";
import Spinner from "reactstrap/es/Spinner";
import Table from "reactstrap/es/Table";

// App
import AlertErrors from "../../core/components/shared/AlertErrors";
import CardBodyHeader from "../../core/components/shared/CardBodyHeader";
import CardTextEmpty from "../../core/components/shared/CardTextEmpty";
import { valueRender } from "../../../utilities";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Dashboard Access Rules
import {
  PERMISSION_DASHBOARDACCESSRULE_READ,
  PERMISSION_DASHBOARDACCESSRULE_UPDATE,
  PERMISSION_DASHBOARDACCESSRULE_VIEW,
} from "../constants/permissions";

const DashboardAccessRules = (props) => {
  const { checkingStateByDeviceUuid, disabledIds, errors, errorsByDeviceUuid, isFetching, items } = props;
  const showTable = !isEmpty(items);
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  // Nacteni seznamu zarizeni a jednotlivych pravidel
  useEffect(() => {
    props.fetchAccessRules();

    return () => {
      props.clearAccessRules();
    };
  }, []);

  // Aktualizace stavu pravidel dle jednotlivych zarizeni
  useEffect(() => {
    if (false === isFetching && !isEmpty(items)) {
      Object.keys(items).map((deviceId) => {
        const device = items[deviceId];

        if (device.uuid) {
          props.checkStateByDevice(device.uuid);
        }

        return false;
      });
    }
  }, [isFetching]);

  // Kontrola opravneni
  if (!hasPermission(PERMISSION_DASHBOARDACCESSRULE_READ) || !hasPermission(PERMISSION_DASHBOARDACCESSRULE_VIEW)) {
    return null;
  }

  function handleChange(data, deviceId, uuid) {
    props.updateAccessRule(data, deviceId, uuid);
  }

  return (
    <Card>
      <CardBody>
        <CardBodyHeader icon={faNetworkWired} title={t("title.firewallAccessRules")} />
        <AlertErrors errors={errors} />
        {showTable ? (
          <Fragment>
            {items &&
              Object.keys(items).map((deviceUuid) => {
                const device = items[deviceUuid];
                const showSpinner = Object.keys(checkingStateByDeviceUuid).includes(deviceUuid);

                return (
                  <Row key={deviceUuid}>
                    <Col>
                      <h6>{device.name}</h6>
                      {errorsByDeviceUuid[deviceUuid] ? (
                        <AlertErrors errors={errorsByDeviceUuid[deviceUuid]} fade={false} />
                      ) : (
                        <Card>
                          <CardBody className="p-0">
                            <Table className="mb-0" size="sm" borderless striped>
                              <thead>
                                <tr>
                                  <th className="w-50">{t("title.ruleName")}</th>
                                  <th className="w-50">{t("title.active")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {device.accessRules &&
                                  Object.keys(device.accessRules).map((accessRuleUuid) => {
                                    const accessRule = device.accessRules[accessRuleUuid];
                                    return (
                                      <tr key={accessRuleUuid}>
                                        <td>{valueRender(accessRule, "name")}</td>
                                        <td>
                                          {showSpinner ? (
                                            <Spinner color="primary" size="sm" />
                                          ) : (
                                            <CustomInput
                                              checked={accessRule.enable}
                                              disabled={
                                                !hasPermission(PERMISSION_DASHBOARDACCESSRULE_UPDATE) ||
                                                disabledIds[accessRuleUuid]
                                              }
                                              id={"enable_" + accessRuleUuid}
                                              onChange={() =>
                                                handleChange({ enable: !accessRule.enable }, deviceUuid, accessRuleUuid)
                                              }
                                              type="switch"
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      )}
                    </Col>
                  </Row>
                );
              })}
          </Fragment>
        ) : (
          <CardTextEmpty text={t("text.noAccessRules")} />
        )}
      </CardBody>
    </Card>
  );
};

DashboardAccessRules.propTypes = {
  checkStateByDevice: PropTypes.func.isRequired,
  checkingStateByDeviceUuid: PropTypes.object.isRequired,
  clearAccessRules: PropTypes.func.isRequired,
  disabledIds: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  errorsByDeviceUuid: PropTypes.object.isRequired,
  fetchAccessRules: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  items: PropTypes.object.isRequired,
  updateAccessRule: PropTypes.func.isRequired,
};

export default DashboardAccessRules;
