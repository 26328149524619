import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "regenerator-runtime/runtime";
import { setLocale, setMessages } from "lemo-validator";
import messages from "lemo-validator/messages/cs";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import * as serviceWorker from "./serviceWorker";

// Containers
import App from "./modules/core/components/App";

// Helpers
import { store } from "./store";

// Locales
import cs from "./locales/default.cs.json";

i18n.init({
  debug: false,
  fallbackLng: "cs",
  interpolation: {
    escapeValue: false,
  },
  lng: "cs",
  resources: {
    cs: {
      translation: cs,
    },
  },
});

setLocale("cs-CZ");
setMessages(messages);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
