import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Modal from "reactstrap/es/Modal";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import ModalHeader from "reactstrap/es/ModalHeader";

// Components
import AlertErrors from "./AlertErrors";
import { Button } from "./Buttons";

class ButtonConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleClickConfirm = this.handleClickConfirm.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  async handleClickCancel(e) {
    e.preventDefault();

    const { buttonNoOnClick } = this.props;

    if (typeof buttonNoOnClick === "function") {
      await buttonNoOnClick();
    } else {
      this.setState({
        isOpen: false,
      });
    }
  }

  async handleClickConfirm(e) {
    e.preventDefault();

    const { buttonYesOnClick } = this.props;

    if (typeof buttonYesOnClick === "function") {
      await buttonYesOnClick();
    } else {
      this.setState({
        isOpen: false,
      });
    }
  }

  handleToggle(e) {
    e.preventDefault();

    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { color, icon, id, disabled, modalBody, modalErrors, modalTitle, outline, size, text, title } = this.props;

    // BUTTON - Yes
    let propsYes = {};
    if (this.props.hasOwnProperty("buttonYesColor")) {
      propsYes.color = this.props.buttonYesColor;
    }
    if (this.props.hasOwnProperty("buttonYesDisabled")) {
      propsYes.disabled = this.props.buttonYesDisabled;
    }
    if (this.props.hasOwnProperty("buttonYesExecuting")) {
      propsYes.executing = this.props.buttonYesExecuting;
    }
    if (this.props.hasOwnProperty("buttonYesIcon")) {
      propsYes.icon = this.props.buttonYesIcon;
    }
    if (this.props.hasOwnProperty("buttonYesOutline")) {
      propsYes.outline = this.props.buttonYesOutline;
    }
    if (this.props.hasOwnProperty("buttonYesSize")) {
      propsYes.size = this.props.buttonYesSize;
    }
    if (this.props.hasOwnProperty("buttonYesText")) {
      propsYes.text = this.props.buttonYesText;
    }
    if (this.props.hasOwnProperty("buttonYesTitle")) {
      propsYes.title = this.props.buttonYesTitle;
    }

    // Button - No
    let propsNo = {};
    if (this.props.hasOwnProperty("buttonNoColor")) {
      propsNo.color = this.props.buttonNoColor;
    }
    if (this.props.hasOwnProperty("buttonNoDisabled")) {
      propsNo.disabled = this.props.buttonNoDisabled;
    }
    if (this.props.hasOwnProperty("buttonNoExecuting")) {
      propsNo.executing = this.props.buttonNoExecuting;
    }
    if (this.props.hasOwnProperty("buttonNoIcon")) {
      propsNo.icon = this.props.buttonNoIcon;
    }
    if (this.props.hasOwnProperty("buttonNoOutline")) {
      propsNo.outline = this.props.buttonNoOutline;
    }
    if (this.props.hasOwnProperty("buttonNoSize")) {
      propsNo.size = this.props.buttonNoSize;
    }
    if (this.props.hasOwnProperty("buttonNoText")) {
      propsNo.text = this.props.buttonNoText;
    }
    if (this.props.hasOwnProperty("buttonNoTitle")) {
      propsNo.title = this.props.buttonNoTitle;
    }

    return (
      <Fragment>
        <Button
          color={color}
          disabled={disabled}
          icon={icon}
          id={id}
          onClick={this.handleToggle}
          outline={outline}
          size={size}
          text={text}
          title={title}
        />
        <Modal
          backdrop="static"
          isOpen={this.state.isOpen}
          onClosed={this.props.modalOnClose}
          onOpened={this.props.modalOnOpen}
          toggle={this.handleToggle}
        >
          {modalTitle && <ModalHeader toggle={this.handleToggle}>{modalTitle}</ModalHeader>}
          <ModalBody>
            <AlertErrors errors={modalErrors} />
            {modalBody}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClickConfirm} {...propsYes} />
            <Button onClick={this.handleClickCancel} {...propsNo} />
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

ButtonConfirm.defaultProps = {
  buttonNoDisabled: false,
  buttonNoExecuting: false,
  buttonYesDisabled: false,
  buttonYesExecuting: false,
  disabled: false,
  modalErrors: [],
};

ButtonConfirm.propTypes = {
  buttonNoColor: PropTypes.string,
  buttonNoDisabled: PropTypes.bool,
  buttonNoExecuting: PropTypes.bool,
  buttonNoIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  buttonNoOnClick: PropTypes.func,
  buttonNoOutline: PropTypes.bool,
  buttonNoSize: PropTypes.string,
  buttonNoText: PropTypes.string,
  buttonNoTitle: PropTypes.string,
  buttonYesColor: PropTypes.string,
  buttonYesDisabled: PropTypes.bool,
  buttonYesOnClick: PropTypes.func,
  buttonYesOutline: PropTypes.bool,
  buttonYesExecuting: PropTypes.bool,
  buttonYesIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  buttonYesSize: PropTypes.string,
  buttonYesText: PropTypes.string,
  buttonYesTitle: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.object,
  modalBody: PropTypes.string.isRequired,
  modalErrors: PropTypes.array,
  modalOnClose: PropTypes.func,
  modalOnOpen: PropTypes.func,
  modalTitle: PropTypes.string,
  outline: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default ButtonConfirm;
