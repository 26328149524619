import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

// App
import { FormikField, FormikFieldSelect } from "../../core/components/formik";
import { validateMacAddress, validateRequired } from "../../../utilities/validators";
import { normalizeInt, normalizeMac } from "../../../utilities/normalizers";

const FieldsetGeneral = (props) => {
  const { dataDeviceManufacturers, dataDeviceModels, dataDeviceModelSeries, dataDeviceTypes } = props;
  const { t } = useTranslation();

  const handleNormalizeMac = (value) => {
    return normalizeMac(value);
  };

  return (
    <fieldset name="device">
      <FormikField label={t("title.name")} name="name" required validate={validateRequired} />
      <FormikFieldSelect
        label={t("title.type")}
        name="idDeviceType"
        normalize={normalizeInt}
        options={dataDeviceTypes}
        optionsKeyLabel="name"
        optionsKeyValue="id"
        required
        validate={validateRequired}
      />
      <FormikFieldSelect
        label={t("title.manufacturer")}
        name="idDeviceManufacturer"
        normalize={normalizeInt}
        options={dataDeviceManufacturers}
        optionsKeyLabel="name"
        optionsKeyValue="id"
        required
        validate={validateRequired}
      />
      <FormikFieldSelect
        label={t("title.modelSeries")}
        name="idDeviceModelSeries"
        normalize={normalizeInt}
        options={dataDeviceModelSeries}
        optionsKeyLabel="name"
        optionsKeyValue="id"
        required
        validate={validateRequired}
      />
      <FormikFieldSelect
        label={t("title.model")}
        name="idDeviceModel"
        normalize={normalizeInt}
        options={dataDeviceModels}
        optionsKeyLabel="name"
        optionsKeyValue="id"
        required
        validate={validateRequired}
      />
      <FormikField label={t("title.mac")} name="mac" normalize={handleNormalizeMac} validate={validateMacAddress} />
      <FormikField label={t({ id: "title.sn" })} name="sn" />
    </fieldset>
  );
};

FieldsetGeneral.propTypes = {
  dataDeviceManufacturers: PropTypes.array.isRequired,
  dataDeviceModels: PropTypes.array.isRequired,
  dataDeviceModelSeries: PropTypes.array.isRequired,
  dataDeviceTypes: PropTypes.array.isRequired,
};

export default FieldsetGeneral;
