import React, { Component } from "react";
import Container from "reactstrap/es/Container";

class ContentBody extends Component {
  render() {
    return (
      <Container id="content-body" fluid>
        {this.props.children}
      </Container>
    );
  }
}

export default ContentBody;
