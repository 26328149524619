import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faEthernet } from "@fortawesome/pro-regular-svg-icons/faEthernet";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Nav from "reactstrap/es/Nav";
import NavItem from "reactstrap/es/NavItem";
import NavLink from "reactstrap/es/NavLink";
import Row from "reactstrap/es/Row";
import TabContent from "reactstrap/es/TabContent";
import TabPane from "reactstrap/es/TabPane";

import { DEVICE_TYPE_FIREWALL, DEVICE_TYPE_SWITCH } from "../../../constants/codeLists";
import CardBodyHeader from "../../core/components/shared/CardBodyHeader";

// App
import {
  ButtonCancel,
  ButtonEdit,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { ROUTE_DEVICE, ROUTE_DEVICE_EDIT } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Device
import { clearDevice, fetchDevice } from "../actions/device";
import {
  PERMISSION_DEVICE_READ,
  PERMISSION_DEVICE_UPDATE,
  PERMISSION_DEVICE_VIEW,
  PERMISSION_DEVICEACCESSRULE_VIEW,
  PERMISSION_DEVICEIMPORT_VIEW,
  PERMISSION_DEVICEPORT_VIEW,
  PERMISSION_DEVICETIMELINE_VIEW,
} from "../constants/permissions";
import DeviceAccessRules from "./DeviceAccessRules";
import DevicePorts from "./DevicePorts";
import General from "../components/detail/General";
import Imports from "../components/detail/Imports";
import Organization from "../components/detail/Organization";
import Timeline from "../components/detail/Timeline";
import { getData, getErrors } from "../selectors/device";

const DevicePageDetail = (props) => {
  const { data, errors, match } = props;
  const { idDeviceType } = data;
  const [activeTab, setActiveTab] = useState("1");
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.devices");
  }, []);

  useEffect(() => {
    props.fetchDevice(match.params.id);

    return () => {
      props.clearDevice();
    };
  }, [match.params.id]);

  function handleToggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  // Kontrola opravneni
  if (!hasPermission(PERMISSION_DEVICE_READ) || !hasPermission(PERMISSION_DEVICE_VIEW)) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faEthernet} title={t("title.devices")} />
        <ContentHeaderButtons>
          {hasPermission(PERMISSION_DEVICE_UPDATE) && (
            <ButtonEdit text={t("action.edit")} to={generatePath(ROUTE_DEVICE_EDIT, { id: match.params.id })} />
          )}
          <ButtonCancel to={ROUTE_DEVICE} text={t("action.close")} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={errors} />

      <ContentBody>
        <Row>
          <Col>
            <Nav pills>
              <NavItem>
                <NavLink
                  active={"1" === activeTab}
                  onClick={() => {
                    handleToggleTab("1");
                  }}
                  href="#"
                >
                  {t("title.information")}
                </NavLink>
              </NavItem>
              {DEVICE_TYPE_SWITCH === idDeviceType && hasPermission(PERMISSION_DEVICETIMELINE_VIEW) && (
                <NavItem>
                  <NavLink
                    active={"2" === activeTab}
                    onClick={() => {
                      handleToggleTab("2");
                    }}
                    href="#"
                  >
                    {t("title.timeline")}
                  </NavLink>
                </NavItem>
              )}
              {DEVICE_TYPE_SWITCH === idDeviceType && hasPermission(PERMISSION_DEVICEIMPORT_VIEW) && (
                <NavItem>
                  <NavLink
                    active={"3" === activeTab}
                    onClick={() => {
                      handleToggleTab("3");
                    }}
                    href="#"
                  >
                    {t("title.imports")}
                  </NavLink>
                </NavItem>
              )}
              {DEVICE_TYPE_FIREWALL === idDeviceType && hasPermission(PERMISSION_DEVICEACCESSRULE_VIEW) && (
                <NavItem>
                  <NavLink
                    active={"4" === activeTab}
                    onClick={() => {
                      handleToggleTab("4");
                    }}
                    href="#"
                  >
                    {t("title.accessRules")}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardBodyHeader icon={faFile} title={t("title.general")} />
                        <General data={data} />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardBodyHeader icon={faBuilding} title={t("title.location")} />
                        <Organization data={data} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {DEVICE_TYPE_SWITCH === idDeviceType && hasPermission(PERMISSION_DEVICEPORT_VIEW) && (
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <CardBodyHeader icon={faEthernet} title={t("title.ports")} />
                          <DevicePorts deviceId={match.params.id} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </TabPane>
              {DEVICE_TYPE_SWITCH === idDeviceType && hasPermission(PERMISSION_DEVICETIMELINE_VIEW) && (
                <TabPane tabId="2">
                  <Timeline deviceId={match.params.id} />
                </TabPane>
              )}
              {DEVICE_TYPE_SWITCH === idDeviceType && hasPermission(PERMISSION_DEVICEIMPORT_VIEW) && (
                <TabPane tabId="3">
                  <Imports deviceId={match.params.id} />
                </TabPane>
              )}
              {DEVICE_TYPE_FIREWALL === idDeviceType && hasPermission(PERMISSION_DEVICEACCESSRULE_VIEW) && (
                <TabPane tabId="4">
                  <DeviceAccessRules deviceId={match.params.id} />
                </TabPane>
              )}
            </TabContent>
          </Col>
        </Row>
      </ContentBody>
    </Fragment>
  );
};

DevicePageDetail.propTypes = {
  clearDevice: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  fetchDevice: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  clearDevice,
  fetchDevice,
};

const mapStateToProps = (state) => ({
  data: getData(state),
  errors: getErrors(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicePageDetail);
