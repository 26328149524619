import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

// App
import { FormikField } from "../../core/components/formik";
import { validateRequired } from "../../../utilities/validators";

const FieldsetAPI = (props) => {
  const { passwordRequired } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <FormikField label={t("title.apiUri")} name="apiUri" required={true} type="url" validate={validateRequired} />
      <FormikField
        label={t("title.apiUsernameRead")}
        name="apiUsernameRead"
        required={true}
        validate={validateRequired}
      />
      <FormikField
        label={t("title.apiPasswordRead")}
        name="apiPasswordRead"
        required={passwordRequired}
        text={!passwordRequired ? t("text.fillInOnlyWhenSettingANewPassword") : null}
        type="password"
        validate={passwordRequired ? validateRequired : undefined}
      />
      <FormikField
        label={t("title.apiUsernameWrite")}
        name="apiUsernameWrite"
        required={true}
        validate={validateRequired}
      />
      <FormikField
        label={t("title.apiPasswordWrite")}
        name="apiPasswordWrite"
        required={passwordRequired}
        text={!passwordRequired ? t("text.fillInOnlyWhenSettingANewPassword") : null}
        type="password"
        validate={passwordRequired ? validateRequired : undefined}
      />
    </Fragment>
  );
};

FieldsetAPI.propTypes = {
  passwordRequired: PropTypes.bool.isRequired,
};

export default FieldsetAPI;
