import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Button from "reactstrap/es/Button";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// Components
import ContentBody from "./shared/ContentBody";
import ContentHeader from "./shared/ContentHeader";
import ContentHeaderTitle from "./shared/ContentHeaderTitle";

// Constants
import { ROUTE_DASHBOARD } from "../../../constants";

// Hooks
import useTitle from "../hooks/useTitle";

const Error403 = () => {
  const { t } = useTranslation();

  // Titulek
  useTitle(t("title.error403"));

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faLock} title={t("title.error403")} />
      </ContentHeader>
      <ContentBody>
        <Row>
          <Col>
            <Card outline color="danger">
              <CardBody className="text-center">
                <p className="text-danger">{t("text.youDoNotHavePermissionToAccessTheRequestedPage")}</p>
                <Button color="danger" onClick={this.props.history.goBack}>
                  <FontAwesomeIcon fixedWidth icon={faArrowLeft} /> {t("action.goToBack")}
                </Button>{" "}
                <Button color="danger" tag={Link} to={ROUTE_DASHBOARD}>
                  <FontAwesomeIcon fixedWidth icon={faHome} /> {t("action.goToDefaultPage")}
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ContentBody>
    </Fragment>
  );
};

export default withRouter(Error403);
