// App
import { appendUrlParams, fetchGet } from "../../../utilities";

// Device
import {
  DEVICEGRID_CHANGE_FILTER,
  DEVICEGRID_CHANGE_PAGINATION,
  DEVICEGRID_CHANGE_SORT,
  DEVICEGRID_FETCH,
  DEVICEGRID_FETCH_ERROR,
  DEVICEGRID_FETCH_SUCCESS,
} from "../constants/actionTypes";
import { URI_DEVICE } from "../constants/uris";

// Change - Filter
export const changeFilter = (filters) => {
  return (dispatch) => {
    dispatch({
      type: DEVICEGRID_CHANGE_FILTER,
      payload: {
        filters: filters,
      },
    });
  };
};

// Change - Pagination
export const changePagination = (page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: DEVICEGRID_CHANGE_PAGINATION,
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  };
};

// Change - Sort
export const changeSort = (sortField, sortOrder) => {
  return (dispatch) => {
    dispatch({
      type: DEVICEGRID_CHANGE_SORT,
      payload: {
        sortField: sortField,
        sortOrder: sortOrder,
      },
    });
  };
};

// Fetch - Devices
export const fetchDevices = () => {
  return (dispatch, getState) => {
    const state = getState();

    if (false === state.deviceGrid.isFetching) {
      dispatch({
        type: DEVICEGRID_FETCH,
      });

      const urlParams = appendUrlParams(
        state.deviceGrid.filters,
        state.deviceGrid.page,
        state.deviceGrid.pageSize,
        state.deviceGrid.sortField,
        state.deviceGrid.sortOrder
      );

      fetchGet(URI_DEVICE + urlParams)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICEGRID_FETCH_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: DEVICEGRID_FETCH_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICEGRID_FETCH_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};
