import {
  DEVICEIMPORTS_FETCH,
  DEVICEIMPORTS_FETCH_ERROR,
  DEVICEIMPORTS_FETCH_OK,
  DEVICEIMPORTS_RESET,
} from "../constants/actionTypes";

const initialState = {
  data: [],
  errors: [],
  isFetching: false,
  offset: 0,
};

const deviceImports = (state = initialState, action) => {
  switch (action.type) {
    case DEVICEIMPORTS_RESET:
      return initialState;
    case DEVICEIMPORTS_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case DEVICEIMPORTS_FETCH_ERROR:
      return {
        ...state,
        data: [],
        errors: action.payload.errors,
        isFetching: false,
      };
    case DEVICEIMPORTS_FETCH_OK:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        errors: [],
        isFetching: false,
        offset: action.payload.offset,
      };
    default:
      return state;
  }
};

export default deviceImports;
