import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React from "react";
import { useTranslation } from "react-i18next";

const TimelineRowPort = (props) => {
  const { ports } = props;
  const { t } = useTranslation();

  if (!ports.added && !ports.removed) {
    return null;
  }

  return (
    <div className="timeline-block">
      <b>{t("title.port")}:</b>
      <hr />
      <ul>
        {ports.added &&
          ports.added.map((port, index) => (
            <li key={index} className="text-success">
              <FontAwesomeIcon icon={faPlus} /> {port.name} {port.description && "(" + port.description + ") "}
            </li>
          ))}
        {ports.removed &&
          ports.removed.map((port, index) => (
            <li key={index} className="text-danger">
              <FontAwesomeIcon icon={faMinus} /> {port.name} {port.description && "(" + port.description + ") "}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TimelineRowPort;
