import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React from "react";
import { useTranslation } from "react-i18next";

const TimelineRowVlan = (props) => {
  const { vlans } = props;
  const { t } = useTranslation();

  if (!vlans.added && !vlans.removed) {
    return null;
  }

  return (
    <div className="timeline-block">
      <b>{t("title.vlan")}:</b>
      <hr />
      <ul>
        {vlans.added &&
          vlans.added.map((vlan, index) => (
            <li key={index} className="text-success">
              <FontAwesomeIcon icon={faPlus} /> {vlan.number} {vlan.name && "(" + vlan.name + ") "}
            </li>
          ))}
        {vlans.removed &&
          vlans.removed.map((vlan, index) => (
            <li key={index} className="text-danger">
              <FontAwesomeIcon icon={faMinus} /> {vlan.number} {vlan.name && "(" + vlan.name + ") "}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TimelineRowVlan;
