import { faRepeatAlt } from "@fortawesome/pro-regular-svg-icons/faRepeatAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Button from "reactstrap/es/Button";

class Spinner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadVisible: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.timer = setTimeout(this.toggleButton.bind(this), 7500);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  toggleButton() {
    this.setState({
      realoadVisible: true,
    });
  }

  handleRefreshPage() {
    window.location.reload();
  }

  render() {
    const { text } = this.props;
    const { realoadVisible } = this.state;

    return (
      <Fragment>
        <div id="spinner-container">
          <div id="spinner" />
        </div>
        <div id="spinner-info">
          <p>{text}</p>
          {realoadVisible && (
            <Fragment>
              <p>Probíhá načítání podezřele dlouho?</p>
              <Button onClick={this.handleRefreshPage} color="primary">
                <FontAwesomeIcon icon={faRepeatAlt} fixedWidth /> Zkusit načíst znovu
              </Button>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

Spinner.defaultProps = {
  text: "Načítám",
};

Spinner.propTypes = {
  text: PropTypes.string,
};

export default Spinner;
