// ROLE - Form
export const ROLE_FORM_CLEAR = "ROLE_FORM_CLEAR";
export const ROLE_FORM_INIT_REQUEST = "ROLE_FORM_INIT_REQUEST";
export const ROLE_FORM_INIT_RESPONSE_ERROR = "ROLE_FORM_INIT_RESPONSE_ERROR";
export const ROLE_FORM_INIT_RESPONSE_SUCCESS = "ROLE_FORM_INIT_RESPONSE_SUCCESS";
export const ROLE_FORM_FETCH_REQUEST = "ROLE_FORM_FETCH_REQUEST";
export const ROLE_FORM_FETCH_RESPONSE_ERROR = "ROLE_FORM_FETCH_RESPONSE_ERROR";
export const ROLE_FORM_FETCH_RESPONSE_SUCCESS = "ROLE_FORM_FETCH_RESPONSE_SUCCESS";
export const ROLE_FORM_SUBMIT_REQUEST = "ROLE_FORM_SUBMIT_REQUEST";
export const ROLE_FORM_SUBMIT_RESPONSE_ERROR = "ROLE_FORM_SUBMIT_RESPONSE_ERROR";
export const ROLE_FORM_SUBMIT_RESPONSE_SUCCESS = "ROLE_FORM_SUBMIT_RESPONSE_SUCCESS";

// ROLE - Grid
export const ROLE_GRID_CHANGE_FILTER = "ROLE_GRID_CHANGE_FILTER";
export const ROLE_GRID_CHANGE_PAGINATION = "ROLE_GRID_CHANGE_PAGINATION";
export const ROLE_GRID_CHANGE_SORT = "ROLE_GRID_CHANGE_SORT";
export const ROLE_GRID_DELETE_REQUEST = "ROLE_GRID_DELETE_REQUEST";
export const ROLE_GRID_DELETE_RESPONSE_ERROR = "ROLE_GRID_DELETE_RESPONSE_ERROR";
export const ROLE_GRID_DELETE_RESPONSE_SUCCESS = "ROLE_GRID_DELETE_RESPONSE_SUCCESS";
export const ROLE_GRID_FETCH_REQUEST = "ROLE_GRID_FETCH_REQUEST";
export const ROLE_GRID_FETCH_RESPONSE_ERROR = "ROLE_GRID_FETCH_RESPONSE_ERROR";
export const ROLE_GRID_FETCH_RESPONSE_SUCCESS = "ROLE_GRID_FETCH_RESPONSE_SUCCESS";
