import {
  DEVICEPORTS_CLEAR,
  DEVICEPORTS_FETCH,
  DEVICEPORTS_FETCH_ERROR,
  DEVICEPORTS_FETCH_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  errors: [],
  isFetching: false,
  items: [],
};

const devicePorts = (state = initialState, action) => {
  switch (action.type) {
    case DEVICEPORTS_CLEAR:
      return initialState;
    case DEVICEPORTS_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case DEVICEPORTS_FETCH_ERROR:
      return {
        ...state,
        items: [],
        errors: action.payload,
        isFetching: false,
      };
    case DEVICEPORTS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload,
        errors: [],
        isFetching: false,
      };
    default:
      return state;
  }
};

export default devicePorts;
