import { ACTION_FETCH, ACTION_FETCH_ERROR, ACTION_FETCH_OK } from "../actions/appConfig";

const initialState = {
  data: {},
  errors: [],
  isError: false,
  isFetched: false,
  isFetching: false,
};

const appConfig = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FETCH:
      return {
        ...state,
        isFetched: false,
        isFetching: true,
      };
    case ACTION_FETCH_OK:
      return {
        ...state,
        data: action.payload.data,
        errors: [],
        isError: false,
        isFetched: true,
        isFetching: false,
      };
    case ACTION_FETCH_ERROR:
      return {
        ...state,
        data: {},
        errors: action.payload.errors,
        isError: true,
        isFetched: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default appConfig;
