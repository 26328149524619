import {
  DEVICEACCESSRULES_CLEAR,
  DEVICEACCESSRULES_FETCH,
  DEVICEACCESSRULES_FETCH_ERROR,
  DEVICEACCESSRULES_FETCH_OK,
  DEVICEACCESSRULES_REFRESH,
  DEVICEACCESSRULES_REFRESH_ERROR,
  DEVICEACCESSRULES_REFRESH_OK,
  DEVICEACCESSRULES_SUBMIT,
  DEVICEACCESSRULES_SUBMIT_ERROR,
  DEVICEACCESSRULES_SUBMIT_OK,
} from "../constants/actionTypes";

const initialState = {
  disabledIds: {},
  errors: [],
  isFetching: false,
  isRefreshing: false,
  isSubmitting: false,
  items: [],
};

const deviceAccessRules = (state = initialState, action) => {
  switch (action.type) {
    // CLEAR
    case DEVICEACCESSRULES_CLEAR:
      return initialState;

    // FETCH
    case DEVICEACCESSRULES_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case DEVICEACCESSRULES_FETCH_ERROR:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: false,
        items: [],
      };
    case DEVICEACCESSRULES_FETCH_OK:
      return {
        ...state,
        errors: [],
        isFetching: false,
        items: action.payload.items,
      };

    // REFRESH
    case DEVICEACCESSRULES_REFRESH:
      return {
        ...state,
        isRefreshing: true,
      };
    case DEVICEACCESSRULES_REFRESH_ERROR:
      return {
        ...state,
        errors: action.payload.errors,
        isRefreshing: false,
        items: [],
      };
    case DEVICEACCESSRULES_REFRESH_OK:
      return {
        ...state,
        errors: [],
        isRefreshing: false,
        items: action.payload.items,
      };

    // SUBMIT
    case DEVICEACCESSRULES_SUBMIT:
      return {
        ...state,
        disabledIds: { ...state.disabledIds, ...{ [action.payload.id]: true } },
        isSubmitting: true,
      };
    case DEVICEACCESSRULES_SUBMIT_ERROR:
      delete state.disabledIds[action.payload.id];

      return {
        ...state,
        errors: action.payload.errors,
        isSubmitting: false,
      };
    case DEVICEACCESSRULES_SUBMIT_OK:
      delete state.disabledIds[action.payload.id];

      return {
        ...state,
        errors: [],
        isSubmitting: false,
        items: state.items.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload.item } : item)),
      };
    default:
      return state;
  }
};

export default deviceAccessRules;
