import { generatePath } from "react-router";

// App
import { fetchGet } from "../../../utilities";

// Device
import {
  DEVICETIMELINE_CHANGE_FILTER,
  DEVICETIMELINE_FETCH,
  DEVICETIMELINE_FETCH_SUCCESS,
  DEVICETIMELINE_FETCH_ERROR,
} from "../constants/actionTypes";
import { URI_DEVICE_X_TIMELINE } from "../constants/uris";

// CHANGE - Filter
export const changeFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: DEVICETIMELINE_CHANGE_FILTER,
      payload: filter,
    });
  };
};

// FETCH - Timeline
export const fetchTimeline = (id) => {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.deviceTimeline.isFetching) {
      dispatch({
        type: DEVICETIMELINE_FETCH,
      });

      fetchGet(generatePath(URI_DEVICE_X_TIMELINE, { id: id }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICETIMELINE_FETCH_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: DEVICETIMELINE_FETCH_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICETIMELINE_FETCH_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};
