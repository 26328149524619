import get from "lodash/get";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import Col from "reactstrap/es/Col";
import Container from "reactstrap/es/Container";
import Row from "reactstrap/es/Row";

// Selectors
import { appConfigData } from "../../../selectors/appConfig";

const Footer = () => {
  const appConfig = useSelector(appConfigData);

  let web = get(appConfig, "web").replace(/^(?:https?:\/\/)?/i, "");

  return (
    <footer>
      <Container>
        <Row>
          <Col>
            <span className="text">
              © {moment().format("Y")} {get(appConfig, "company")}
            </span>
            <span className="separator">|</span>
            <span className="text">
              <a href={get(appConfig, "web")} target="_blank" rel="noopener noreferrer">
                {web}
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
