import get from "lodash/get";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { appConfigData } from "../../../selectors/appConfig";

const Footer = () => {
  const appConfig = useSelector(appConfigData);

  const company = get(appConfig, "company");

  return (
    <span className="text-muted">
      © {moment().format("Y")} {company}
    </span>
  );
};

export default Footer;
