import {
  DEVICEIMPORT_CLEAR,
  DEVICEIMPORT_DELETE,
  DEVICEIMPORT_DELETE_ERROR,
  DEVICEIMPORT_DELETE_SUCCESS,
  DEVICEIMPORT_MODAL_CLEAR,
  DEVICEIMPORT_MODAL_INIT,
  DEVICEIMPORT_MODAL_INIT_ERROR,
  DEVICEIMPORT_MODAL_INIT_SUCCESS,
  DEVICEIMPORT_MODAL_SUBMIT,
  DEVICEIMPORT_MODAL_SUBMIT_ERROR,
  DEVICEIMPORT_MODAL_SUBMIT_SUCCESS,
  DEVICEIMPORT_MODAL_TOGGLE,
} from "../constants/actionTypes";

const initialState = {
  errors: [],
  isDeleting: false,
  isFetching: false,
  modalDataId: null,
  modalDataParsers: [],
  modalErrors: [],
  modalIsInitializing: false,
  modalIsOpen: false,
  modalIsSubmitting: false,
};

const deviceImport = (state = initialState, action) => {
  switch (action.type) {
    // CLEAR
    case DEVICEIMPORT_CLEAR:
      return initialState;

    // DELETE
    case DEVICEIMPORT_DELETE:
      return {
        ...state,
        isDeleting: true,
      };
    case DEVICEIMPORT_DELETE_ERROR:
      return {
        ...state,
        isDeleting: false,
        errors: action.payload,
      };
    case DEVICEIMPORT_DELETE_SUCCESS:
      if (state.data.length > 0) {
        const indexToDelete = state.data.findIndex((file) => {
          return file.uuid === action.payload.uuid;
        });

        state.data.splice(indexToDelete, 1);
      }

      return {
        ...state,
        isDeleting: false,
        errors: [],
      };

    // MODAL - Clear
    case DEVICEIMPORT_MODAL_CLEAR:
      return {
        ...state,
        modalDataParsers: [],
        modalErrors: [],
        modalIsInitializing: false,
        modalIsSubmitting: false,
      };

    // MODAL - Init
    case DEVICEIMPORT_MODAL_INIT:
      return {
        ...state,
        modalIsInitializing: true,
      };
    case DEVICEIMPORT_MODAL_INIT_ERROR:
      return {
        ...state,
        modalDataParsers: [],
        modalIsInitializing: false,
      };
    case DEVICEIMPORT_MODAL_INIT_SUCCESS:
      return {
        ...state,
        modalDataParsers: action.payload.parsers,
        modalIsInitializing: false,
      };

    // MODAL - Submit
    case DEVICEIMPORT_MODAL_SUBMIT:
      return {
        ...state,
        modalErrors: [],
        modalIsSubmitting: true,
      };
    case DEVICEIMPORT_MODAL_SUBMIT_ERROR:
      return {
        ...state,
        modalErrors: action.payload,
        modalIsOpen: true,
        modalIsSubmitting: false,
      };
    case DEVICEIMPORT_MODAL_SUBMIT_SUCCESS:
      return {
        ...state,
        modalIsOpen: false,
        modalIsSubmitting: false,
      };

    // MODAL - Toggle
    case DEVICEIMPORT_MODAL_TOGGLE:
      return {
        ...state,
        modalDataId: action.payload.id,
        modalIsOpen: action.payload.isOpen,
      };
    default:
      return state;
  }
};

export default deviceImport;
