export const FORMAT_BIRTHNUMBER = "FORMAT_BIRTHNUMBER";
export const FORMAT_BOOLEAN = "FORMAT_BOOLEAN";
export const FORMAT_CURRENCY = "FORMAT_CURRENCE";
export const FORMAT_DATE = "FORMAT_DATE";
export const FORMAT_DATETIME = "FORMAT_DATETIME";
export const FORMAT_EMAIL = "FORMAT_EMAIL";
export const FORMAT_NEWLINE = "FORMAT_NEWLINE";
export const FORMAT_NEWLINE_ADDRESS = "FORMAT_NEWLINE_ADDRESS";
export const FORMAT_NEWLINE_ARRAY = "FORMAT_NEWLINE_ARRAY";
export const FORMAT_LICENCEPLATE = "FORMAT_LICENCEPLATE";
export const FORMAT_NUMBER = "FORMAT_NUMBER";
export const FORMAT_PHONE = "FORMAT_PHONE";
export const FORMAT_PHONE_SHORT = "FORMAT_PHONE_SHORT";
export const FORMAT_TIME = "FORMAT_TIME";
