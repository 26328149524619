import { Field, getIn } from "formik";
import PropTypes from "prop-types";
import React from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Input from "reactstrap/es/Input";
import InputGroup from "reactstrap/es/InputGroup";

import FieldAddon from "./FieldAddon";
import FieldFeedback from "./FieldFeedback";
import FieldLabel from "./FieldLabel";
import FieldText from "./FieldText";

const FormikField = (props) => {
  const { addonAppend, addonPrepend, label, name, normalize, required, text, validate, visible, ...attributes } = props;

  if (!visible) {
    return null;
  }

  let id = props.id || name;

  return (
    <Field
      name={name}
      validate={validate}
      render={({ field, form }) => {
        const error = getIn(form.errors, name);
        const touched = getIn(form.touched, name);

        return (
          <FormGroup>
            <FieldLabel for={id} label={label} required={required} />
            <InputGroup>
              {addonPrepend && <FieldAddon {...addonPrepend} addonType="prepend" />}
              <Input
                {...field}
                {...attributes}
                id={name}
                invalid={touched && !!error}
                onChange={(e) => {
                  const val = normalize ? normalize(e.target.value) : e.target.value;
                  field.onChange(e.target.name)(val);
                }}
              />
              {addonAppend && <FieldAddon {...addonAppend} addonType="append" />}
              {touched && error && <FieldFeedback error={error} />}
            </InputGroup>
            {text && <FieldText text={text} />}
          </FormGroup>
        );
      }}
    />
  );
};

FormikField.defaultProps = {
  required: false,
  visible: true,
};
FormikField.propTypes = {
  addonAppend: PropTypes.shape({
    children: PropTypes.node,
    icon: PropTypes.object,
    iconFixedWidth: PropTypes.bool,
    iconSize: PropTypes.string,
    iconSpin: PropTypes.bool,
    text: PropTypes.string,
  }),
  addonPrepend: PropTypes.shape({
    children: PropTypes.node,
    icon: PropTypes.object,
    iconFixedWidth: PropTypes.bool,
    iconSize: PropTypes.string,
    iconSpin: PropTypes.bool,
    text: PropTypes.string,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  plaintext: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  valid: PropTypes.bool,
  visible: PropTypes.bool,
};

export default FormikField;
