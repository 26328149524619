import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import CardHeader from "reactstrap/es/CardHeader";

// App
import { AlertErrors } from "../../core/components";
import { ROUTE_AUTH_SIGNIN } from "../../../constants";
import { isGuid } from "../../../utilities";

// Auth
import { resetPasswordClear, resetPasswordSubmit } from "../actions/auth";
import { ResetPasswordForm, ResetPasswordSuccess } from "../components";

// Hooks
import useTitle from "../../core/hooks/useTitle";

const ResetPasswordPage = (props) => {
  const { match, resetPasswordErrors, resetPasswordSubmitting, resetPasswordSubmittingResult } = props;
  const { t } = useTranslation();

  // Titulek
  useTitle(t("title.resetPassword"));

  useEffect(() => {
    return () => {
      props.resetPasswordClear();
    };
  }, []);

  function handleSubmit(formApi) {
    props.resetPasswordSubmit(match.params.hash, {
      password: formApi.getValue("password"),
    });
  }

  // Chybi token, presmerujeme na prihlaseni
  if (!isGuid(match.params.hash)) {
    return <Redirect to={ROUTE_AUTH_SIGNIN} />;
  }

  return (
    <div id="reset-password" className="container-450">
      <Card>
        <CardHeader>
          <h1>{t("title.resetPassword")}</h1>
        </CardHeader>
        <CardBody>
          <AlertErrors errors={resetPasswordErrors} />
          {resetPasswordSubmittingResult ? (
            <ResetPasswordSuccess />
          ) : (
            <ResetPasswordForm resetPasswordSubmit={handleSubmit} resetPasswordSubmitting={resetPasswordSubmitting} />
          )}
        </CardBody>
        <CardFooter className="text-center">
          <Link to={ROUTE_AUTH_SIGNIN}>{t("action.backToSignIn")}</Link>
        </CardFooter>
      </Card>
    </div>
  );
};

ResetPasswordPage.propTypes = {
  resetPasswordClear: PropTypes.func.isRequired,
  resetPasswordErrors: PropTypes.array.isRequired,
  resetPasswordSubmit: PropTypes.func.isRequired,
  resetPasswordSubmitting: PropTypes.bool.isRequired,
  resetPasswordSubmittingResult: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  resetPasswordSubmit,
  resetPasswordClear,
};

const mapStateToProps = (state) => {
  return {
    resetPasswordErrors: state.auth.resetPasswordErrors,
    resetPasswordSubmitting: state.auth.resetPasswordSubmitting,
    resetPasswordSubmittingResult: state.auth.resetPasswordSubmittingResult,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
