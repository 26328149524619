const validatorMessages = {
  isAlpha: "Hodnota musí obsahovat pouze písmena.",
  isAlphanumeric: "Hodnota musí obsahovat pouze písmena a číslice.",
  isBirthNumber: "Hodnota musí být platné rodné číslo.",
  isDate: "Hodnota musí být platné datum.",
  isDateFormat: "Hodnota musí být platné datum dle formátu :format.",
  isEmail: "Hodnota musí být platná emailová adresa.",
  isEmpty: "Hodnota nesmí být vyplněna.",
  isEqual: "Hodnota nesmí být shodná s :value.",
  isEqualToField: "Hodnota nesmí být shodná s polem :field.",
  isFile: "Hodnota musí být soubor.",
  isIdentificationNumber: "Hodnota musí být platné IČ.",
  isInt: "Hodnota musí být číslo.",
  isLength: "Hodnota musí mít :max znaky.",
  isLengthBetween: "Hodnota musí mít :min až :max znaky.",
  isLengthLong: "Hodnota musí mít maximálně :max znaky.",
  isLengthShort: "Hodnota musí mít minimálně :min znaky.",
  isMACAddress: "Hodnota musí být platná MAC adresa.",
  isMatched: "Hodnota musí odpovídat šabloně.",
  isNotEmpty: "Hodnota musí být vyplněna.",
  isNotEqual: "Hodnota musí být shodná s :value.",
  isNotEqualToField: "Hodnota musí být shodná s polem :field.",
  isPostalCode: "Hodnota musí být platné PSČ.",
  isURL: "Hodnota musí být platná URL adresa.",
};

export default validatorMessages;
