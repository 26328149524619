// AUTH - Forgot password
export const AUTH_FORGOTPASSWORD_CLEAR = "AUTH_FORGOTPASSWORD_CLEAR";
export const AUTH_FORGOTPASSWORD_REQUEST = "AUTH_FORGOTPASSWORD_REQUEST";
export const AUTH_FORGOTPASSWORD_RESPONSE_ERROR = "AUTH_FORGOTPASSWORD_RESPONSE_ERROR";
export const AUTH_FORGOTPASSWORD_RESPONSE_SUCCESS = "AUTH_FORGOTPASSWORD_RESPONSE_SUCCESS";

// AUTH - Refresh
export const AUTH_REFRESH_CLEAR = "AUTH_REFRESH_CLEAR";
export const AUTH_REFRESH_REQUEST = "AUTH_REFRESH_REQUEST";
export const AUTH_REFRESH_RESPONSE_ERROR = "AUTH_REFRESH_RESPONSE_ERROR";
export const AUTH_REFRESH_RESPONSE_SUCCESS = "AUTH_REFRESH_RESPONSE_SUCCESS";

// AUTH - Reset password
export const AUTH_RESETPASSWORD_CLEAR = "AUTH_RESETPASSWORD_CLEAR";
export const AUTH_RESETPASSWORD_REQUEST = "AUTH_RESETPASSWORD_REQUEST";
export const AUTH_RESETPASSWORD_RESPONSE_ERROR = "AUTH_RESETPASSWORD_RESPONSE_ERROR";
export const AUTH_RESETPASSWORD_RESPONSE_SUCCESS = "AUTH_RESETPASSWORD_RESPONSE_SUCCESS";

// AUTH - Sign in
export const AUTH_SIGNIN_REQUEST = "AUTH_SIGNIN_REQUEST";
export const AUTH_SIGNIN_CLEAR = "AUTH_SIGNIN_CLEAR";
export const AUTH_SIGNIN_RESPONSE_ERROR = "AUTH_SIGNIN_RESPONSE_ERROR";
export const AUTH_SIGNIN_RESPONSE_SUCCESS = "AUTH_SIGNIN_RESPONSE_SUCCESS";

// AUTH - Sign out
export const AUTH_SIGNOUT_REQUEST = "AUTH_SIGNOUT_REQUEST";
export const AUTH_SIGNOUT_RESPONSE_ERROR = "AUTH_SIGNOUT_RESPONSE_ERROR";
export const AUTH_SIGNOUT_RESPONSE_SUCCESS = "AUTH_SIGNOUT_RESPONSE_SUCCESS";

// AUTH - Verify
export const AUTH_VERIFY_REQUEST = "AUTH_VERIFY_REQUEST";
export const AUTH_VERIFY_RESPONSE_ERROR = "AUTH_VERIFY_RESPONSE_ERROR";
export const AUTH_VERIFY_RESPONSE_SUCCESS = "AUTH_VERIFY_RESPONSE_SUCCESS";
