import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Table from "reactstrap/es/Table";
import { connect } from "react-redux";

// App
import { ButtonDeleteConfirm, ButtonDownload, ButtonUpload } from "../../../core/components";
import { formatDateTime, valueRender } from "../../../../utilities";
import CardTextEmpty from "../../../core/components/shared/CardTextEmpty";
import {
  PERMISSION_DEVICEIMPORT_CREATE,
  PERMISSION_DEVICEIMPORT_DELETE,
  PERMISSION_DEVICEIMPORT_DOWNLOAD,
} from "../../constants/permissions";

// Auth
import { usePermission } from "../../../auth/hooks/usePermission";

// Device
import ModalImport from "../../containers/DeviceModalImport";

// Device - Imports
import { clearImport, deleteImport, downloadImport, openModal } from "../../actions/deviceImport";
import { fetchImports, resetImports } from "../../actions/deviceImports";
import { getData, getErrors, getIsDeleting, getOffset } from "../../selectors/deviceImports";

const Imports = (props) => {
  const { data, deviceId, isDeleting, offset } = props;
  const showTable = data.length > 0;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchImports(deviceId, offset);

    return () => {
      props.resetImports();
    };
  }, []);

  function handleDelete(deviceId, importId) {
    props.deleteImport(deviceId, importId);
  }

  function handleDeleteClear() {
    props.clearImport();
  }

  function handleDownload(deviceId, importId) {
    props.downloadImport(deviceId, importId);
  }

  function handleLoadMore(e) {
    e.preventDefault();

    let { deviceId, offset } = props;

    props.fetchImports(deviceId, offset + 100);
  }

  function handleOpenModal(deviceId) {
    props.openModal(deviceId);
  }

  return (
    <Card>
      <CardBody>
        {hasPermission(PERMISSION_DEVICEIMPORT_CREATE) && (
          <Fragment>
            <div className="text-center">
              <ButtonUpload
                color="primary"
                onClick={() => handleOpenModal(deviceId)}
                outline={false}
                text={t("action.uploadNewFile")}
              />
            </div>
            <hr />
          </Fragment>
        )}
        {showTable ? (
          <Table className="mb-0" size="sm" borderless striped>
            <thead>
              <tr>
                <th>{t("title.date")}</th>
                <th>{t("title.parser")}</th>
                {hasPermission(PERMISSION_DEVICEIMPORT_DOWNLOAD) && hasPermission(PERMISSION_DEVICEIMPORT_DELETE) && (
                  <th>{t("title.actions")}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  <td>{formatDateTime(valueRender(row, "datetime"))}</td>
                  <td>{valueRender(row, "parser")}</td>
                  {hasPermission(PERMISSION_DEVICEIMPORT_DOWNLOAD) && hasPermission(PERMISSION_DEVICEIMPORT_DELETE) && (
                    <td className="text-right">
                      {hasPermission(PERMISSION_DEVICEIMPORT_DOWNLOAD) && (
                        <ButtonDownload onClick={() => handleDownload(deviceId, row)} title={t("action.download")} />
                      )}{" "}
                      {hasPermission(PERMISSION_DEVICEIMPORT_DELETE) && (
                        <ButtonDeleteConfirm
                          buttonNoDisabled={isDeleting}
                          buttonYesOnClick={() => handleDelete(deviceId, row.id)}
                          buttonYesProcessing={isDeleting}
                          id={"TooltipDelete" + row.id}
                          modalOnClose={handleDeleteClear}
                          modalBody={t("text.doYouReallyWantToDeleteThisFile")}
                          title={t("action.delete")}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            {data.length > 0 && data.length % 50 === 0 && (
              <tfoot>
                <tr>
                  <td className="text-center" colSpan={3}>
                    <a href="#show-more" onClick={handleLoadMore}>
                      <FontAwesomeIcon icon={faAngleDown} fixedWidth /> {t("action.loadMore")}
                    </a>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        ) : (
          <CardTextEmpty text={t("text.noImports")} />
        )}
        <ModalImport deviceId={deviceId} reloadImports={true} />
      </CardBody>
    </Card>
  );
};

Imports.propTypes = {
  clearImport: PropTypes.func.isRequired,
  deviceId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  clearImport,
  deleteImport,
  downloadImport,
  fetchImports,
  openModal,
  resetImports,
};

const mapStateToProps = (state) => ({
  data: getData(state),
  errors: getErrors(state),
  isDeleting: getIsDeleting(state),
  offset: getOffset(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Imports);
