import validator from "validator";
import validatorMessages from "../constants/validatorMessages";

const validateEmail = (value) => {
  let error;

  if (validator.isEmpty(value)) {
    error = validatorMessages.isNotEmpty;
  } else if (!validator.isEmail(value)) {
    error = validatorMessages.isEmail;
  }

  return error;
};

const validateInt = (value) => {
  let error;

  value = "" + value;

  if (validator.isEmpty(value)) {
    error = validatorMessages.isNotEmpty;
  } else if (!validator.isInt(value)) {
    error = validatorMessages.isInt;
  }

  return error;
};

const validateMacAddress = (value) => {
  let error;

  if (validator.isEmpty(value)) {
    error = validatorMessages.isNotEmpty;
  } else if (!validator.isMACAddress(value, { no_colons: true })) {
    error = validatorMessages.isMACAddress;
  }

  return error;
};

const validateRequired = (value) => {
  let error;

  if (!Array.isArray(value)) {
    value = "" + value;
  }

  if (validator.isEmpty(value)) {
    error = validatorMessages.isNotEmpty;
  }

  return error;
};

const validateUrl = (value) => {
  let error;

  value = "" + value;

  if (validator.isEmpty(value)) {
    error = validatorMessages.isNotEmpty;
  } else if (!validator.isURL(value)) {
    error = validatorMessages.isURL;
  }

  return error;
};

export { validateEmail, validateInt, validateMacAddress, validateRequired, validateUrl };
