const normalizeFloat = (value) => {
  if (!value) {
    return value;
  }

  return parseFloat(value);
};

const normalizeInt = (value) => {
  if (!value) {
    return value;
  }

  return parseInt(value);
};

const normalizeMac = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[\W_]+/g, "");
};

export { normalizeFloat, normalizeInt, normalizeMac };
