import { faEthernet } from "@fortawesome/pro-regular-svg-icons/faEthernet";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonAdd,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { ROUTE_DEVICE_ADD } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Device
import { deleteDevice } from "../actions/device";
import { changeFilter, changePagination, changeSort, fetchDevices } from "../actions/deviceGrid";
import Grid from "../components/Grid";
import { PERMISSION_DEVICE_CREATE, PERMISSION_DEVICE_READ } from "../constants/permissions";

const DevicePageList = (props) => {
  const { errors } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.devices");
  }, []);

  // Kontrola opravneni
  if (!hasPermission(PERMISSION_DEVICE_READ)) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faEthernet} title={t("title.devices")} />
        <ContentHeaderButtons>
          {hasPermission(PERMISSION_DEVICE_CREATE) && <ButtonAdd text={t("action.add")} to={ROUTE_DEVICE_ADD} />}
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={errors} />

      <ContentBody>
        <Row>
          <Col>
            <Card>
              <Grid {...props} />
            </Card>
          </Col>
        </Row>
      </ContentBody>
    </Fragment>
  );
};

const mapDispatchToProps = {
  changeFilter,
  changePagination,
  changeSort,
  fetchDevices,
  deleteDevice,
};

const mapStateToProps = (state) => ({
  data: state.deviceGrid.data,
  dataTotal: state.deviceGrid.dataTotal,
  errors: state.deviceGrid.errors,
  isDeleting: state.device.isDeleting,
  isFetching: state.deviceGrid.isFetching,
  page: state.deviceGrid.page,
  pageSize: state.deviceGrid.pageSize,
  sortField: state.deviceGrid.sortField,
  sortOrder: state.deviceGrid.sortOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicePageList);
