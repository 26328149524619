import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React from "react";
import { useTranslation } from "react-i18next";

// Utilities
import { formatMac } from "../../../../utilities/formatter";

const TimelineRowRemoteDevice = (props) => {
  const { remoteDevices } = props;
  const { t } = useTranslation();

  if (!remoteDevices.added && !remoteDevices.removed) {
    return null;
  }

  return (
    <div className="timeline-block">
      <b>{t("title.remoteDevice")}:</b>
      <hr />
      <ul>
        {remoteDevices.added &&
          remoteDevices.added.map((remoteDevice, index) => (
            <li key={index} className="text-success">
              <FontAwesomeIcon icon={faPlus} /> {formatMac(remoteDevice.mac)}{" "}
              {remoteDevice.name && "(" + remoteDevice.name + ") "}
            </li>
          ))}
        {remoteDevices.removed &&
          remoteDevices.removed.map((remoteDEvice, index) => (
            <li key={index} className="text-danger">
              <FontAwesomeIcon icon={faMinus} /> {formatMac(remoteDEvice.mac)}{" "}
              {remoteDEvice.name && "(" + remoteDEvice.name + ") "}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TimelineRowRemoteDevice;
