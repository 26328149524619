import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faEthernet } from "@fortawesome/pro-regular-svg-icons/faEthernet";
import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faUserShield } from "@fortawesome/pro-regular-svg-icons/faUserShield";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

// App
import {
  ROUTE_DASHBOARD,
  ROUTE_DEVICE,
  ROUTE_ORGANIZATION,
  ROUTE_ORGANIZATIONLOCATION,
  ROUTE_ROLE,
  ROUTE_USER,
} from "../../../../../constants";

// Auth
import { usePermission } from "../../../../auth/hooks/usePermission";

const Sidebar = (props) => {
  const { location } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  return (
    <aside>
      <ul>
        {hasPermission("dashboard.view") && (
          <li className={ROUTE_DASHBOARD === location.pathname ? "active" : ""}>
            <Link to="/">
              <span className="icon">
                <FontAwesomeIcon icon={faHome} fixedWidth />
              </span>
              <span className="text">{t("title.dashboard")}</span>
            </Link>
          </li>
        )}
        {hasPermission("organization.view") && (
          <li
            className={
              ROUTE_ORGANIZATION === location.pathname.substr(0, ROUTE_ORGANIZATION.length) &&
              ROUTE_ORGANIZATIONLOCATION !== location.pathname.substr(0, ROUTE_ORGANIZATIONLOCATION.length)
                ? "active"
                : ""
            }
          >
            <Link to={ROUTE_ORGANIZATION}>
              <span className="icon">
                <FontAwesomeIcon icon={faBuilding} fixedWidth />
              </span>
              <span className="text">{t("title.organizations")}</span>
            </Link>
          </li>
        )}
        {hasPermission("organizationLocation.view") && (
          <li
            className={
              ROUTE_ORGANIZATIONLOCATION === location.pathname.substr(0, ROUTE_ORGANIZATIONLOCATION.length)
                ? "active"
                : ""
            }
          >
            <Link to={ROUTE_ORGANIZATIONLOCATION}>
              <span className="icon">
                <FontAwesomeIcon icon={faBuilding} fixedWidth />
              </span>
              <span className="text">{t("title.organizationLocations")}</span>
            </Link>
          </li>
        )}
        {hasPermission("device.view") && (
          <li className={ROUTE_DEVICE === location.pathname.substr(0, ROUTE_DEVICE.length) ? "active" : ""}>
            <Link to={ROUTE_DEVICE}>
              <span className="icon">
                <FontAwesomeIcon icon={faEthernet} fixedWidth />
              </span>
              <span className="text">{t("title.devices")}</span>
            </Link>
          </li>
        )}
        {hasPermission("user.view") && (
          <li className={ROUTE_USER === location.pathname.substr(0, ROUTE_USER.length) ? "active" : ""}>
            <Link to={ROUTE_USER}>
              <span className="icon">
                <FontAwesomeIcon icon={faUsers} fixedWidth />
              </span>
              <span className="text">{t("title.users")}</span>
            </Link>
          </li>
        )}
        {hasPermission("role.view") && (
          <li className={ROUTE_ROLE === location.pathname.substr(0, ROUTE_ROLE.length) ? "active" : ""}>
            <Link to={ROUTE_ROLE}>
              <span className="icon">
                <FontAwesomeIcon icon={faUserShield} fixedWidth />
              </span>
              <span className="text">{t("title.userRoles")}</span>
            </Link>
          </li>
        )}
      </ul>
    </aside>
  );
};

export default withRouter(Sidebar);
