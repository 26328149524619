import PropTypes from "prop-types";
import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginator from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";

// App
import { ButtonDeleteConfirm, ButtonDetail, ButtonEdit } from "../../core/components";
import { ROUTE_DEVICE_DETAIL, ROUTE_DEVICE_EDIT } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

const Grid = (props) => {
  const { data, dataTotal, isDeleting, page, pageSize, sortField, sortOrder } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  async function handleChange(type, { filters, page, sizePerPage, sortField, sortOrder }) {
    const { changeFilter, changePagination, changeSort, fetchDevices } = props;

    switch (type) {
      case "filter":
        await changeFilter(filters);
        break;
      case "pagination":
        await changePagination(page, sizePerPage);
        break;
      case "sort":
        await changeSort(sortField, sortOrder);
        break;
      default:
        throw Error("Unknow type");
    }

    await fetchDevices();
  }

  function handleDelete(id) {
    props.deleteDevice(id).then(() => {
      props.fetchDevices();
    });
  }

  // Columns
  const columns = [
    {
      dataField: "name",
      filter: textFilter(),
      sort: true,
      text: t("title.name"),
    },
    {
      dataField: "type",
      filter: textFilter(),
      sort: true,
      text: t("title.type"),
    },
    {
      dataField: "device",
      filter: textFilter(),
      sort: true,
      text: t("title.device"),
    },
    {
      dataField: "mac",
      filter: textFilter(),
      sort: true,
      text: t("title.mac"),
    },
    {
      dataField: "sn",
      filter: textFilter(),
      sort: true,
      text: t("title.sn"),
    },
    {
      classes: "react-bootstrap-table-buttons",
      dataField: "id",
      filterable: false,
      isDummyField: true,
      formatter: (cellContent, row) => {
        return (
          <Fragment>
            {hasPermission("device.view") && <ButtonDetail to={generatePath(ROUTE_DEVICE_DETAIL, { id: row.id })} />}
            {hasPermission("device.update") && <ButtonEdit to={generatePath(ROUTE_DEVICE_EDIT, { id: row.id })} />}
            {hasPermission("device.delete") && (
              <ButtonDeleteConfirm
                buttonNoDisabled={isDeleting}
                buttonYesDisabled={isDeleting}
                buttonYesExecuting={isDeleting}
                buttonYesOnClick={() => handleDelete(row.id)}
                modalBody={t("text.doYouReallyWantToDeleteThisItem")}
                title={t("action.delete")}
              />
            )}
          </Fragment>
        );
      },
      text: t("title.actions"),
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      columns={columns}
      data={data}
      defaultSorted={[
        {
          dataField: sortField,
          order: sortOrder,
        },
      ]}
      filter={filterFactory()}
      keyField="id"
      onTableChange={handleChange}
      pagination={paginator({
        firstPageTitle: t("action.goToFirst"),
        lastPageTitle: t("action.goToLast"),
        nextPageTitle: t("action.goToNext"),
        prePageTitle: t("action.goToPrevious"),
        page: page,
        showTotal: false,
        sizePerPage: pageSize,
        totalSize: dataTotal,
      })}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
      striped
    />
  );
};

Grid.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  changePagination: PropTypes.func.isRequired,
  changeSort: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  dataTotal: PropTypes.number.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  fetchDevices: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  sortField: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
};

export default Grid;
