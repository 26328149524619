import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CardTextEmpty from "../../../core/components/shared/CardTextEmpty";

// Components
import PortDetail from "./PortDetail";

const Ports = (props) => {
  const { deviceId, items } = props;
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);
  const showTable = items.length > 0;

  useEffect(() => {
    props.fetchPorts(deviceId);

    return () => {
      props.clearPorts();
    };
  }, []);

  function handleClick(index) {
    if (index === selected) {
      setSelected(null);
    } else {
      setSelected(index);
    }
  }

  return showTable ? (
    <Fragment>
      <div id="ports" className={`ports-${items.length}`}>
        {items.map((item, index) => (
          <div className="port" key={index}>
            <span>{index + 1}</span>
            <div className="port-interface" onClick={() => handleClick(index)} />
          </div>
        ))}
      </div>
      {null !== selected && <PortDetail handleClick={handleClick} port={items[selected] || {}} />}
    </Fragment>
  ) : (
    <CardTextEmpty text={t("text.noPorts")} />
  );
};

Ports.propTypes = {
  clearPorts: PropTypes.func.isRequired,
  deviceId: PropTypes.string.isRequired,
  fetchPorts: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default Ports;
