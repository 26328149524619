import { faAt } from "@fortawesome/pro-regular-svg-icons/faAt";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faSignIn } from "@fortawesome/pro-regular-svg-icons/faSignIn";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";

// App
import { Button } from "../../core/components";
import { validateEmail, validateRequired } from "../../../utilities/validators";
import { FormikField, FormikForm } from "../../core/components/formik";

const C = (props) => {
  const { isSubmitting, handleSubmit } = props;
  const { t } = useTranslation();

  return (
    <FormikForm onSubmit={handleSubmit}>
      <FormikField
        addonPrepend={{
          icon: faAt,
        }}
        label={t("title.email")}
        name="email"
        type="email"
        validate={validateEmail}
      />
      <FormikField
        addonPrepend={{
          icon: faLock,
        }}
        label={t("title.password")}
        name="password"
        type="password"
        validate={validateRequired}
      />
      <div className="text-center">
        <Button
          color="primary"
          executing={isSubmitting}
          icon={faSignIn}
          outline={false}
          size="lg"
          text={t("action.signIn")}
          type="submit"
        />
      </div>
    </FormikForm>
  );
};

C.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const SignInForm = withFormik({
  mapPropsToValues: () => ({ email: "", password: "" }),
  handleSubmit: async (values, { props }) => {
    await props.onSubmit(values);
  },
})(C);

export default SignInForm;
