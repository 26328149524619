import React, { useEffect } from "react";

// Components
import TimelineRow from "./TimelineRow";

const TimelineRows = (props) => {
  const { deviceId, rows } = props;
  const showRows = rows.length > 0;

  useEffect(() => {
    props.onLoad(deviceId);
  }, []);

  if (!showRows) {
    return null;
  }

  return (
    <div id="timeline">
      <div className="timeline-start">
        <i className="timeline-dot-small" />
      </div>
      {rows.map((row, index) => (
        <TimelineRow key={index} row={row} />
      ))}
      <div className="timeline-stop">
        <i className="timeline-dot-small" />
      </div>
    </div>
  );
};

export default TimelineRows;
