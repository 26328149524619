import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";

// Components
import AlertErrors from "./shared/AlertErrors";

// Selectors
import { appConfigErrors } from "../selectors/appConfig";

const AppError = () => {
  const { t } = useTranslation();

  // Nacteme si data ze skladu
  const { configErrors } = useSelector(appConfigErrors);

  let errors = configErrors;
  if (!errors) {
    errors = [t("notice.anUnknownErrorOccurred")];
  }

  return (
    <div id="public">
      <main>
        <div id="signin" className="container-450">
          <Card>
            <CardBody>
              <AlertErrors errors={errors} />
              <span>{t("text.anUnknownErrorDescription")}</span>
            </CardBody>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default AppError;
