import { faAt } from "@fortawesome/pro-regular-svg-icons/faAt";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { Field, Form } from "lemo-form";
import { IsEmail, IsNotEmpty } from "lemo-validator";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

// App
import { Button } from "../../core/components";

const ForgotPasswordForm = (props) => {
  const { forgotPasswordSubmit, forgotPasswordSubmitting } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <p>
        Před zasláním odkazu pro obnovení hesla je nutné zadat e-mailovou adresu, kterou máte zaregistrovanou u svého
        účtu.
      </p>
      <Form onSubmit={forgotPasswordSubmit}>
        <Field
          addonPrependIcon={faAt}
          label={t("title.email")}
          name="email"
          type="email"
          validators={[new IsNotEmpty(), new IsEmail()]}
        />
        <div className="text-center">
          <Button
            color="primary"
            executing={forgotPasswordSubmitting}
            icon={faEnvelope}
            outline={false}
            size="lg"
            text={t("action.send")}
          />
        </div>
      </Form>
    </Fragment>
  );
};

ForgotPasswordForm.propTypes = {
  forgotPasswordSubmit: PropTypes.func.isRequired,
  forgotPasswordSubmitting: PropTypes.bool.isRequired,
};

export default ForgotPasswordForm;
