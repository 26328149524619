import get from "lodash/get";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Selectors
import { appConfigData } from "../selectors/appConfig";

const useTitle = (title) => {
  const appConfig = useSelector(appConfigData);

  useEffect(() => {
    let tit = title ? title + " | " : "";

    document.title = tit + get(appConfig, "title");
  }, [title]);
};

export default useTitle;
