import { connect } from "react-redux";

// Device
import { clearPorts, fetchPorts } from "../actions/devicePorts";
import Ports from "../components/detail/Ports";
import { getItems } from "../selectors/devicePorts";

const mapStateToProps = (state) => {
  return {
    items: getItems(state),
  };
};

const mapDispatchToProps = {
  clearPorts,
  fetchPorts,
};

const DevicePorts = connect(mapStateToProps, mapDispatchToProps)(Ports);

export default DevicePorts;
