import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Table from "reactstrap/es/Table";

// App
import { valueRender } from "../../../../utilities";

const General = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Table className="table-detail" borderless>
      <tbody>
        <tr>
          <td>{t("title.name")}:</td>
          <td>{valueRender(data, "name")}</td>
        </tr>
        <tr>
          <td>{t("title.type")}:</td>
          <td>{valueRender(data, "type")}</td>
        </tr>
        <tr>
          <td>{t("title.manufacturer")}:</td>
          <td>{valueRender(data, "manufacturer")}</td>
        </tr>
        <tr>
          <td>{t("title.modelSeries")}:</td>
          <td>{valueRender(data, "modelSeries")}</td>
        </tr>
        <tr>
          <td>{t("title.model")}:</td>
          <td>{valueRender(data, "model")}</td>
        </tr>
        <tr>
          <td>{t("title.mac")}:</td>
          <td>{valueRender(data, "mac")}</td>
        </tr>
        <tr>
          <td>{t("title.sn")}:</td>
          <td>{valueRender(data, "sn")}</td>
        </tr>
      </tbody>
    </Table>
  );
};

General.propTypes = {
  data: PropTypes.object.isRequired,
};

export default General;
