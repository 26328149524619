import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

// Actions
import { fetchConfig } from "../actions/appConfig";

// Constants
import {
  ROUTE_AUTH_FORGOTPASSWORD,
  ROUTE_AUTH_RESETPASSWORD,
  ROUTE_AUTH_SIGNIN,
  ROUTE_AUTH_SIGNOUT,
} from "../../../constants";

// Components
import AppError from "./AppError";
import AppLoader from "./AppLoader";
import AppPrivate from "./AppPrivate";
import AppPublic from "./AppPublic";

// Helpers
import history from "../../../history";

// Selectors
import { appConfigIsError, appConfigIsFetched, appConfigIsFetching } from "../selectors/appConfig";

const App = () => {
  const dispatch = useDispatch();

  // Nacteme si data ze skladu
  const configIsError = useSelector(appConfigIsError);
  const configIsFetched = useSelector(appConfigIsFetched);
  const configIsFetching = useSelector(appConfigIsFetching);

  useEffect(() => {
    if (!configIsFetched) {
      dispatch(fetchConfig());
    }
  }, [dispatch]);

  if (!configIsFetched) {
    return <AppLoader />;
  }

  if (configIsError) {
    return <AppError />;
  }

  return (
    <Router history={history}>
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <Route component={AppPublic} exact path={ROUTE_AUTH_FORGOTPASSWORD} />
            <Route component={AppPublic} exact path={ROUTE_AUTH_RESETPASSWORD} />
            <Route component={AppPublic} exact path={ROUTE_AUTH_SIGNIN} />
            <Route component={AppPublic} exact path={ROUTE_AUTH_SIGNOUT} />

            <Route component={AppPrivate} />
          </Switch>
        )}
      />
    </Router>
  );
};

export default App;
