import React from "react";
import { connect } from "react-redux";

// Auth
import { AuthProvider } from "../hooks/authContext";

const Auth = (props) => {
  function hasPermission(permission) {
    return props.identityPermissions.includes(permission);
  }

  const authProviderValue = {
    identity: props.identity,
    identityPermissions: props.identityPermissions,
    hasPermission: hasPermission,
  };

  return <AuthProvider value={authProviderValue}>{props.children}</AuthProvider>;
};

const mapStateToProps = (state) => ({
  identity: state.auth.identity,
  identityPermissions: state.auth.identityPermissions,
});

export default connect(mapStateToProps)(Auth);
