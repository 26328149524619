import { Form, Field } from "lemo-form";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

const TimelineFilter = (props) => {
  const { filter, handleChange } = props;
  const { t } = useTranslation();

  function handleOnChange(formApi) {
    handleChange(formApi.getValues());
  }

  function handleSubmit() {
    return false;
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit} values={filter}>
          <Row>
            <Col>
              <Field label={t("title.text")} name="text" onChange={handleOnChange} type="text" />
            </Col>
            <Col>
              <Field label={t("title.dateFrom")} name="dateFrom" onChange={handleOnChange} type="date" />
              <Field label={t("title.dateTo")} name="dateTo" onChange={handleOnChange} type="date" />
            </Col>
            <Col>
              <Field
                label={t("title.type")}
                name="types"
                onChange={handleOnChange}
                options={[
                  { v: "Remote devices", k: "remoteDevices" },
                  { v: "Ports", k: "ports" },
                  { v: "VLANs", k: "vlans" },
                ]}
                optionsKey="k"
                optionsValue="v"
                type="multicheckbox"
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

TimelineFilter.propTypes = {
  filter: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    text: PropTypes.string,
    types: PropTypes.array,
  }).isRequired,
  handleChange: PropTypes.func,
};

export default TimelineFilter;
