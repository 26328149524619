// Constants
import {
  FORMAT_BIRTHNUMBER,
  FORMAT_BOOLEAN,
  FORMAT_CURRENCY,
  FORMAT_DATE,
  FORMAT_DATETIME,
  FORMAT_EMAIL,
  FORMAT_LICENCEPLATE,
  FORMAT_NEWLINE,
  FORMAT_NEWLINE_ADDRESS,
  FORMAT_NEWLINE_ARRAY,
  FORMAT_NUMBER,
  FORMAT_PHONE,
  FORMAT_PHONE_SHORT,
  FORMAT_TIME,
} from "../constants/formats";

// React
import React from "react";

export const valueHas = (obj, path) => {
  if (!path) {
    return false;
  }
  const properties = path.split(".");
  for (let i = 0; i < properties.length; i++) {
    let prop = properties[i];

    if (!obj || !obj.hasOwnProperty(prop)) {
      return false;
    } else {
      obj = obj[prop];
    }
  }

  return !(!obj || (Array.isArray(obj) && 0 === obj.length));
};

export const valueGet = (obj, path, defaultValue = null) => {
  if (!path) {
    return defaultValue;
  }

  const properties = path.split(".");

  for (let i = 0; i < properties.length; i++) {
    let prop = properties[i];

    if (!obj || !obj.hasOwnProperty(prop)) {
      return defaultValue;
    } else {
      obj = obj[prop];
    }
  }

  if (null === obj && null !== defaultValue) {
    obj = defaultValue;
  }

  return obj;
};

export const valueRender = (obj, path, format = null, options = {}) => {
  let value = valueGet(obj, path);

  if ((!value && FORMAT_BOOLEAN !== format) || (Array.isArray(value) && 0 === value.length)) {
    return "-";
  }

  switch (format) {
    case FORMAT_BIRTHNUMBER:
      return value.substr(0, 6) + "/" + value.substr(6);
    case FORMAT_BOOLEAN:
      return true === value ? "Ano" : "Ne";
    case FORMAT_CURRENCY:
      return value.toLocaleString("cs-CZ", {
        style: "currency",
        currency: "CZK",
        minimumFractionDigits: 0,
      });
    case FORMAT_DATE:
      value = new Date(value);
      return value.toLocaleDateString("cs-CZ");
    case FORMAT_DATETIME:
      value = new Date(value);
      return value.toLocaleDateString("cs-CZ") + " - " + value.toLocaleTimeString("cs-CZ");
    case FORMAT_EMAIL:
      return <a href={"mailto:" + value}>{value}</a>;
    case FORMAT_LICENCEPLATE:
      return value.substr(0, 3) + " " + value.substr(3);
    case FORMAT_NEWLINE:
      return value.split("\n").map(function (item, key) {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
    case FORMAT_NEWLINE_ADDRESS:
      return value.split(", ").map(function (item, key) {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
    case FORMAT_NEWLINE_ARRAY:
      return value.map(function (item, key) {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
    case FORMAT_NUMBER:
      return value.toLocaleString("cs-CZ");
    case FORMAT_PHONE:
      return "+420 " + value.substr(0, 3) + " " + value.substr(3, 3) + " " + value.substr(6, 3);
    case FORMAT_PHONE_SHORT:
      return value.substr(0, 3) + " " + value.substr(3, 3) + " " + value.substr(6, 3);
    case FORMAT_TIME:
      value = new Date(value);
      return value.toLocaleTimeString("cs-CZ");
    default:
      return value;
  }
};
