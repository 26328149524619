import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "reactstrap/es/Alert";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";

// App
import { ROUTE_AUTH_SIGNIN } from "../../../constants";

// Auth
import { signOutSubmit } from "../actions/auth";

// Hooks
import useTitle from "../../core/hooks/useTitle";

const SignOutPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Titulek
  useTitle(t("title.signOut"));

  useEffect(() => {
    dispatch(signOutSubmit());
  }, [dispatch]);

  return (
    <div id="signout" className="container-450">
      <Card>
        <CardBody align="center">
          <Alert className="text-center" color="success" transition={{ appear: false, timeout: 0 }}>
            {t("notice.youHaveBeenSuccessfullySignedOut")}
          </Alert>
          <p className="mt-4 mb-4">
            Pokud máte jakékoliv náměty nebo připomínky, napište nám. Vaše podněty jsou pro nás velmi důležité.
          </p>
          <div className="text-center">
            <Link to={ROUTE_AUTH_SIGNIN} className="btn btn-primary btn-lg">
              <FontAwesomeIcon icon={faKey} fixedWidth /> {t("action.signInAgain")}
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SignOutPage;
