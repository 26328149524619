import { connect, FieldArray } from "formik";
import PropTypes from "prop-types";
import React from "react";
import CustomInput from "reactstrap/es/CustomInput";
import FormGroup from "reactstrap/es/FormGroup";

import FieldFeedback from "./FieldFeedback";
import FieldLabel from "./FieldLabel";
import FieldText from "./FieldText";

const FormikFieldMulticheckbox = (props) => {
  const {
    formik,
    label,
    name,
    onChange,
    options,
    optionsKeyLabel,
    optionsKeyValue,
    required,
    text,
    visible,
    validate,
    ...attributes
  } = props;

  if (!visible) {
    return null;
  }

  if (0 === options.length) {
    return null;
  }

  let id = props.id || name;

  return (
    <FormGroup>
      <FieldLabel for={id} label={label} required={required} />

      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div>
            {options.map((option, index) => {
              const optLabel = option[optionsKeyLabel];
              const optValue = option[optionsKeyValue];

              return (
                <CustomInput
                  {...attributes}
                  checked={formik.values[name].includes(optValue)}
                  id={props.name + "-" + optValue}
                  invalid={formik.touched[name] && !!formik.errors[name]}
                  key={index}
                  name={`${name}[]`}
                  label={optLabel}
                  onChange={async (e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(optValue);
                    } else {
                      const idx = formik.values[name].indexOf(optValue);
                      arrayHelpers.remove(idx);
                    }

                    await formik.handleChange(e);

                    if (typeof onChange === "function") {
                      onChange(e);
                    }
                  }}
                  type="checkbox"
                  value={optValue}
                />
              );
            })}
          </div>
        )}
      />
      {text && <FieldText text={text} />}
      {formik.touched[name] && formik.errors[name] && formik.errors[name].length > 0 && (
        <FieldFeedback error={formik.errors[name]} />
      )}
    </FormGroup>
  );
};

FormikFieldMulticheckbox.defaultProps = {
  options: [],
  optionsKeyLabel: "label",
  optionsKeyValue: "value",
  required: false,
  visible: true,
};
FormikFieldMulticheckbox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  optionsKeyLabel: PropTypes.string,
  optionsKeyValue: PropTypes.string,
  text: PropTypes.string,
  visible: PropTypes.bool,
};

export default connect(FormikFieldMulticheckbox);
