import { connect } from "react-redux";

// Device
import { changeFilter } from "../actions/deviceTimeline";
import TimelineFilter from "../components/detail/TimelineFilter";
import { getFilter } from "../selectors/deviceTimeline";

const mapStateToProps = (state) => {
  return {
    filter: getFilter(state),
  };
};

const mapDispatchToProps = {
  handleChange: changeFilter,
};

const DeviceTimelineFilter = connect(mapStateToProps, mapDispatchToProps)(TimelineFilter);

export default DeviceTimelineFilter;
