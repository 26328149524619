// ORGANIZATION - Form
export const ORGANIZATION_FORM_CLEAR = "ORGANIZATION_FORM_CLEAR";
export const ORGANIZATION_FORM_INIT_REQUEST = "ORGANIZATION_FORM_INIT_REQUEST";
export const ORGANIZATION_FORM_INIT_RESPONSE_ERROR = "ORGANIZATION_FORM_INIT_RESPONSE_ERROR";
export const ORGANIZATION_FORM_INIT_RESPONSE_SUCCESS = "ORGANIZATION_FORM_INIT_RESPONSE_SUCCESS";
export const ORGANIZATION_FORM_FETCH_REQUEST = "ORGANIZATION_FORM_FETCH_REQUEST";
export const ORGANIZATION_FORM_FETCH_RESPONSE_ERROR = "ORGANIZATION_FORM_FETCH_RESPONSE_ERROR";
export const ORGANIZATION_FORM_FETCH_RESPONSE_SUCCESS = "ORGANIZATION_FORM_FETCH_RESPONSE_SUCCESS";
export const ORGANIZATION_FORM_SUBMIT_REQUEST = "ORGANIZATION_FORM_SUBMIT_REQUEST";
export const ORGANIZATION_FORM_SUBMIT_RESPONSE_ERROR = "ORGANIZATION_FORM_SUBMIT_RESPONSE_ERROR";
export const ORGANIZATION_FORM_SUBMIT_RESPONSE_SUCCESS = "ORGANIZATION_FORM_SUBMIT_RESPONSE_SUCCESS";

// ORGANIZATION - Grid
export const ORGANIZATION_GRID_CHANGE_FILTER = "ORGANIZATION_GRID_CHANGE_FILTER";
export const ORGANIZATION_GRID_CHANGE_PAGINATION = "ORGANIZATION_GRID_CHANGE_PAGINATION";
export const ORGANIZATION_GRID_CHANGE_SORT = "ORGANIZATION_GRID_CHANGE_SORT";
export const ORGANIZATION_GRID_DELETE_REQUEST = "ORGANIZATION_GRID_DELETE_REQUEST";
export const ORGANIZATION_GRID_DELETE_RESPONSE_ERROR = "ORGANIZATION_GRID_DELETE_RESPONSE_ERROR";
export const ORGANIZATION_GRID_DELETE_RESPONSE_SUCCESS = "ORGANIZATION_GRID_DELETE_RESPONSE_SUCCESS";
export const ORGANIZATION_GRID_FETCH_REQUEST = "ORGANIZATION_GRID_FETCH_REQUEST";
export const ORGANIZATION_GRID_FETCH_RESPONSE_ERROR = "ORGANIZATION_GRID_FETCH_RESPONSE_ERROR";
export const ORGANIZATION_GRID_FETCH_RESPONSE_SUCCESS = "ORGANIZATION_GRID_FETCH_RESPONSE_SUCCESS";
