import moment from "moment/moment";

import {
  DEVICETIMELINE_CHANGE_FILTER,
  DEVICETIMELINE_FETCH,
  DEVICETIMELINE_FETCH_ERROR,
  DEVICETIMELINE_FETCH_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  data: [],
  errors: [],
  filter: {
    dateFrom: moment(new Date()).subtract(1, "months").format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    text: null,
    types: ["ports", "remoteDevices", "vlans"],
  },
  isFetching: false,
};

const deviceTimeline = (state = initialState, action) => {
  switch (action.type) {
    // FETCH
    case DEVICETIMELINE_CHANGE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    // FETCH
    case DEVICETIMELINE_FETCH:
      return {
        ...state,
        data: [],
        errors: [],
        isFetching: true,
      };
    case DEVICETIMELINE_FETCH_ERROR:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case DEVICETIMELINE_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default deviceTimeline;
