import { generatePath } from "react-router";
import { fetchGet, fetchPatch } from "../../../utilities";

// Constants
import {
  DASHBOARDACCESSRULE_CHECKSTATE_START,
  DASHBOARDACCESSRULE_CHECKSTATE_STOP,
  DASHBOARDACCESSRULES_SUBMIT,
  DASHBOARDACCESSRULES_SUBMIT_ERROR,
  DASHBOARDACCESSRULES_SUBMIT_OK,
  DASHBOARDACCESSRULES_CLEAR,
  DASHBOARDACCESSRULES_FETCH,
  DASHBOARDACCESSRULES_FETCH_ERROR,
  DASHBOARDACCESSRULES_FETCH_OK,
} from "../constants/actionTypes";
import {
  URI_DASHBOARD_ACCESSRULE,
  URI_DASHBOARD_ACCESSRULE_DEVICE_X,
  URI_DASHBOARD_ACCESSRULE_X,
} from "../constants/uris";

// Clear
export const clearAccessRules = () => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARDACCESSRULES_CLEAR,
    });
  };
};

// Check state
export const checkStateByDevice = (deviceUuid) => {
  return (dispatch) => {
    dispatch({
      type: DASHBOARDACCESSRULE_CHECKSTATE_START,
      payload: {
        deviceUuid: deviceUuid,
      },
    });

    fetchGet(generatePath(URI_DASHBOARD_ACCESSRULE_DEVICE_X, { deviceUuid: deviceUuid }))
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DASHBOARDACCESSRULE_CHECKSTATE_STOP,
            payload: {
              accessRulesState: res.body,
              deviceUuid: deviceUuid,
            },
          });
        } else {
          dispatch({
            type: DASHBOARDACCESSRULE_CHECKSTATE_STOP,
            payload: {
              accessRulesState: null,
              deviceUuid: deviceUuid,
              errors: res.body,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DASHBOARDACCESSRULE_CHECKSTATE_STOP,
          payload: {
            accessRulesState: null,
            deviceUuid: deviceUuid,
            errors: [err.message],
          },
        });
      });
  };
};

// Fetch
export const fetchAccessRules = () => {
  return (dispatch, getState) => {
    const state = getState();

    if (false === state.deviceAccessRules.isFetching) {
      dispatch({
        type: DASHBOARDACCESSRULES_FETCH,
      });

      fetchGet(URI_DASHBOARD_ACCESSRULE)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DASHBOARDACCESSRULES_FETCH_OK,
              items: res.body,
            });
          } else {
            dispatch({
              type: DASHBOARDACCESSRULES_FETCH_ERROR,
              errors: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DASHBOARDACCESSRULES_FETCH_ERROR,
            errors: [err.message],
          });
        });
    }
  };
};

// UPDATE
export const updateAccessRule = (data, deviceId, uuid) => {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARDACCESSRULES_SUBMIT,
      uuid: uuid,
    });

    await fetchPatch(generatePath(URI_DASHBOARD_ACCESSRULE_X, { uuid: uuid }), data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DASHBOARDACCESSRULES_SUBMIT_OK,
            deviceId: deviceId,
            uuid: uuid,
            item: data,
          });
        } else {
          dispatch({
            type: DASHBOARDACCESSRULES_SUBMIT_ERROR,
            deviceId: deviceId,
            uuid: uuid,
            errors: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DASHBOARDACCESSRULES_SUBMIT_ERROR,
          deviceId: deviceId,
          uuid: uuid,
          errors: [err.message],
        });
      });
  };
};
