export const formatDate = function (value, locale = "cs-CZ") {
  value = new Date(value);

  return value.toLocaleDateString(locale);
};

export const formatDateTime = function (value, locale = "cs-CZ") {
  value = new Date(value);

  return value.toLocaleDateString(locale) + " - " + value.toLocaleTimeString(locale);
};

export const formatMac = function (value) {
  return value
    .toString(16)
    .match(/.{1,2}/g)
    .join(":");
};
