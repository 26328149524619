import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import { ROUTE_ORGANIZATION } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Organization
import { formClear, formFetch, formInit, formSubmitUpdate } from "../actions/organization";
import { OrganizationFieldset } from "../components";

const OrganizationPageEdit = (props) => {
  const { formData, formDataParents, formErrors, formIsSubmitting, history, match } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.organizations");

    props.formFetch(match.params.id).then(() => {
      props.formInit(formData, match.params.id);
    });

    return () => {
      props.formClear();
    };
  }, []);

  const handleSubmit = (values) => {
    props.formSubmitUpdate(values, match.params.id).then(() => {
      if (0 === props.formErrors.length) {
        history.push(ROUTE_ORGANIZATION);
      }
    });
  };

  // Kontrola opravneni
  if (!hasPermission("organization.update")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faBuilding} title={t("title.organizations")} />
        <ContentHeaderButtons>
          <ButtonSave
            disabled={formIsSubmitting}
            executing={formIsSubmitting}
            form="form-organizations"
            text={t("action.save")}
          />
          <ButtonCancel text={t("action.cancel")} to={ROUTE_ORGANIZATION} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={formErrors} />

      <ContentBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            idParent: formData.idParent || "",
            name: formData.name || "",
          }}
          onSubmit={handleSubmit}
          render={(formik) => (
            <FormikForm id="form-organizations" onSubmit={formik.handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <OrganizationFieldset formDataParents={formDataParents} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </FormikForm>
          )}
        />
      </ContentBody>
    </Fragment>
  );
};

OrganizationPageEdit.propTypes = {
  formClear: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formDataParents: PropTypes.array.isRequired,
  formErrors: PropTypes.array.isRequired,
  formFetch: PropTypes.func.isRequired,
  formInit: PropTypes.func.isRequired,
  formIsFetching: PropTypes.bool.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
  formSubmitUpdate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  formClear,
  formFetch,
  formInit,
  formSubmitUpdate,
};

const mapStateToProps = (state) => ({
  formData: state.organization.formData,
  formDataParents: state.organization.formDataParents,
  formErrors: state.organization.formErrors,
  formIsFetching: state.organization.formIsFetching,
  formIsSubmitting: state.organization.formIsSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPageEdit);
