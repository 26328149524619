import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import PropTypes from "prop-types";
import React from "react";

const CardBodyHeader = (props) => {
  return (
    <div className="card-body-header font-weight-bold">
      {props.icon && <FontAwesomeIcon icon={props.icon} fixedWidth={true} />}
      <span>{props.title}</span>
      <hr />
    </div>
  );
};

CardBodyHeader.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default CardBodyHeader;
