import { connect } from "react-redux";

// Dashboard
import {
  checkStateByDevice,
  clearAccessRules,
  fetchAccessRules,
  updateAccessRule,
} from "../actions/dashboardAccessRules";
import DashboardAccessRules from "../components/DashboardAccessRules";
import {
  getCheckingStateByDeviceUuid,
  getDisabledIds,
  getErrors,
  getErrorsByDeviceUuid,
  getIsFetching,
  getItems,
} from "../selectors/dashboardAccessRules";

const mapStateToProps = (state) => {
  return {
    checkingStateByDeviceUuid: getCheckingStateByDeviceUuid(state),
    disabledIds: getDisabledIds(state),
    errors: getErrors(state),
    errorsByDeviceUuid: getErrorsByDeviceUuid(state),
    isFetching: getIsFetching(state),
    items: getItems(state),
  };
};

const mapDispatchToProps = {
  checkStateByDevice,
  clearAccessRules,
  fetchAccessRules,
  updateAccessRule,
};

const DashboardAccessRulesContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardAccessRules);

export default DashboardAccessRulesContainer;
