import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash/get";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DropdownItem from "reactstrap/es/DropdownItem";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import Nav from "reactstrap/es/Nav";
import Navbar from "reactstrap/es/Navbar";
import UncontrolledDropdown from "reactstrap/es/UncontrolledDropdown";

// App
import { ROUTE_AUTH_SIGNOUT, ROUTE_DASHBOARD } from "../../../../../constants";
import { appConfigData } from "../../../selectors/appConfig";

const Header = () => {
  const identity = useSelector((state) => state.auth.identity);
  const appConfig = useSelector(appConfigData);
  const { t } = useTranslation();

  // Nacteme si konfiguraci
  const url = "/img/logo/" + get(appConfig, "logo2");

  return (
    <header>
      <Navbar className="flex-md-nowrap shadow" color="primary" dark fixed="top">
        <Link className="navbar-brand" to={ROUTE_DASHBOARD}>
          <img src={url} alt={get(appConfig, "title")} />
        </Link>
        <Nav navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle nav caret>
              <FontAwesomeIcon icon={faUser} fixedWidth /> {identity.firstname} {identity.lastname}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag="a" href={ROUTE_AUTH_SIGNOUT}>
                <FontAwesomeIcon icon={faSignOut} fixedWidth /> {t("action.signOut")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </header>
  );
};

export default Header;
