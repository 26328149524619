import { connect } from "react-redux";

// Device
import { clearAccessRules, fetchAccessRules, refreshAccessRules, updateAccessRule } from "../actions/deviceAccessRules";
import AccessRules from "../components/detail/AccessRules";
import { getDisabledIds, getErrors, getIsRefreshing, getItems } from "../selectors/deviceAccessRules";

const mapStateToProps = (state) => {
  return {
    disabledIds: getDisabledIds(state),
    errors: getErrors(state),
    isRefreshing: getIsRefreshing(state),
    items: getItems(state),
  };
};

const mapDispatchToProps = {
  clearAccessRules,
  fetchAccessRules,
  refreshAccessRules,
  updateAccessRule,
};

const DeviceAccessRules = connect(mapStateToProps, mapDispatchToProps)(AccessRules);

export default DeviceAccessRules;
