export const PERMISSION_DEVICE_CREATE = "device.create";
export const PERMISSION_DEVICE_DELETE = "device.delete";
export const PERMISSION_DEVICE_READ = "device.read";
export const PERMISSION_DEVICE_UPDATE = "device.update";
export const PERMISSION_DEVICE_VIEW = "device.view";
export const PERMISSION_DEVICEACCESSRULE_READ = "deviceAccessRule.read";
export const PERMISSION_DEVICEACCESSRULE_REFRESH = "deviceAccessRule.refresh";
export const PERMISSION_DEVICEACCESSRULE_UPDATE = "deviceAccessRule.update";
export const PERMISSION_DEVICEACCESSRULE_VIEW = "deviceAccessRule.view";
export const PERMISSION_DEVICEIMPORT_CREATE = "deviceImport.create";
export const PERMISSION_DEVICEIMPORT_DELETE = "deviceImport.delete";
export const PERMISSION_DEVICEIMPORT_DOWNLOAD = "deviceImport.download";
export const PERMISSION_DEVICEIMPORT_READ = "deviceImport.read";
export const PERMISSION_DEVICEIMPORT_VIEW = "deviceImport.view";
export const PERMISSION_DEVICEPORT_READ = "devicePort.read";
export const PERMISSION_DEVICEPORT_VIEW = "devicePort.view";
export const PERMISSION_DEVICETIMELINE_READ = "devicePort.read";
export const PERMISSION_DEVICETIMELINE_VIEW = "devicePort.view";
