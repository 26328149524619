import { connect } from "formik";
import PropTypes from "prop-types";
import React from "react";
import CustomInput from "reactstrap/es/CustomInput";
import FormFeedback from "reactstrap/es/FormFeedback";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";

const FormikFieldCheckbox = (props) => {
  const { formik, label, name, options, visible, ...attributes } = props;

  if (!visible) {
    return null;
  }

  let error = formik.errors[name] || null;
  let touched = formik.touched[name] || false;
  let value = 1 === formik.values[name] || true === formik.values[name];

  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <CustomInput
        {...attributes}
        checked={!!value}
        id={name}
        invalid={touched && !!error}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="checkbox"
        value={value}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

FormikFieldCheckbox.defaultProps = {
  options: [],
  visible: true,
};
FormikFieldCheckbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  visible: PropTypes.bool,
};

export default connect(FormikFieldCheckbox);
