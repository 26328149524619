export const downloadDialog = (content, filename, mimetype) => {
  const blob = new Blob([content], {
    type: mimetype || "application/octet-stream",
  });

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    let tempLink = document.createElement("a");

    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);

    tempLink.click();

    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

// export const downloadNew = (content, filename, mimeType) => {
//     let element = document.createElement('a');
//     element.setAttribute('href', 'data:' + mimeType + ',' + encodeURIComponent(content));
//     element.setAttribute('download', filename);
//
//     element.style.display = 'none';
//     document.body.appendChild(element);
//
//     element.click();
//
//     document.body.removeChild(element);
// };
