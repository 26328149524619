import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { withFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import { ROUTE_ORGANIZATIONLOCATION } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Organization location
import { formClear, formFetch, formInit, formSubmitUpdate } from "../actions/organizationLocation";
import { OrganizationLocationFieldset } from "../components";

const OrganizationLocationPageEdit = (props) => {
  const {
    formData,
    formDataOrganizations,
    formDataParents,
    formErrors,
    formIsSubmitting,
    handleSubmit,
    match,
    values,
  } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.organizationLocations");

    props.formFetch(match.params.id);

    return () => {
      props.formClear();
    };
  }, []);

  // Aktualizace formulare pri zmene ID organizace
  useEffect(() => {
    if (!isEmpty(formData)) {
      props.formInit(values, match.params.id);
    }
  }, [values.idOrganization]);

  // Kontrola opravneni
  if (!hasPermission("organizationLocation.update")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faBuilding} title={t("title.organizationLocations")} />
        <ContentHeaderButtons>
          <ButtonSave
            disabled={formIsSubmitting}
            executing={formIsSubmitting}
            form="form-organizationLocations"
            text={t("action.save")}
          />
          <ButtonCancel text={t("action.cancel")} to={ROUTE_ORGANIZATIONLOCATION} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={formErrors} />

      <ContentBody>
        <FormikForm id="form-organizationLocations" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <OrganizationLocationFieldset
                    formDataOrganizations={formDataOrganizations}
                    formDataParents={formDataParents}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </FormikForm>
      </ContentBody>
    </Fragment>
  );
};

OrganizationLocationPageEdit.propTypes = {
  formClear: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formDataParents: PropTypes.array.isRequired,
  formDataOrganizations: PropTypes.array.isRequired,
  formErrors: PropTypes.array.isRequired,
  formFetch: PropTypes.func.isRequired,
  formInit: PropTypes.func.isRequired,
  formIsFetching: PropTypes.bool.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
  formSubmitUpdate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  formClear,
  formFetch,
  formInit,
  formSubmitUpdate,
};

const mapStateToProps = (state) => ({
  formData: state.organizationLocation.formData,
  formDataParents: state.organizationLocation.formDataParents,
  formDataOrganizations: state.organizationLocation.formDataOrganizations,
  formErrors: state.organizationLocation.formErrors,
  formIsFetching: state.organizationLocation.formIsFetching,
  formIsSubmitting: state.organizationLocation.formIsSubmitting,
});

const OrganizationLocationPageEditFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    idOrganization: props.formData.idOrganization || "",
    idParent: props.formData.idParent || "",
    name: props.formData.name || "",
  }),
  handleSubmit: async (values, { props }) => {
    props.formSubmitUpdate(values, props.match.params.id).then(() => {
      if (0 === props.formErrors.length) {
        props.history.push(ROUTE_ORGANIZATIONLOCATION);
      }
    });
  },
})(OrganizationLocationPageEdit);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLocationPageEditFormik);
