import clone from "lodash/clone";

import {
  DASHBOARDACCESSRULE_CHECKSTATE_START,
  DASHBOARDACCESSRULE_CHECKSTATE_STOP,
  DASHBOARDACCESSRULES_CLEAR,
  DASHBOARDACCESSRULES_FETCH,
  DASHBOARDACCESSRULES_FETCH_ERROR,
  DASHBOARDACCESSRULES_FETCH_OK,
  DASHBOARDACCESSRULES_SUBMIT,
  DASHBOARDACCESSRULES_SUBMIT_ERROR,
  DASHBOARDACCESSRULES_SUBMIT_OK,
} from "../constants/actionTypes";

const initialState = {
  checkingStateByDeviceUuid: {},
  disabledIds: {},
  errors: [],
  errorsByDeviceUuid: {},
  isFetching: false,
  isSubmitting: false,
  items: {},
};

const dashboardAccessRules = (state = initialState, action) => {
  switch (action.type) {
    // CLEAR
    case DASHBOARDACCESSRULES_CLEAR:
      return initialState;

    // FETCH
    case DASHBOARDACCESSRULE_CHECKSTATE_START:
      return {
        ...state,
        checkingStateByDeviceUuid: {
          ...state.checkingStateByDeviceUuid,
          [action.payload.deviceUuid]: true,
        },
      };
    case DASHBOARDACCESSRULE_CHECKSTATE_STOP:
      const accessRulesState = action.payload.accessRulesState;
      const deviceUuid = action.payload.deviceUuid;

      let newcheckingStateByDeviceUuid = clone(state.checkingStateByDeviceUuid);
      delete newcheckingStateByDeviceUuid[action.payload.deviceUuid];

      if (null === accessRulesState) {
        return {
          ...state,
          checkingStateByDeviceUuid: newcheckingStateByDeviceUuid,
          errorsByDeviceUuid: {
            ...state.errorsByDeviceUuid,
            [deviceUuid]: action.payload.errors,
          },
        };
      }

      return {
        ...state,
        checkingStateByDeviceUuid: newcheckingStateByDeviceUuid,
        items: {
          ...state.items,
          [deviceUuid]: {
            ...state.items[deviceUuid],
            accessRules: accessRulesState,
          },
        },
      };

    // FETCH
    case DASHBOARDACCESSRULES_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case DASHBOARDACCESSRULES_FETCH_ERROR:
      return {
        ...state,
        errors: action.errors,
        isFetching: false,
        items: {},
      };
    case DASHBOARDACCESSRULES_FETCH_OK:
      return {
        ...state,
        errors: [],
        isFetching: false,
        items: action.items,
      };

    // SUBMIT
    case DASHBOARDACCESSRULES_SUBMIT:
      return {
        ...state,
        disabledIds: { ...state.disabledIds, ...{ [action.uuid]: true } },
        isSubmitting: true,
      };
    case DASHBOARDACCESSRULES_SUBMIT_ERROR:
      delete state.disabledIds[action.uuid];

      return {
        ...state,
        errors: action.errors,
        isSubmitting: false,
      };
    case DASHBOARDACCESSRULES_SUBMIT_OK:
      delete state.disabledIds[action.uuid];

      return {
        ...state,
        errors: [],
        isSubmitting: false,
        items: {
          ...state.items,
          [action.deviceId]: {
            ...state.items[action.deviceId],
            accessRules: {
              ...state.items[action.deviceId].accessRules,
              [action.uuid]: {
                ...state.items[action.deviceId].accessRules[action.uuid],
                ...action.item,
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

export default dashboardAccessRules;
