import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

// App
import { FormikFieldSelect } from "../../core/components/formik";
import { normalizeInt } from "../../../utilities/normalizers";
import { validateRequired } from "../../../utilities/validators";

const FieldsetOrganization = (props) => {
  const { dataOrganizationLocations, dataOrganizations } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <FormikFieldSelect
        label={t("title.organization")}
        name="idOrganization"
        normalize={normalizeInt}
        options={dataOrganizations}
        optionsKeyLabel="nameComposed"
        optionsKeyValue="id"
        required
        validate={validateRequired}
      />
      <FormikFieldSelect
        label={t("title.location")}
        name="idOrganizationLocation"
        normalize={normalizeInt}
        options={dataOrganizationLocations}
        optionsKeyLabel="nameComposed"
        optionsKeyValue="id"
      />
    </Fragment>
  );
};

FieldsetOrganization.propTypes = {
  dataOrganizationLocations: PropTypes.array.isRequired,
  dataOrganizations: PropTypes.array.isRequired,
};

export default FieldsetOrganization;
