import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

// App
import { FormikField, FormikFieldMulticheckbox } from "../../core/components/formik";
import { validateRequired } from "../../../utilities/validators";

const UserAuthFieldset = (props) => {
  const { passwordRequired, roles } = props;
  const { t } = useTranslation();

  return (
    <fieldset name="auth">
      <FormikField label={t("title.email")} name="email" required type="email" validate={validateRequired} />
      <FormikField
        label={t("title.newPassword")}
        name="password"
        required={passwordRequired}
        text={!passwordRequired ? t("text.fillInOnlyWhenSettingANewPassword") : null}
        type="password"
        validate={passwordRequired ? validateRequired : null}
      />
      <FormikFieldMulticheckbox
        label={t("title.roles")}
        name="idRoles"
        options={roles}
        optionsKeyLabel="name"
        optionsKeyValue="id"
        required
        type="multicheckbox"
        validate={validateRequired}
      />
    </fieldset>
  );
};

UserAuthFieldset.propTypes = {
  passwordRequired: PropTypes.bool.isRequired,
};

export default UserAuthFieldset;
