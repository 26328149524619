import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import isArrayLikeObject from "lodash/isArrayLikeObject";
import isString from "lodash/isString";
import React from "react";
import Alert from "reactstrap/es/Alert";

const AlertErrors = (props) => {
  const { errors, fade } = props;

  if (typeof errors === "undefined" || 0 === errors.length) {
    return "";
  }

  if (!isArray(errors) && !isArrayLikeObject(errors)) {
    return (
      <Alert color="danger" fade={fade}>
        An unknown error occurred.
      </Alert>
    );
  }

  return (
    <Alert color="danger" fade={fade} className="shadow-sm">
      {errors.map((error, index) => {
        if (isString(error)) {
          return (
            <span key={index}>
              {error}
              <br />
            </span>
          );
        } else {
          return "";
        }
      })}
    </Alert>
  );
};

AlertErrors.defaultProps = {
  fade: true,
};
AlertErrors.propTypes = {
  errors: PropTypes.array,
  fade: PropTypes.bool,
};

export default AlertErrors;
