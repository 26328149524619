import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

// Components
import Auth from "../../auth/components/Auth";
import { Dashboard } from "../../dashboard/components";
import Error404 from "./Error404";
import Footer from "./shared/AppPrivate/Footer";
import Header from "./shared/AppPrivate/Header";
import Main from "./shared/AppPrivate/Main";
import Sidebar from "./shared/AppPrivate/Sidebar";

// Constants
import {
  ROUTE_AUTH_SIGNIN,
  ROUTE_DASHBOARD,
  ROUTE_DEVICE,
  ROUTE_DEVICE_ADD,
  ROUTE_DEVICE_DETAIL,
  ROUTE_DEVICE_EDIT,
  ROUTE_ORGANIZATION,
  ROUTE_ORGANIZATION_ADD,
  ROUTE_ORGANIZATION_EDIT,
  ROUTE_ORGANIZATIONLOCATION,
  ROUTE_ORGANIZATIONLOCATION_ADD,
  ROUTE_ORGANIZATIONLOCATION_EDIT,
  ROUTE_ROLE,
  ROUTE_ROLE_ADD,
  ROUTE_ROLE_EDIT,
  ROUTE_USER,
  ROUTE_USER_ADD,
  ROUTE_USER_EDIT,
} from "../../../constants";

// Containers
import { DevicePageAdd, DevicePageDetail, DevicePageEdit, DevicePageList } from "../../device/containers";
import { OrganizationPageAdd, OrganizationPageEdit, OrganizationPageList } from "../../organization/containers";
import {
  OrganizationLocationPageAdd,
  OrganizationLocationPageEdit,
  OrganizationLocationPageList,
} from "../../organizationLocation/containers";
import { RolePageAdd, RolePageEdit, RolePageList } from "../../role/containers";
import { UserPageAdd, UserPageEdit, UserPageList } from "../../user/containers";

const AppPrivate = (props) => {
  const { location } = props;
  const identityAuthenticated = useSelector((state) => state.auth.identityAuthenticated);

  // User is not signed, redirect to signin
  if (!identityAuthenticated) {
    let r = "";
    if ("/" !== location.pathname) {
      r = "?r=" + encodeURI(location.pathname);
    }

    return <Redirect to={ROUTE_AUTH_SIGNIN + r} />;
  }

  return (
    <Auth>
      <div id="private">
        <Header />
        <Sidebar />
        <Main>
          <Switch>
            <Route component={Dashboard} exact path={ROUTE_DASHBOARD} />
            <Route component={DevicePageAdd} exact path={ROUTE_DEVICE_ADD} />
            <Route component={DevicePageDetail} exact path={ROUTE_DEVICE_DETAIL} />
            <Route component={DevicePageEdit} exact path={ROUTE_DEVICE_EDIT} />
            <Route component={DevicePageList} exact path={ROUTE_DEVICE} />
            <Route component={OrganizationLocationPageList} exact path={ROUTE_ORGANIZATIONLOCATION} />
            <Route component={OrganizationLocationPageAdd} exact path={ROUTE_ORGANIZATIONLOCATION_ADD} />
            <Route component={OrganizationLocationPageEdit} exact path={ROUTE_ORGANIZATIONLOCATION_EDIT} />
            <Route component={OrganizationPageList} exact path={ROUTE_ORGANIZATION} />
            <Route component={OrganizationPageAdd} exact path={ROUTE_ORGANIZATION_ADD} />
            <Route component={OrganizationPageEdit} exact path={ROUTE_ORGANIZATION_EDIT} />
            <Route component={RolePageList} exact path={ROUTE_ROLE} />
            <Route component={RolePageAdd} exact path={ROUTE_ROLE_ADD} />
            <Route component={RolePageEdit} exact path={ROUTE_ROLE_EDIT} />
            <Route component={UserPageList} exact path={ROUTE_USER} />
            <Route component={UserPageAdd} exact path={ROUTE_USER_ADD} />
            <Route component={UserPageEdit} exact path={ROUTE_USER_EDIT} />
            <Route component={Error404} />
          </Switch>
        </Main>
        <Footer />
      </div>
    </Auth>
  );
};

export default AppPrivate;
