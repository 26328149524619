import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { default as ReactstrapButton } from "reactstrap/es/Button";
import { Link } from "react-router-dom";

class Button extends Component {
  renderIcon() {
    const { icon, iconSpin, iconFixedWidth, executing } = this.props;

    if (executing) {
      return <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />;
    }

    if (!icon) {
      return null;
    }

    return <FontAwesomeIcon icon={icon} fixedWidth={iconFixedWidth} spin={iconSpin} />;
  }

  render() {
    const { children, executing, icon, iconFixedWidth, iconSpin, text, ...props } = this.props;

    let tag = "button";
    if (this.props.to) {
      tag = Link;
    }

    return (
      <ReactstrapButton tag={tag} {...props}>
        {this.renderIcon()} {text || children}
      </ReactstrapButton>
    );
  }
}

Button.defaultProps = {
  color: "secondary",
  executing: false,
  iconFixedWidth: true,
  outline: false,
  iconSpin: false,
  size: "sm",
};

Button.propTypes = {
  active: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  executing: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  iconFixedWidth: PropTypes.bool,
  iconSpin: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  text: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Button;
