import { generatePath } from "react-router";

// App
import { fetchGet } from "../../../utilities";

// Device
import {
  DEVICEPORTS_CLEAR,
  DEVICEPORTS_FETCH,
  DEVICEPORTS_FETCH_SUCCESS,
  DEVICEPORTS_FETCH_ERROR,
} from "../constants/actionTypes";
import { URI_DEVICE_X_PORT } from "../constants/uris";

// CLEAR - Ports
export const clearPorts = () => {
  return (dispatch) => {
    dispatch({
      type: DEVICEPORTS_CLEAR,
    });
  };
};

// FETCH - Ports
export const fetchPorts = (id) => {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.devicePorts.isFetching) {
      dispatch({
        type: DEVICEPORTS_FETCH,
      });

      fetchGet(generatePath(URI_DEVICE_X_PORT, { id: id }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICEPORTS_FETCH_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: DEVICEPORTS_FETCH_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICEPORTS_FETCH_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};
