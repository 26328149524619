import { Comparator } from "react-bootstrap-table2-filter";

function appendUrlParams(filters, page, pageSize, sortField, sortOrder) {
  return (
    "?mode=g" +
    partLimit(pageSize) +
    partOffset(page, pageSize) +
    partOrder(sortField, sortOrder) +
    partFilters(filters)
  );
}

function partFilters(filters) {
  let part = "";

  for (let [field, filter] of Object.entries(filters)) {
    let operator = null;
    switch (filter.comparator) {
      // LIKE
      case Comparator.LIKE:
        operator = "like";
        break;

      // Equal
      case Comparator.EQ:
        operator = "eq";
        break;
      case Comparator.NE:
        operator = "neq";
        break;

      // Greater
      case Comparator.GE:
        operator = "gte";
        break;
      case Comparator.GT:
        operator = "gt";
        break;

      // Less
      case Comparator.LE:
        operator = "lte";
        break;
      case Comparator.LT:
        operator = "lt";
        break;

      default:
        throw Error("Unknow filter operator");
    }

    part += "&" + field + "[" + operator + "]=" + filter.filterVal;
  }

  return part;
}

function partLimit(pageSize) {
  return "&limit=" + pageSize;
}

function partOffset(page, pageSize) {
  return "&offset=" + (page * pageSize - pageSize);
}

function partOrder(sortField, sortOrder) {
  if (sortField && sortField.length > 0) {
    sortOrder = sortOrder || "asc";

    return "&order[" + sortField + "]=" + sortOrder;
  }

  return "";
}

export { appendUrlParams };
