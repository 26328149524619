import { fetchPost } from "../../../utilities";

export const ACTION_FETCH = "appConfig.fetch";
export const ACTION_FETCH_OK = "appConfig.fetch.ok";
export const ACTION_FETCH_ERROR = "appConfig.fetch.error";
export const URI_CONFIG = "/api/config";

// FETCH CONFIG
export const fetchConfig = () => {
  return async (dispatch) => {
    dispatch({
      type: ACTION_FETCH,
    });

    await fetchPost(URI_CONFIG, { host: window.location.hostname })
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ACTION_FETCH_OK,
            payload: {
              data: res.body,
            },
          });
        } else {
          dispatch({
            type: ACTION_FETCH_ERROR,
            payload: {
              errors: res.body,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ACTION_FETCH_ERROR,
          payload: {
            errors: [err.message],
          },
        });
      });
  };
};
