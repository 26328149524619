import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import { ROUTE_ORGANIZATIONLOCATION } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Organization location
import { formInit, formClear, formSubmitCreate } from "../actions/organizationLocation";
import { OrganizationLocationFieldset } from "../components";

const OrganizationLocationPageAdd = (props) => {
  const { formDataOrganizations, formDataParents, formErrors, formIsSubmitting, handleSubmit, values } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.organizationLocations");

    props.formInit();

    return () => {
      props.formClear();
    };
  }, []);

  // Aktualizace formulare pri zmene ID organizace
  useEffect(() => {
    props.formInit(values);
  }, [values.idOrganization]);

  // Kontrola opravneni
  if (!hasPermission("organizationLocation.create")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faBuilding} title={t("title.organizationLocations")} />
        <ContentHeaderButtons>
          <ButtonSave
            disabled={formIsSubmitting}
            executing={formIsSubmitting}
            form="form-organizationLocations"
            text={t("action.save")}
          />
          <ButtonCancel text={t("action.cancel")} to={ROUTE_ORGANIZATIONLOCATION} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={formErrors} />

      <ContentBody>
        <FormikForm id="form-organizationLocations" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <OrganizationLocationFieldset
                    formDataParents={formDataParents}
                    formDataOrganizations={formDataOrganizations}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </FormikForm>
      </ContentBody>
    </Fragment>
  );
};

OrganizationLocationPageAdd.propTypes = {
  formClear: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formDataParents: PropTypes.array.isRequired,
  formDataOrganizations: PropTypes.array.isRequired,
  formErrors: PropTypes.array.isRequired,
  formInit: PropTypes.func.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
  formSubmitCreate: PropTypes.func.isRequired,
};

const OrganizationLocationPageAddFormik = withFormik({
  mapPropsToValues: () => ({
    idOrganization: "",
    idParent: "",
    name: "",
  }),
  handleSubmit: async (values, { props }) => {
    props.formSubmitCreate(values).then(() => {
      if (0 === props.formErrors.length) {
        props.history.push(ROUTE_ORGANIZATIONLOCATION);
      }
    });
  },
})(OrganizationLocationPageAdd);

const mapDispatchToProps = {
  formClear,
  formInit,
  formSubmitCreate,
};

const mapStateToProps = (state) => ({
  formData: state.organizationLocation.formData,
  formDataParents: state.organizationLocation.formDataParents,
  formDataOrganizations: state.organizationLocation.formDataOrganizations,
  formErrors: state.organizationLocation.formErrors,
  formIsSubmitting: state.organizationLocation.formIsSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLocationPageAddFormik);
