import React, { Component } from "react";
import Container from "reactstrap/es/Container";

class ContentHeader extends Component {
  render() {
    return (
      <Container id="content-header" fluid>
        {this.props.children}
      </Container>
    );
  }
}

export default ContentHeader;
