import PropTypes from "prop-types";
import React, { Component } from "react";
import Alert from "reactstrap/es/Alert";
import Col from "reactstrap/es/Col";
import Container from "reactstrap/es/Container";
import Row from "reactstrap/es/Row";

class ContentAlerts extends Component {
  render() {
    const { errors } = this.props;

    if (!errors || 0 === errors.length) {
      return "";
    }

    return (
      <Container id="content-alerts" fluid>
        <Row>
          <Col>
            <Alert color="danger" fade={false}>
              {errors.map((error, index) => (
                <span key={index}>
                  {error}
                  <br />
                </span>
              ))}
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}

ContentAlerts.propTypes = {
  errors: PropTypes.array,
};

export default ContentAlerts;
