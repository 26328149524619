import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faBug } from "@fortawesome/pro-regular-svg-icons/faBug";
import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import React, { Component, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Button from "reactstrap/es/Button";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// Components
import ContentBody from "./shared/ContentBody";
import ContentHeader from "./shared/ContentHeader";
import ContentHeaderTitle from "./shared/ContentHeaderTitle";

// Constants
import { ROUTE_DASHBOARD } from "../../../constants";

// Hooks
import useTitle from "../hooks/useTitle";

class Error500 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { t } = useTranslation();

    // Titulek
    useTitle(t("title.error403"));

    if (!this.state.errorInfo) {
      return this.props.children;
    }

    return (
      <Fragment>
        <ContentHeader>
          <ContentHeaderTitle icon={faBug} title={t("title.error500")} />
        </ContentHeader>
        <ContentBody>
          <Row>
            <Col>
              <Card outline color="danger">
                <CardBody className="text-center">
                  <p className="text-danger">{t("text.anErrorOccurredAndYourRequestCouldntBeCompleted")}</p>
                  <Button color="danger" onClick={this.props.history.goBack}>
                    <FontAwesomeIcon fixedWidth icon={faArrowLeft} /> {t("action.goToBack")}
                  </Button>{" "}
                  <Button color="danger" tag={Link} to={ROUTE_DASHBOARD}>
                    <FontAwesomeIcon fixedWidth icon={faHome} /> {t("action.goToDefaultPage")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContentBody>
      </Fragment>
    );
  }
}

export default withRouter(Error500);
