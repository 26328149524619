import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

// App
import { FormikField, FormikFieldSelect } from "../../core/components/formik";
import { validateInt, validateRequired } from "../../../utilities/validators";

const UserBaseFieldset = (props) => {
  const { formDataOrganizations } = props;
  const { t } = useTranslation();

  return (
    <fieldset name="user">
      <FormikFieldSelect
        label={t("title.organization")}
        name="idOrganization"
        options={formDataOrganizations}
        optionsEmpty={true}
        optionsKeyLabel="nameComposed"
        optionsKeyValue="id"
        required
        validate={validateInt}
      />
      <FormikField label={t("title.firstname")} name="firstname" required validate={validateRequired} />
      <FormikField label={t("title.lastname")} name="lastname" required validate={validateRequired} />
    </fieldset>
  );
};

UserBaseFieldset.propTypes = {
  formDataOrganizations: PropTypes.array.isRequired,
};

export default UserBaseFieldset;
