import { generatePath } from "react-router";

// App
import { appendUrlParams, fetchDelete, fetchGet, fetchPatch, fetchPost } from "../../../utilities";
import {
  URI_ORGANIZATIONLOCATION,
  URI_ORGANIZATIONLOCATION_ADD,
  URI_ORGANIZATIONLOCATION_DELETE,
  URI_ORGANIZATIONLOCATION_EDIT,
  URI_ORGANIZATIONLOCATION_FORM,
} from "../constants/uris";

// Organization location
import {
  ORGANIZATIONLOCATION_FORM_CLEAR,
  ORGANIZATIONLOCATION_FORM_FETCH_REQUEST,
  ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_FORM_INIT_REQUEST,
  ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST,
  ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_GRID_CHANGE_FILTER,
  ORGANIZATIONLOCATION_GRID_CHANGE_PAGINATION,
  ORGANIZATIONLOCATION_GRID_CHANGE_SORT,
  ORGANIZATIONLOCATION_GRID_DELETE_REQUEST,
  ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_GRID_FETCH_REQUEST,
  ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_SUCCESS,
} from "../constants/actionTypes";

// FORM - Clear
export const formClear = () => {
  return (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_FORM_CLEAR,
    });
  };
};

// FORM - Fetch
export const formFetch = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.organizationLocation.formIsFetching) {
      dispatch({
        type: ORGANIZATIONLOCATION_FORM_FETCH_REQUEST,
      });

      await fetchGet(generatePath(URI_ORGANIZATIONLOCATION_EDIT, { id: id }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// FORM - Init
export const formInit = (values, id = null) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.organizationLocation.formIsInitializing) {
      dispatch({
        type: ORGANIZATIONLOCATION_FORM_INIT_REQUEST,
      });

      if (null === id) {
        id = 0;
      }

      await fetchPost(generatePath(URI_ORGANIZATIONLOCATION_FORM, { id: id }), values)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// FORM - Submit - Create
export const formSubmitCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST,
    });

    await fetchPost(URI_ORGANIZATIONLOCATION_ADD, data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// FORM - Submit - Update
export const formSubmitUpdate = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST,
    });

    await fetchPatch(generatePath(URI_ORGANIZATIONLOCATION_EDIT, { id: id }), data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// GRID - Change - Filter
export const gridChangeFilter = (filters) => {
  return (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_GRID_CHANGE_FILTER,
      payload: {
        filters: filters,
      },
    });
  };
};

// GRID - Change - Pagination
export const gridChangePagination = (page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_GRID_CHANGE_PAGINATION,
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  };
};

// GRID - Change - Sort
export const gridChangeSort = (sortField, sortOrder) => {
  return (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_GRID_CHANGE_SORT,
      payload: {
        sortField: sortField,
        sortOrder: sortOrder,
      },
    });
  };
};

// GRID - Delete
export const gridDelete = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ORGANIZATIONLOCATION_GRID_DELETE_REQUEST,
    });

    await fetchDelete(generatePath(URI_ORGANIZATIONLOCATION_DELETE, { id: id }))
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// GRID - Fetch
export const gridFetch = () => {
  return (dispatch, getState) => {
    const state = getState();

    if (false === state.organizationLocation.gridIsFetching) {
      dispatch({
        type: ORGANIZATIONLOCATION_GRID_FETCH_REQUEST,
      });

      const urlParam = appendUrlParams(
        state.organizationLocation.gridFilters,
        state.organizationLocation.gridPage,
        state.organizationLocation.gridPageSize,
        state.organizationLocation.gridSortField,
        state.organizationLocation.gridSortOrder
      );

      fetchGet(URI_ORGANIZATIONLOCATION + urlParam)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};
