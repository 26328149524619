import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import { ContentBody, ContentHeader, ContentHeaderTitle, Error403 } from "../../core/components";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Dashboard
import DashboardAccessRulesContainer from "../containers/DashboardAccessRulesContainer";

const Dashboard = () => {
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.dashboard");
  }, [t]);

  if (!hasPermission("dashboard.view")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faHome} title={t("title.dashboard")} />
      </ContentHeader>
      <ContentBody>
        <Row>
          <Col>
            <DashboardAccessRulesContainer />
          </Col>
        </Row>
      </ContentBody>
    </Fragment>
  );
};

export default Dashboard;
