// Device
import {
  DEVICEGRID_CHANGE_FILTER,
  DEVICEGRID_CHANGE_PAGINATION,
  DEVICEGRID_CHANGE_SORT,
  DEVICEGRID_FETCH,
  DEVICEGRID_FETCH_ERROR,
  DEVICEGRID_FETCH_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  data: [],
  dataTotal: 0,
  errors: [],
  filters: [],
  isFetching: false,
  page: 1,
  pageSize: 10,
  sortField: "name",
  sortOrder: "asc",
};

const deviceGrid = (state = initialState, action) => {
  switch (action.type) {
    // CHANGE - Filter
    case DEVICEGRID_CHANGE_FILTER:
      return {
        ...state,
        filters: action.payload.filters,
      };

    // CHANGE - Pagination
    case DEVICEGRID_CHANGE_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };

    // CHANGE - Sort
    case DEVICEGRID_CHANGE_SORT:
      return {
        ...state,
        sortField: action.payload.sortField,
        sortOrder: action.payload.sortOrder,
      };

    // FETCH
    case DEVICEGRID_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case DEVICEGRID_FETCH_ERROR:
      return {
        ...state,
        data: [],
        dataTotal: 0,
        errors: action.payload,
        isFetching: false,
      };
    case DEVICEGRID_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        dataTotal: action.payload.total,
        errors: [],
        isFetching: false,
      };

    default:
      return state;
  }
};

export default deviceGrid;
