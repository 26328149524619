import { faBracketsCurly } from "@fortawesome/pro-regular-svg-icons/faBracketsCurly";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faEthernet } from "@fortawesome/pro-regular-svg-icons/faEthernet";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { withFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonDetail,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import CardBodyHeader from "../../core/components/shared/CardBodyHeader";
import { DEVICE_TYPE_FIREWALL } from "../../../constants/codeLists";
import { ROUTE_DEVICE, ROUTE_DEVICE_DETAIL } from "../../../constants/routes";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Device
import { clearDevice, fetchDevice, initDevice, updateDevice } from "../actions/device";
import FieldsetAPI from "../components/FieldsetAPI";
import FieldsetGeneral from "../components/FieldsetGeneral";
import FieldsetOrganization from "../components/FieldsetOrganization";
import { PERMISSION_DEVICE_UPDATE, PERMISSION_DEVICE_VIEW } from "../constants/permissions";

const DevicePageEdit = (props) => {
  const {
    data,
    dataDeviceManufacturers,
    dataDeviceModels,
    dataDeviceModelSeries,
    dataDeviceTypes,
    dataOrganizationLocations,
    dataOrganizations,
    deviceErrors,
    handleSubmit,
    isSubmitting,
    match,
    setFieldValue,
    values,
  } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.devices");
  }, []);

  useEffect(() => {
    props.fetchDevice(match.params.id);

    return () => {
      props.clearDevice();
    };
  }, [match.params.id]);

  // Aktualizace formulare pri zmene ID organizace
  useEffect(() => {
    if (!isEmpty(data)) {
      props.initDevice(values, match.params.id);
    }
  }, [values.idDeviceManufacturer, values.idDeviceModelSeries, values.idDeviceType, values.idOrganization]);

  // Aktualizace formulare pri zmene ID type
  useEffect(() => {
    if (!isEmpty(data) && DEVICE_TYPE_FIREWALL !== values.idDeviceType) {
      setFieldValue("apiUri", "");
      setFieldValue("apiUsernameRead", "");
      setFieldValue("apiUsernameWrite", "");
      setFieldValue("apiPasswordRead", "");
      setFieldValue("apiPasswordWrite", "");
    }
  }, [values.idDeviceType]);

  // Aktualizace formulare pri zmene ID type
  // useEffect(() => {
  //   if (!isEmpty(data)) {
  //     setFieldValue('idDeviceModelSeries', '');
  //     setFieldValue('idDeviceModel', '');
  //   }
  // }, [values.idDeviceManufacturer]);

  // Aktualizace formulare pri zmene ID type
  useEffect(() => {
    if (!isEmpty(data) && DEVICE_TYPE_FIREWALL !== values.idDeviceType) {
      setFieldValue("apiUri", "");
      setFieldValue("apiUsernameRead", "");
      setFieldValue("apiUsernameWrite", "");
      setFieldValue("apiPasswordRead", "");
      setFieldValue("apiPasswordWrite", "");
    }
  }, [values.idDeviceType]);

  // Kontrola opravneni
  if (!hasPermission(PERMISSION_DEVICE_UPDATE)) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faEthernet} title={t("title.devices")} />
        <ContentHeaderButtons>
          <ButtonSave disabled={isSubmitting} executing={isSubmitting} form="form-devices" text={t("action.save")} />
          {hasPermission(PERMISSION_DEVICE_VIEW) && (
            <ButtonDetail text={t("action.detail")} to={generatePath(ROUTE_DEVICE_DETAIL, { id: match.params.id })} />
          )}
          <ButtonCancel text={t("action.cancel")} to={ROUTE_DEVICE} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={deviceErrors} />

      <ContentBody>
        <FormikForm id="form-devices" noValidate={true} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardBodyHeader icon={faFile} title={t("title.general")} />
                  <FieldsetGeneral
                    dataDeviceManufacturers={dataDeviceManufacturers}
                    dataDeviceModels={dataDeviceModels}
                    dataDeviceModelSeries={dataDeviceModelSeries}
                    dataDeviceTypes={dataDeviceTypes}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardBodyHeader icon={faBuilding} title={t("title.location")} />
                  <FieldsetOrganization
                    dataOrganizationLocations={dataOrganizationLocations}
                    dataOrganizations={dataOrganizations}
                  />
                </CardBody>
              </Card>
              {DEVICE_TYPE_FIREWALL === values.idDeviceType && (
                <Card>
                  <CardBody>
                    <CardBodyHeader icon={faBracketsCurly} title={t("title.api")} />
                    <FieldsetAPI passwordRequired={false} />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </FormikForm>
      </ContentBody>
    </Fragment>
  );
};

DevicePageEdit.propTypes = {
  clearDevice: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dataDeviceManufacturers: PropTypes.array.isRequired,
  dataDeviceModels: PropTypes.array.isRequired,
  dataDeviceModelSeries: PropTypes.array.isRequired,
  dataDeviceTypes: PropTypes.array.isRequired,
  dataOrganizationLocations: PropTypes.array.isRequired,
  dataOrganizations: PropTypes.array.isRequired,
  deviceErrors: PropTypes.array.isRequired,
  fetchDevice: PropTypes.func.isRequired,
  initDevice: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateDevice: PropTypes.func.isRequired,
};

const DevicePageEditFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    apiUri: props.data.apiUri || "",
    apiUsernameRead: props.data.apiUsernameRead || "",
    apiUsernameWrite: props.data.apiUsernameWrite || "",
    apiPasswordRead: props.data.apiPasswordRead || "",
    apiPasswordWrite: props.data.apiPasswordWrite || "",
    idDeviceManufacturer: props.data.idDeviceManufacturer || "",
    idDeviceModel: props.data.idDeviceModel || "",
    idDeviceModelSeries: props.data.idDeviceModelSeries || "",
    idDeviceType: props.data.idDeviceType || "",
    idOrganization: props.data.idOrganization || "",
    idOrganizationLocation: props.data.idOrganizationLocation || "",
    mac: props.data.mac || "",
    name: props.data.name || "",
    sn: props.data.sn || "",
  }),
  handleSubmit: async (values, { props }) => {
    props.updateDevice(values, props.match.params.id).then(() => {
      if (0 === props.deviceErrors.length) {
        props.history.push(ROUTE_DEVICE);
      }
    });
  },
})(DevicePageEdit);

const mapDispatchToProps = {
  clearDevice,
  fetchDevice,
  initDevice,
  updateDevice,
};

const mapStateToProps = (state) => ({
  data: state.device.data,
  dataDeviceManufacturers: state.device.dataDeviceManufacturers,
  dataDeviceModels: state.device.dataDeviceModels,
  dataDeviceModelSeries: state.device.dataDeviceModelSeries,
  dataDeviceTypes: state.device.dataDeviceTypes,
  dataOrganizationLocations: state.device.dataOrganizationLocations,
  dataOrganizations: state.device.dataOrganizations,
  deviceErrors: state.device.errors,
  isSubmitting: state.device.isSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicePageEditFormik);
