import { faUserShield } from "@fortawesome/pro-regular-svg-icons/faUserShield";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import { ROUTE_ROLE } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Role
import { formClear, formInit, formSubmitCreate } from "../actions/role";
import { RoleFieldsetBase, RoleFieldsetPermissions } from "../components";

const RolePageAdd = (props) => {
  const { formData, formDataPermissions, formErrors, formIsSubmitting, history } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.roles");

    props.formInit();

    return () => {
      props.formClear();
    };
  }, []);

  const handleSubmit = (values) => {
    props.formSubmitCreate(values).then(() => {
      if (0 === props.formErrors.length) {
        history.push(ROUTE_ROLE);
      }
    });
  };

  // Kontrola opravneni
  if (!hasPermission("role.create")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faUserShield} title={t("title.roles")} />
        <ContentHeaderButtons>
          <ButtonSave
            disabled={formIsSubmitting}
            executing={formIsSubmitting}
            form="form-roles"
            text={t("action.save")}
          />
          <ButtonCancel text={t("action.cancel")} to={ROUTE_ROLE} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={formErrors} />

      <ContentBody>
        <Formik
          initialValues={{
            name: formData.name || "",
            idPermissions: formData.idPermissions || [],
          }}
          onSubmit={handleSubmit}
          render={(formik) => (
            <FormikForm id="form-roles" onSubmit={formik.handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <RoleFieldsetBase />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <RoleFieldsetPermissions permissions={formDataPermissions} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </FormikForm>
          )}
        />
      </ContentBody>
    </Fragment>
  );
};

RolePageAdd.propTypes = {
  formClear: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formDataPermissions: PropTypes.array.isRequired,
  formErrors: PropTypes.array.isRequired,
  formInit: PropTypes.func.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
  formSubmitCreate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  formClear,
  formInit,
  formSubmitCreate,
};

const mapStateToProps = (state) => ({
  formData: state.role.formData,
  formDataPermissions: state.role.formDataPermissions,
  formErrors: state.role.formErrors,
  formIsSubmitting: state.role.formIsSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(RolePageAdd);
