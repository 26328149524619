import PropTypes from "prop-types";
import React from "react";
import Label from "reactstrap/es/Label";

const FieldLabel = (props) => {
  let label = props.label || props.children;

  return (
    <Label for={props.for}>
      {label}
      {props.required && <em className="required"> *</em>}
    </Label>
  );
};

FieldLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  for: PropTypes.string,
  required: PropTypes.bool.isRequired,
};

export default FieldLabel;
