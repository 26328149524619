import { createContext } from "react";

const authContext = createContext({
  identity: {},
  identityPermissions: [],
  hasPermission: () => {},
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
export const AuthContext = authContext;
