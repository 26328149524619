import * as querystring from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";

// App
import { AlertErrors } from "../../core/components";
import { ROUTE_AUTH_FORGOTPASSWORD, ROUTE_DASHBOARD } from "../../../constants";

// Auth
import { signInClear, signInSubmit } from "../actions/auth";
import { getIsAuthenticated, getSignInErrors, getSignInSubmitting } from "../selectors/auth";
import SignInForm from "../components/SignInForm";

// Hooks
import useTitle from "../../core/hooks/useTitle";

const SignInPage = (props) => {
  const { history, identityAuthenticated, signInErrors, signInSubmitting } = props;
  const { t } = useTranslation();

  // Titulek
  useTitle(t("title.signIn"));

  // Vycisteni po opusteni komponenty
  useEffect(() => {
    return () => {
      props.signInClear();
    };
  }, []);

  // Presmerujeme do APP po uspesnem prihlaseni
  useEffect(() => {
    if (true === identityAuthenticated) {
      const parsed = querystring.parse(history.location.search);

      if (parsed.r) {
        history.push(parsed.r);
      } else {
        history.push(ROUTE_DASHBOARD);
      }
    }
  }, [history, identityAuthenticated]);

  return (
    <div id="signin" className="container-450">
      <Card>
        <CardBody>
          <AlertErrors errors={signInErrors} />
          <SignInForm isSubmitting={signInSubmitting} onSubmit={props.signInSubmit} />
        </CardBody>
        <CardFooter className="text-center">
          <Link to={ROUTE_AUTH_FORGOTPASSWORD}>{t("action.didYouForgetPassword")}</Link>
        </CardFooter>
      </Card>
    </div>
  );
};

const mapDispatchToProps = {
  signInClear,
  signInSubmit,
};

const mapStateToProps = (state) => {
  return {
    identityAuthenticated: getIsAuthenticated(state),
    signInErrors: getSignInErrors(state),
    signInSubmitting: getSignInSubmitting(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
