import React from "react";
import { Route, Switch } from "react-router";

// Components
import Error404 from "./Error404";
import Footer from "./shared/AppPublic/Footer";
import Header from "./shared/AppPublic/Header";

// Constants
import {
  ROUTE_AUTH_FORGOTPASSWORD,
  ROUTE_AUTH_RESETPASSWORD,
  ROUTE_AUTH_SIGNIN,
  ROUTE_AUTH_SIGNOUT,
} from "../../../constants";

// Containers
import { ForgotPasswordPage, ResetPasswordPage, SignInPage, SignOutPage } from "../../auth/containers/index";

const AppPublic = () => {
  return (
    <div id="public">
      <header>
        <Header />
      </header>
      <main>
        <Switch>
          <Route component={ForgotPasswordPage} exact path={ROUTE_AUTH_FORGOTPASSWORD} />
          <Route component={ResetPasswordPage} exact path={ROUTE_AUTH_RESETPASSWORD} />
          <Route component={SignInPage} exact path={ROUTE_AUTH_SIGNIN} />
          <Route component={SignOutPage} exact path={ROUTE_AUTH_SIGNOUT} />
          <Route component={Error404} />
        </Switch>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default AppPublic;
