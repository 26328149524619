import PropTypes from "prop-types";
import Spinner from "./shared/Spinner";
import React from "react";
import { useTranslation } from "react-i18next";

const AppLoader = () => {
  const { t } = useTranslation();

  return (
    <div id="public">
      <main>
        <div className="container-450">
          <Spinner text={t("notice.loadingAppConfig")} />
        </div>
      </main>
    </div>
  );
};

AppLoader.propTypes = {
  text: PropTypes.string,
};

export default AppLoader;
