// Organization location
import {
  ORGANIZATIONLOCATION_FORM_CLEAR,
  ORGANIZATIONLOCATION_FORM_FETCH_REQUEST,
  ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_FORM_INIT_REQUEST,
  ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST,
  ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_GRID_CHANGE_FILTER,
  ORGANIZATIONLOCATION_GRID_CHANGE_PAGINATION,
  ORGANIZATIONLOCATION_GRID_CHANGE_SORT,
  ORGANIZATIONLOCATION_GRID_DELETE_REQUEST,
  ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_SUCCESS,
  ORGANIZATIONLOCATION_GRID_FETCH_REQUEST,
  ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR,
  ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  formData: {},
  formDataOrganizations: [],
  formDataParents: [],
  formErrors: [],
  formIsFetching: false,
  formIsInitializing: false,
  formIsSubmitting: false,
  gridErrors: [],
  gridData: [],
  gridDataTotal: 0,
  gridIsDeleting: false,
  gridIsFetching: false,
  gridFilters: [],
  gridPage: 1,
  gridPageSize: 10,
  gridSortField: "nameComposed",
  gridSortOrder: "asc",
};

const organizationLocation = (state = initialState, action) => {
  switch (action.type) {
    // FORM - Clear
    case ORGANIZATIONLOCATION_FORM_CLEAR:
      return {
        ...state,
        formData: {},
        formDataOrganizations: [],
        formDataParents: [],
        formErrors: [],
        formIsFetching: false,
        formIsInitializing: false,
        formIsSubmitting: false,
      };

    // FORM - Fetch
    case ORGANIZATIONLOCATION_FORM_FETCH_REQUEST:
      return {
        ...state,
        formData: {},
        formErrors: [],
        formIsFetching: true,
      };
    case ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR:
      return {
        ...state,
        formErrors: action.payload,
        formIsFetching: false,
      };
    case ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_SUCCESS:
      return {
        ...state,
        formData: action.payload,
        formIsFetching: false,
      };

    // FORM - New
    case ORGANIZATIONLOCATION_FORM_INIT_REQUEST:
      return {
        ...state,
        formIsInitializing: true,
      };
    case ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR:
      return {
        ...state,
        formDataParents: [],
        formDataOrganizations: [],
        formIsInitializing: false,
      };
    case ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_SUCCESS:
      return {
        ...state,
        formDataParents: action.payload.parents,
        formDataOrganizations: action.payload.organizations,
        formIsInitializing: false,
      };

    // FORM - Submit
    case ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST:
      return {
        ...state,
        formIsSubmitting: true,
      };
    case ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR:
      return {
        ...state,
        formErrors: action.payload,
        formIsSubmitting: false,
      };
    case ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS:
      return {
        ...state,
        formErrors: [],
        formIsSubmitting: false,
      };

    // GRID - Change - Filter
    case ORGANIZATIONLOCATION_GRID_CHANGE_FILTER:
      return {
        ...state,
        gridFilters: action.payload.filters,
      };

    // GRID - Change - Pagination
    case ORGANIZATIONLOCATION_GRID_CHANGE_PAGINATION:
      return {
        ...state,
        gridPage: action.payload.page,
        gridPageSize: action.payload.pageSize,
      };

    // GRID - Change - Sort
    case ORGANIZATIONLOCATION_GRID_CHANGE_SORT:
      return {
        ...state,
        gridSortField: action.payload.sortField,
        gridSortOrder: action.payload.sortOrder,
      };

    // GRID - Delete
    case ORGANIZATIONLOCATION_GRID_DELETE_REQUEST:
      return {
        ...state,
        gridIsDeleting: true,
      };
    case ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR:
      return {
        ...state,
        gridData: [],
        gridDataTotal: 0,
        gridIsDeleting: false,
        gridErrors: action.payload,
      };
    case ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_SUCCESS:
      return {
        ...state,
        gridIsDeleting: false,
        gridErrors: [],
      };

    // GRID - Fetch
    case ORGANIZATIONLOCATION_GRID_FETCH_REQUEST:
      return {
        ...state,
        gridIsFetching: true,
      };
    case ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR:
      return {
        ...state,
        gridData: [],
        gridDataTotal: 0,
        gridErrors: action.payload,
        gridIsFetching: false,
      };
    case ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_SUCCESS:
      return {
        ...state,
        gridData: action.payload.data,
        gridDataTotal: action.payload.total,
        gridErrors: [],
        gridIsFetching: false,
      };
    default:
      return state;
  }
};

export default organizationLocation;
