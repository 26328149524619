export const fetchDelete = (uri) => {
  return doFetch("DELETE", uri);
};

export const fetchGet = (uri) => {
  return doFetch("GET", uri);
};

export const fetchPatch = (uri, data) => {
  return doFetch("PATCH", uri, data);
};

export const fetchPost = (uri, data, isUpload) => {
  return doFetch("POST", uri, data, isUpload);
};

export const fetchPut = (uri, data) => {
  return doFetch("PUT", uri, data);
};

const doFetch = (method, uri, data = null, isUpload = false) => {
  let content = null;
  let headers = new Headers();

  // Authorization
  if (localStorage.getItem("jwtAuth")) {
    headers.append("Authorization", "Bearer " + localStorage.getItem("jwtAuth"));
  }

  // Content
  if (true === isUpload) {
    const formData = new FormData();

    for (const key in data) {
      console.log("HAS");
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    content = formData;
  } else {
    content = null !== data ? JSON.stringify(data) : null;
    headers.append("Accept", "application/json");
  }

  // Content-Type
  if (false === isUpload) {
    headers.append("Content-Type", "application/json");
  }

  return fetch(process.env.REACT_APP_HOST_API + uri, {
    method: method,
    headers: headers,
    body: content,
  })
    .then(async (response) => {
      const contentType = response.headers.get("Content-Type");
      let body = null;

      if (contentType.includes("application/json")) {
        body = response.json();
      } else if (contentType.includes("text/")) {
        body = response.text();
      } else {
        return response.blob();
      }

      await body.then(function (result) {
        body = result;
      });

      return {
        body: body,
        ok: response.ok,
        status: response.status,
        statusMessage: response.statusMessage,
      };
    })
    .catch((error) => {
      throw error;
    });
};
