import PropTypes from "prop-types";
import React from "react";
import FormFeedback from "reactstrap/es/FormFeedback";

const FieldFeedback = (props) => {
  if (!props.error) {
    return null;
  }

  return <FormFeedback>{props.error}</FormFeedback>;
};

FieldFeedback.propTypes = {
  error: PropTypes.string,
};

export default FieldFeedback;
