import get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";

// Selectors
import { appConfigData } from "../../../selectors/appConfig";

const Header = () => {
  const appConfig = useSelector(appConfigData);

  // Nacteme si konfiguraci
  const url = "/img/logo/" + get(appConfig, "logo1");

  return (
    <div id="logo">
      <img src={url} alt={get(appConfig, "title")} />
    </div>
  );
};

export default Header;
