import { generatePath } from "react-router";
import { fetchGet } from "../../../utilities";

// Constants
import {
  DEVICEIMPORTS_FETCH,
  DEVICEIMPORTS_FETCH_ERROR,
  DEVICEIMPORTS_FETCH_OK,
  DEVICEIMPORTS_RESET,
} from "../constants/actionTypes";
import { URI_DEVICE_X_IMPORT } from "../constants/uris";

// IMPORTS - Reset
export const resetImports = () => {
  return (dispatch) => {
    dispatch({
      type: DEVICEIMPORTS_RESET,
    });
  };
};

export const fetchImports = (deviceId, offset) => {
  return (dispatch, getState) => {
    const state = getState();

    if (false === state.deviceImports.isFetching) {
      dispatch({
        type: DEVICEIMPORTS_FETCH,
      });

      fetchGet(generatePath(URI_DEVICE_X_IMPORT, { id: deviceId }) + "?o=" + offset)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICEIMPORTS_FETCH_OK,
              payload: {
                data: res.body,
                offset: offset,
              },
            });
          } else {
            dispatch({
              type: DEVICEIMPORTS_FETCH_ERROR,
              payload: {
                errors: res.body,
              },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICEIMPORTS_FETCH_ERROR,
            payload: {
              errors: [err.message],
            },
          });
        });
    }
  };
};
