import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CustomInput from "reactstrap/es/CustomInput";
import Table from "reactstrap/es/Table";

// Components
import AlertErrors from "../../../core/components/shared/AlertErrors";
import Button from "../../../core/components/shared/Button";
import CardTextEmpty from "../../../core/components/shared/CardTextEmpty";
import { usePermission } from "../../../auth/hooks/usePermission";

// Utilities
import { valueRender } from "../../../../utilities";

// Device
import { PERMISSION_DEVICEACCESSRULE_REFRESH, PERMISSION_DEVICEACCESSRULE_UPDATE } from "../../constants/permissions";

const AccessRules = (props) => {
  const { deviceId, disabledIds, errors, isRefreshing, items } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();
  const showTable = items.length > 0;

  useEffect(() => {
    props.fetchAccessRules(deviceId);

    return () => {
      props.clearAccessRules();
    };
  }, []);

  function handleChange(id, data) {
    props.updateAccessRule(deviceId, id, data);
  }

  function handleUpdate(e) {
    e.preventDefault();

    props.refreshAccessRules(deviceId);
  }

  return (
    <Card>
      <CardBody>
        <AlertErrors errors={errors} />
        {hasPermission(PERMISSION_DEVICEACCESSRULE_REFRESH) && (
          <Fragment>
            <div className="text-center">
              <Button
                color="primary"
                executing={isRefreshing}
                icon={faDownload}
                onClick={handleUpdate}
                outline={false}
                size="sm"
                text={t("action.updateRulesFromDevice")}
              />
            </div>
            <hr />
          </Fragment>
        )}
        {showTable ? (
          <Table className="mb-0" size="sm" borderless striped>
            <thead>
              <tr>
                <th className="w-50">{t("title.name")}</th>
                <th className="w-50">{t("title.showInDashboard")}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((accessRule) => (
                <tr key={accessRule.id}>
                  <td>{valueRender(accessRule, "name")}</td>
                  <td>
                    <CustomInput
                      checked={accessRule.showInDashboard}
                      disabled={!hasPermission(PERMISSION_DEVICEACCESSRULE_UPDATE) || disabledIds[accessRule.id]}
                      id={"showInDashboard_" + accessRule.id}
                      onChange={() => handleChange(accessRule.id, { showInDashboard: !accessRule.showInDashboard })}
                      type="switch"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <CardTextEmpty text={t("text.noAccessRules")} />
        )}
      </CardBody>
    </Card>
  );
};

AccessRules.propTypes = {
  clearAccessRules: PropTypes.func.isRequired,
  deviceId: PropTypes.string.isRequired,
  disabledIds: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  fetchAccessRules: PropTypes.func.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  refreshAccessRules: PropTypes.func.isRequired,
  updateAccessRule: PropTypes.func.isRequired,
};

export default AccessRules;
