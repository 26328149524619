import { createSelector } from "reselect/lib/index";
import { normalizeMac } from "../../../utilities/normalizers";

export const getData = (state) => state.deviceTimeline.data;
export const getErrors = (state) => state.deviceTimeline.errors;
export const getFilter = (state) => state.deviceTimeline.filter;
export const getIsFetching = (state) => state.deviceTimeline.isFetching;

export const getDataByDateRange = createSelector([getData, getFilter], (rows, filter) =>
  rows.filter((row) => {
    return row.datetime >= filter.dateFrom + "00:00:00" && row.datetime <= filter.dateTo + "23:59:59";
  })
);

export const getDataByFilter = createSelector([getDataByDateRange, getFilter], (rows, filter) => {
  let keyword = filter.text;
  let keywordMac = normalizeMac(keyword);
  let items = [];

  rows.forEach((row) => {
    let item = {
      datetime: row.datetime,
      ports: {},
      remoteDevices: {},
      vlans: {},
    };

    // Filter Ports
    if (filter.types.includes("ports") && row.ports) {
      if (filter.text) {
        ["added", "removed"].forEach((change) => {
          if (row.ports[change]) {
            item.ports[change] = row.ports[change].filter((port) => {
              return (
                (port.name && port.name.includes(keyword)) || (port.description && port.description.includes(keyword))
              );
            });
          }
        });
      } else {
        item.ports = row.ports;
      }
    }

    // Filter Remote devices
    if (filter.types.includes("remoteDevices") && row.remoteDevices) {
      if (filter.text) {
        ["added", "removed"].forEach((change) => {
          if (row.remoteDevices[change]) {
            item.remoteDevices[change] = row.remoteDevices[change].filter((remoteDevice) => {
              return (
                (remoteDevice.name && remoteDevice.name.includes(keyword)) ||
                (remoteDevice.mac && remoteDevice.mac.includes(keywordMac))
              );
            });
          }
        });
      } else {
        item.remoteDevices = row.remoteDevices;
      }
    }

    // Filter VLAN
    if (filter.types.includes("vlans") && row.vlans) {
      if (filter.text) {
        ["added", "removed"].forEach((change) => {
          if (row.vlans[change]) {
            item.vlans[change] = row.vlans[change].filter((vlan) => {
              return (vlan.name && vlan.name.includes(keyword)) || (vlan.number && vlan.number.includes(keyword));
            });
          }
        });
      } else {
        item.vlans = row.vlans;
      }
    }

    items.push(item);
  });

  return items;
});
