import React from "react";
import { useTranslation } from "react-i18next";
import { validateRequired } from "../../../utilities/validators";
import { FormikField } from "../../core/components/formik";

const RoleFieldsetBase = () => {
  const { t } = useTranslation();

  return (
    <fieldset name="base">
      <FormikField label={t("title.name")} name="name" required={true} validate={validateRequired} />
    </fieldset>
  );
};

export default RoleFieldsetBase;
