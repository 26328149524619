// App
import { generatePath } from "react-router";
import { downloadDialog, fetchDelete, fetchGet, fetchPost } from "../../../utilities";

// Device
import {
  DEVICEIMPORT_CLEAR,
  DEVICEIMPORT_DELETE,
  DEVICEIMPORT_DELETE_ERROR,
  DEVICEIMPORT_DELETE_SUCCESS,
  DEVICEIMPORT_DOWNLOAD,
  DEVICEIMPORT_DOWNLOAD_ERROR,
  DEVICEIMPORT_DOWNLOAD_SUCCESS,
  DEVICEIMPORT_MODAL_CLEAR,
  DEVICEIMPORT_MODAL_TOGGLE,
  DEVICEIMPORT_MODAL_INIT,
  DEVICEIMPORT_MODAL_INIT_SUCCESS,
  DEVICEIMPORT_MODAL_INIT_ERROR,
  DEVICEIMPORT_MODAL_SUBMIT,
  DEVICEIMPORT_MODAL_SUBMIT_SUCCESS,
  DEVICEIMPORT_MODAL_SUBMIT_ERROR,
} from "../constants/actionTypes";
import {
  URI_DEVICE_X_IMPORT,
  URI_DEVICE_X_IMPORT_X,
  URI_DEVICE_X_IMPORT_FORM,
  URI_DEVICE_X_IMPORT_X_DOWNLOAD,
} from "../constants/uris";

// CLEAR
export const clearImport = () => {
  return {
    type: DEVICEIMPORT_CLEAR,
  };
};

// DELETE
export const deleteImport = (deviceId, fileId) => {
  return async (dispatch) => {
    dispatch({
      type: DEVICEIMPORT_DELETE,
    });

    await fetchDelete(generatePath(URI_DEVICE_X_IMPORT_X, { id: deviceId, importId: fileId }))
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICEIMPORT_DELETE_SUCCESS,
            payload: {
              uuid: fileId,
            },
          });
        } else {
          dispatch({
            type: DEVICEIMPORT_DELETE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICEIMPORT_DELETE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// DOWNLOAD
export const downloadImport = (deviceId, file) => {
  return async (dispatch) => {
    dispatch({
      type: DEVICEIMPORT_DOWNLOAD,
      payload: {
        uuid: file.uuid,
      },
    });

    let fileName = file.parser + "_" + file.datetime;
    fileName = fileName.replace(/[\W_]+/g, "_") + ".txt";

    await fetchGet(generatePath(URI_DEVICE_X_IMPORT_X_DOWNLOAD, { id: deviceId, importId: file.id }))
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICEIMPORT_DOWNLOAD_SUCCESS,
            payload: res.body,
          });

          downloadDialog(res.body, fileName, "text/plain;charset=utf-8");
        } else {
          dispatch({
            type: DEVICEIMPORT_DOWNLOAD_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICEIMPORT_DOWNLOAD_ERROR,
          payload: [err.message],
        });
      });
  };
};

// MODAL - Clear
export const clearModal = () => {
  return (dispatch) => {
    dispatch({
      type: DEVICEIMPORT_MODAL_CLEAR,
    });
  };
};

// MODAL - Close
export const closeModal = () => {
  return {
    type: DEVICEIMPORT_MODAL_TOGGLE,
    payload: {
      id: null,
      isOpen: false,
    },
  };
};

// MODAL - Init
export const initModal = (deviceId) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.deviceImport.modalIsInitializing) {
      dispatch({
        type: DEVICEIMPORT_MODAL_INIT,
      });

      await fetchPost(generatePath(URI_DEVICE_X_IMPORT_FORM, { id: deviceId }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICEIMPORT_MODAL_INIT_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: DEVICEIMPORT_MODAL_INIT_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICEIMPORT_MODAL_INIT_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// MODAL - Open
export const openModal = (id) => {
  return {
    type: DEVICEIMPORT_MODAL_TOGGLE,
    payload: {
      id: Number(id),
      isOpen: true,
    },
  };
};

// MODAL - Submit
export const submitModal = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: DEVICEIMPORT_MODAL_SUBMIT,
    });

    await fetchPost(generatePath(URI_DEVICE_X_IMPORT, { id: data.idDevice }), data, true)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICEIMPORT_MODAL_SUBMIT_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: DEVICEIMPORT_MODAL_SUBMIT_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICEIMPORT_MODAL_SUBMIT_ERROR,
          payload: [err.message],
        });
      });
  };
};
