// Auth
export const ROUTE_AUTH_FORGOTPASSWORD = "/forgot-password";
export const ROUTE_AUTH_RESETPASSWORD = "/reset-password/:hash";
export const ROUTE_AUTH_SIGNIN = "/sign-in";
export const ROUTE_AUTH_SIGNOUT = "/sign-out";

// Dashboard
export const ROUTE_DASHBOARD = "/";

// Device
export const ROUTE_DEVICE = "/device";
export const ROUTE_DEVICE_ADD = "/device/add";
export const ROUTE_DEVICE_DETAIL = "/device/detail/:id";
export const ROUTE_DEVICE_EDIT = "/device/edit/:id";

// Organization
export const ROUTE_ORGANIZATION = "/organization";
export const ROUTE_ORGANIZATION_ADD = "/organization/add";
export const ROUTE_ORGANIZATION_EDIT = "/organization/edit/:id";

// Organization location
export const ROUTE_ORGANIZATIONLOCATION = "/organization-location";
export const ROUTE_ORGANIZATIONLOCATION_ADD = "/organization-location/add";
export const ROUTE_ORGANIZATIONLOCATION_EDIT = "/organization-location/edit/:id";

// Role
export const ROUTE_ROLE = "/role";
export const ROUTE_ROLE_ADD = "/role/add";
export const ROUTE_ROLE_EDIT = "/role/edit/:id";

// User
export const ROUTE_USER = "/user";
export const ROUTE_USER_ADD = "/user/add";
export const ROUTE_USER_EDIT = "/user/edit/:id";
