// ORGANIZATIONLOCATION - Form
export const ORGANIZATIONLOCATION_FORM_CLEAR = "ORGANIZATIONLOCATION_FORM_CLEAR";
export const ORGANIZATIONLOCATION_FORM_FETCH_REQUEST = "ORGANIZATIONLOCATION_FORM_FETCH_REQUEST";
export const ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR = "ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_ERROR";
export const ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_SUCCESS = "ORGANIZATIONLOCATION_FORM_FETCH_RESPONSE_SUCCESS";
export const ORGANIZATIONLOCATION_FORM_INIT_REQUEST = "ORGANIZATIONLOCATION_FORM_INIT_REQUEST";
export const ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR = "ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_ERROR";
export const ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_SUCCESS = "ORGANIZATIONLOCATION_FORM_INIT_RESPONSE_SUCCESS";
export const ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST = "ORGANIZATIONLOCATION_FORM_SUBMIT_REQUEST";
export const ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR = "ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_ERROR";
export const ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS = "ORGANIZATIONLOCATION_FORM_SUBMIT_RESPONSE_SUCCESS";

// ORGANIZATIONLOCATION - Grid
export const ORGANIZATIONLOCATION_GRID_CHANGE_FILTER = "ORGANIZATIONLOCATION_GRID_CHANGE_FILTER";
export const ORGANIZATIONLOCATION_GRID_CHANGE_PAGINATION = "ORGANIZATIONLOCATION_GRID_CHANGE_PAGINATION";
export const ORGANIZATIONLOCATION_GRID_CHANGE_SORT = "ORGANIZATIONLOCATION_GRID_CHANGE_SORT";
export const ORGANIZATIONLOCATION_GRID_DELETE_REQUEST = "ORGANIZATIONLOCATION_GRID_DELETE_REQUEST";
export const ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR = "ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_ERROR";
export const ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_SUCCESS = "ORGANIZATIONLOCATION_GRID_DELETE_RESPONSE_SUCCESS";
export const ORGANIZATIONLOCATION_GRID_FETCH_REQUEST = "ORGANIZATIONLOCATION_GRID_FETCH_REQUEST";
export const ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR = "ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_ERROR";
export const ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_SUCCESS = "ORGANIZATIONLOCATION_GRID_FETCH_RESPONSE_SUCCESS";
