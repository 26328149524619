import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Table from "reactstrap/es/Table";

// App
import { valueRender } from "../../../../utilities";

const Organization = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Table className="table-detail" borderless>
      <tbody>
        <tr>
          <td>{t("title.organization")}:</td>
          <td>{valueRender(data, "organization")}</td>
        </tr>
        <tr>
          <td>{t("title.location")}:</td>
          <td>{valueRender(data, "organizationLocation")}</td>
        </tr>
      </tbody>
    </Table>
  );
};

Organization.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Organization;
