import has from "has-value";
import React from "react";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";

// Components
import TimelineRowPort from "./TimelineRowPort";
import TimelineRowRemoteDevice from "./TimelineRowRemoteDevice";
import TimelineRowVlan from "./TimelineRowVlan";

// Utilities
import { formatDateTime } from "../../../../utilities/formatter";

const TimelineRow = (props) => {
  const { row } = props;

  if (
    !has(row, "ports.added") &&
    !has(row, "ports.removed") &&
    !has(row, "remoteDevices.added") &&
    !has(row, "remoteDevices.removed") &&
    !has(row, "vlans.added") &&
    !has(row, "vlans.removed")
  ) {
    return null;
  }

  return (
    <div className="timeline-row">
      <div className="timeline-date">{formatDateTime(row.datetime)}</div>
      <div className="timeline-separator">
        <i className="timeline-dot-big" />
      </div>
      <div className="timeline-content">
        <Card>
          <CardBody>
            <TimelineRowPort ports={row.ports || {}} />
            <TimelineRowRemoteDevice remoteDevices={row.remoteDevices} />
            <TimelineRowVlan vlans={row.vlans || {}} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default TimelineRow;
