import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

class PasswordMinimalRequirements extends Component {
  render() {
    return (
      <Fragment>
        Heslo musí splňovat následující podmínky:
        <ul>
          {this.props.showDoNotMatchTheCurrentPasword && <li>Nesmí být shodné se současným heslem</li>}
          <li>
            Musí obsahovat alespoň <strong>8</strong> znaků
          </li>
          <li>
            Musí obsahovat alespoň <strong>1</strong> velké písmeno
          </li>
          <li>
            Musí obsahovat alespoň <strong>1</strong> malé písmeno
          </li>
          <li>
            Musí obsahovat alespoň <strong>1</strong> číslici
          </li>
        </ul>
      </Fragment>
    );
  }
}

PasswordMinimalRequirements.defaultProps = {
  showDoNotMatchTheCurrentPasword: false,
};

PasswordMinimalRequirements.propTypes = {
  showDoNotMatchTheCurrentPasword: PropTypes.bool,
};

export default PasswordMinimalRequirements;
