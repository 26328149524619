import React, { Fragment } from "react";
import Alert from "reactstrap/es/Alert";

const ResetPasswordSuccess = () => {
  return (
    <Fragment>
      <Alert className="text-center" color="success" transition={{ appear: false, timeout: 0 }}>
        Nové heslo bylo úspěšně uloženo.
      </Alert>
      <p className="mt-4 mb-0">
        Nyní se můžete vrátit na přihlašovací stránku a prostřednictvím nastaveného nového hesla se přihlásit.
      </p>
    </Fragment>
  );
};

export default ResetPasswordSuccess;
