import { generatePath } from "react-router";

// App
import { appendUrlParams, fetchDelete, fetchGet, fetchPatch, fetchPost } from "../../../utilities";
import { URI_ROLE, URI_ROLE_ADD, URI_ROLE_DELETE, URI_ROLE_EDIT, URI_ROLE_FORM } from "../constants/uris";

// Role
import {
  ROLE_FORM_CLEAR,
  ROLE_FORM_FETCH_REQUEST,
  ROLE_FORM_FETCH_RESPONSE_ERROR,
  ROLE_FORM_FETCH_RESPONSE_SUCCESS,
  ROLE_FORM_INIT_REQUEST,
  ROLE_FORM_INIT_RESPONSE_ERROR,
  ROLE_FORM_INIT_RESPONSE_SUCCESS,
  ROLE_FORM_SUBMIT_REQUEST,
  ROLE_FORM_SUBMIT_RESPONSE_ERROR,
  ROLE_FORM_SUBMIT_RESPONSE_SUCCESS,
  ROLE_GRID_CHANGE_FILTER,
  ROLE_GRID_CHANGE_PAGINATION,
  ROLE_GRID_CHANGE_SORT,
  ROLE_GRID_DELETE_REQUEST,
  ROLE_GRID_DELETE_RESPONSE_ERROR,
  ROLE_GRID_DELETE_RESPONSE_SUCCESS,
  ROLE_GRID_FETCH_REQUEST,
  ROLE_GRID_FETCH_RESPONSE_ERROR,
  ROLE_GRID_FETCH_RESPONSE_SUCCESS,
} from "../constants/actionTypes";

// FORM - Clear
export const formClear = () => {
  return (dispatch) => {
    dispatch({
      type: ROLE_FORM_CLEAR,
    });
  };
};

// FORM - Fetch
export const formFetch = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.role.formIsFetching) {
      dispatch({
        type: ROLE_FORM_FETCH_REQUEST,
      });

      await fetchGet(generatePath(URI_ROLE_EDIT, { id: id }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: ROLE_FORM_FETCH_RESPONSE_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: ROLE_FORM_FETCH_RESPONSE_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ROLE_FORM_FETCH_RESPONSE_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// FORM - Init
export const formInit = (data, id = null) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.role.formIsInitializing) {
      dispatch({
        type: ROLE_FORM_INIT_REQUEST,
      });

      if (null === id) {
        id = 0;
      }

      await fetchPost(generatePath(URI_ROLE_FORM, { id: id }), data)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: ROLE_FORM_INIT_RESPONSE_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: ROLE_FORM_INIT_RESPONSE_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ROLE_FORM_INIT_RESPONSE_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// FORM - Submit - Create
export const formSubmitCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ROLE_FORM_SUBMIT_REQUEST,
    });

    await fetchPost(URI_ROLE_ADD, data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ROLE_FORM_SUBMIT_RESPONSE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: ROLE_FORM_SUBMIT_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ROLE_FORM_SUBMIT_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// FORM - Submit - Update
export const formSubmitUpdate = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: ROLE_FORM_SUBMIT_REQUEST,
    });

    await fetchPatch(generatePath(URI_ROLE_EDIT, { id: id }), data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ROLE_FORM_SUBMIT_RESPONSE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: ROLE_FORM_SUBMIT_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ROLE_FORM_SUBMIT_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// GRID - Change - Filter
export const gridChangeFilter = (filters) => {
  return (dispatch) => {
    dispatch({
      type: ROLE_GRID_CHANGE_FILTER,
      payload: {
        filters: filters,
      },
    });
  };
};

// GRID - Change - Pagination
export const gridChangePagination = (page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: ROLE_GRID_CHANGE_PAGINATION,
      payload: {
        page: page,
        pageSize: pageSize,
      },
    });
  };
};

// GRID - Change - Sort
export const gridChangeSort = (sortField, sortOrder) => {
  return (dispatch) => {
    dispatch({
      type: ROLE_GRID_CHANGE_SORT,
      payload: {
        sortField: sortField,
        sortOrder: sortOrder,
      },
    });
  };
};

// GRID - Delete
export const gridDelete = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ROLE_GRID_DELETE_REQUEST,
    });

    await fetchDelete(generatePath(URI_ROLE_DELETE, { id: id }))
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: ROLE_GRID_DELETE_RESPONSE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: ROLE_GRID_DELETE_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ROLE_GRID_DELETE_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// GRID - Fetch
export const gridFetch = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (false === state.role.gridIsFetching) {
      dispatch({
        type: ROLE_GRID_FETCH_REQUEST,
      });

      const urlParams = appendUrlParams(
        state.role.gridFilters,
        state.role.gridPage,
        state.role.gridPageSize,
        state.role.gridSortField,
        state.role.gridSortOrder
      );

      fetchGet(URI_ROLE + urlParams)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: ROLE_GRID_FETCH_RESPONSE_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: ROLE_GRID_FETCH_RESPONSE_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ROLE_GRID_FETCH_RESPONSE_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};
