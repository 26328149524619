// DEVICE
export const DEVICE_CLEAR = "DEVICE_CLEAR";

export const DEVICE_DELETE = "DEVICE_DELETE";
export const DEVICE_DELETE_ERROR = "DEVICE_DELETE_ERROR";
export const DEVICE_DELETE_SUCCESS = "DEVICE_DELETE_SUCCESS";

export const DEVICE_FETCH = "DEVICE_FETCH";
export const DEVICE_FETCH_ERROR = "DEVICE_FETCH_ERROR";
export const DEVICE_FETCH_SUCCESS = "DEVICE_FETCH_SUCCESS";

export const DEVICE_INIT = "DEVICE_INIT";
export const DEVICE_INIT_ERROR = "DEVICE_INIT_ERROR";
export const DEVICE_INIT_SUCCESS = "DEVICE_INIT_SUCCESS";

export const DEVICE_SUBMIT = "DEVICE_SUBMIT";
export const DEVICE_SUBMIT_ERROR = "DEVICE_SUBMIT_ERROR";
export const DEVICE_SUBMIT_SUCCESS = "DEVICE_FORM_SUBMIT_RESPONSE_SUCCESS";

// DEVICEGRID
export const DEVICEGRID_CHANGE_FILTER = "DEVICEGRID_CHANGE_FILTER";
export const DEVICEGRID_CHANGE_PAGINATION = "DEVICEGRID_CHANGE_PAGINATION";
export const DEVICEGRID_CHANGE_SORT = "DEVICEGRID_CHANGE_SORT";

// DEVICEACCESSRULES
export const DEVICEACCESSRULES_CLEAR = "DEVICEACCESSRULES_CLEAR";

export const DEVICEACCESSRULES_FETCH = "DEVICEACCESSRULES_FETCH";
export const DEVICEACCESSRULES_FETCH_ERROR = "DEVICEACCESSRULES_FETCH_ERROR";
export const DEVICEACCESSRULES_FETCH_OK = "DEVICEACCESSRULES_FETCH_OK";

export const DEVICEACCESSRULES_REFRESH = "DEVICEACCESSRULES_REFRESH";
export const DEVICEACCESSRULES_REFRESH_ERROR = "DEVICEACCESSRULES_REFRESH_ERROR";
export const DEVICEACCESSRULES_REFRESH_OK = "DEVICEACCESSRULES_REFRESH_OK";

export const DEVICEACCESSRULES_SUBMIT = "DEVICEACCESSRULES_SUBMIT";
export const DEVICEACCESSRULES_SUBMIT_ERROR = "DEVICEACCESSRULES_SUBMIT_ERROR";
export const DEVICEACCESSRULES_SUBMIT_OK = "DEVICEACCESSRULES_SUBMIT_OK";

// DEVICEGRID
export const DEVICEGRID_FETCH = "DEVICEGRID_FETCH";
export const DEVICEGRID_FETCH_ERROR = "DEVICEGRID_FETCH_ERROR";
export const DEVICEGRID_FETCH_SUCCESS = "DEVICEGRID_FETCH_SUCCESS";

// DEVICEIMPORT - Clear
export const DEVICEIMPORT_CLEAR = "DEVICEIMPORT_CLEAR";

export const DEVICEIMPORT_DELETE = "DEVICEIMPORT_DELETE";
export const DEVICEIMPORT_DELETE_ERROR = "DEVICEIMPORT_DELETE_ERROR";
export const DEVICEIMPORT_DELETE_SUCCESS = "DEVICEIMPORT_DELETE_SUCCESS";

export const DEVICEIMPORT_DOWNLOAD = "DEVICEIMPORT_DOWNLOAD";
export const DEVICEIMPORT_DOWNLOAD_ERROR = "DEVICEIMPORT_DOWNLOAD_ERROR";
export const DEVICEIMPORT_DOWNLOAD_SUCCESS = "DEVICEIMPORT_DOWNLOAD_SUCCESS";

export const DEVICEIMPORT_MODAL_CLEAR = "DEVICEIMPORT_MODAL_CLEAR";
export const DEVICEIMPORT_MODAL_INIT = "DEVICEIMPORT_MODAL_INIT";
export const DEVICEIMPORT_MODAL_INIT_ERROR = "DEVICEIMPORT_MODAL_INIT_ERROR";
export const DEVICEIMPORT_MODAL_INIT_SUCCESS = "DEVICEIMPORT_MODAL_INIT_SUCCESS";
export const DEVICEIMPORT_MODAL_TOGGLE = "DEVICEIMPORT_MODAL_TOGGLE";
export const DEVICEIMPORT_MODAL_SUBMIT = "DEVICEIMPORT_MODAL_SUBMIT";
export const DEVICEIMPORT_MODAL_SUBMIT_ERROR = "DEVICEIMPORT_MODAL_SUBMIT_ERROR";
export const DEVICEIMPORT_MODAL_SUBMIT_SUCCESS = "DEVICEIMPORT_MODAL_SUBMIT_SUCCESS";

// DEVICEIMPORTS
export const DEVICEIMPORTS_FETCH = "DEVICEIMPORTS_FETCH";
export const DEVICEIMPORTS_FETCH_ERROR = "DEVICEIMPORTS_FETCH_ERROR";
export const DEVICEIMPORTS_FETCH_OK = "DEVICEIMPORTS_FETCH_OK";
export const DEVICEIMPORTS_RESET = "DEVICEIMPORTS_RESET";

// DEVICEPORTS
export const DEVICEPORTS_CLEAR = "DEVICEPORTS_CLEAR";
export const DEVICEPORTS_FETCH = "DEVICEPORTS_FETCH";
export const DEVICEPORTS_FETCH_ERROR = "DEVICEPORTS_FETCH_ERROR";
export const DEVICEPORTS_FETCH_SUCCESS = "DEVICEPORTS_FETCH_SUCCESS";

// DEVICETIMELINE
export const DEVICETIMELINE_CHANGE_FILTER = "DEVICETIMELINE_CHANGE_FILTER";
export const DEVICETIMELINE_FETCH = "DEVICETIMELINE_FETCH";
export const DEVICETIMELINE_FETCH_ERROR = "DEVICETIMELINE_FETCH_ERROR";
export const DEVICETIMELINE_FETCH_SUCCESS = "DEVICETIMELINE_FETCH_SUCCESS";
