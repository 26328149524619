import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import CardHeader from "reactstrap/es/CardHeader";

// App
import { AlertErrors } from "../../core/components";
import { ROUTE_AUTH_SIGNIN } from "../../../constants";

// Auth
import { forgotPasswordClear, forgotPasswordSubmit } from "../actions/auth";
import { ForgotPasswordForm, ForgotPasswordSuccess } from "../components";

// Hooks
import useTitle from "../../core/hooks/useTitle";

const ForgotPasswordPage = (props) => {
  const { forgotPasswordErrors, forgotPasswordSubmitting, forgotPasswordSubmittingResult } = props;
  const { t } = useTranslation();

  // Titulek
  useTitle(t("title.forgotPassword"));

  useEffect(() => {
    return () => {
      props.forgotPasswordClear();
    };
  }, []);

  function handleSubmit(formApi) {
    this.props.forgotPasswordSubmit(formApi.getValues());
  }

  return (
    <div id="forgot-password" className="container-450">
      <Card>
        <CardHeader>
          <h1>{t("title.forgotPassword")}</h1>
        </CardHeader>
        <CardBody>
          <AlertErrors errors={forgotPasswordErrors} />
          {forgotPasswordSubmittingResult ? (
            <ForgotPasswordSuccess />
          ) : (
            <ForgotPasswordForm
              forgotPasswordSubmit={handleSubmit}
              forgotPasswordSubmitting={forgotPasswordSubmitting}
            />
          )}
        </CardBody>
        <CardFooter className="text-center">
          <Link to={ROUTE_AUTH_SIGNIN}>{t("action.backToSignIn")}</Link>
        </CardFooter>
      </Card>
    </div>
  );
};

ForgotPasswordPage.propTypes = {
  forgotPasswordClear: PropTypes.func.isRequired,
  forgotPasswordErrors: PropTypes.array.isRequired,
  forgotPasswordSubmit: PropTypes.func.isRequired,
  forgotPasswordSubmitting: PropTypes.bool.isRequired,
  forgotPasswordSubmittingResult: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  forgotPasswordClear,
  forgotPasswordSubmit,
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordErrors: state.auth.forgotPasswordErrors,
    forgotPasswordSubmitting: state.auth.forgotPasswordSubmitting,
    forgotPasswordSubmittingResult: state.auth.forgotPasswordSubmittingResult,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
