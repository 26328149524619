import PropTypes from "prop-types";
import React from "react";
import Form from "reactstrap/es/Form";

const FormikForm = (props) => {
  return <Form {...props}>{props.children}</Form>;
};

FormikForm.defaultProps = {
  noValidate: true,
};
FormikForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormikForm;
