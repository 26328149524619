// Device
import {
  DEVICE_CLEAR,
  DEVICE_DELETE,
  DEVICE_DELETE_ERROR,
  DEVICE_DELETE_SUCCESS,
  DEVICE_FETCH,
  DEVICE_FETCH_ERROR,
  DEVICE_FETCH_SUCCESS,
  DEVICE_INIT,
  DEVICE_INIT_ERROR,
  DEVICE_INIT_SUCCESS,
  DEVICE_SUBMIT,
  DEVICE_SUBMIT_ERROR,
  DEVICE_SUBMIT_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  data: {},
  dataDeviceManufacturers: [],
  dataDeviceModels: [],
  dataDeviceModelSeries: [],
  dataDeviceTypes: [],
  dataOrganizationLocations: [],
  dataOrganizations: [],
  errors: [],
  isDeleting: false,
  isFetching: false,
  isInitializing: false,
  isSubmitting: false,
};

const device = (state = initialState, action) => {
  switch (action.type) {
    // CLEAR
    case DEVICE_CLEAR:
      return initialState;

    // DELETE
    case DEVICE_DELETE:
      return {
        ...state,
        isDeleting: true,
      };
    case DEVICE_DELETE_ERROR:
      return {
        ...state,
        data: [],
        dataTotal: 0,
        isDeleting: false,
        errors: action.payload,
      };
    case DEVICE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        errors: [],
      };

    // FETCH
    case DEVICE_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case DEVICE_FETCH_ERROR:
      return {
        ...state,
        data: {},
        errors: action.payload,
        isFetching: false,
      };
    case DEVICE_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errors: [],
        isFetching: false,
      };

    // INIT
    case DEVICE_INIT:
      return {
        ...state,
        isInitializing: true,
      };
    case DEVICE_INIT_ERROR:
      return {
        ...state,
        dataDeviceManufacturers: [],
        dataDeviceModels: [],
        dataDeviceModelSeries: [],
        dataDeviceTypes: [],
        dataOrganizationLocations: [],
        dataOrganizations: [],
        isInitializing: false,
      };
    case DEVICE_INIT_SUCCESS:
      return {
        ...state,
        dataDeviceManufacturers: action.payload.deviceManufacturers,
        dataDeviceModels: action.payload.deviceModels,
        dataDeviceModelSeries: action.payload.deviceModelSeries,
        dataDeviceTypes: action.payload.deviceTypes,
        dataOrganizationLocations: action.payload.organizationLocations,
        dataOrganizations: action.payload.organizations,
        isInitializing: false,
      };

    // SUBMIT
    case DEVICE_SUBMIT:
      return {
        ...state,
        isSubmitting: true,
      };
    case DEVICE_SUBMIT_ERROR:
      return {
        ...state,
        errors: action.payload,
        isSubmitting: false,
      };
    case DEVICE_SUBMIT_SUCCESS:
      return {
        ...state,
        errors: [],
        isSubmitting: false,
      };

    default:
      return state;
  }
};

export default device;
