import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import PropTypes from "prop-types";
import React, { Component } from "react";

class ContentHeaderTitle extends Component {
  render() {
    const { icon, title } = this.props;

    return (
      <h1>
        <FontAwesomeIcon icon={icon} fixedWidth />
        {title}
      </h1>
    );
  }
}

ContentHeaderTitle.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
};

export default ContentHeaderTitle;
