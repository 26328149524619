import { Field, Form } from "lemo-form";
import { IsNotEmpty } from "lemo-validator";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "reactstrap/es/Modal";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import ModalHeader from "reactstrap/es/ModalHeader";
import { connect } from "react-redux";

// App
import { AlertErrors, ButtonCancel, ButtonUpload } from "../../core/components";
import { ERROR_FILE_GREATER_MB, ERROR_FILE_MISMATCH_EXTENSION, ERROR_FILE_MISMATCH_MIMETYPE } from "../../../constants";
import { sprintf } from "../../../utilities";

// Device
import { clearModal, closeModal, initModal, submitModal } from "../actions/deviceImport";
import { fetchImports } from "../actions/deviceImports";

const DeviceModalImport = (props) => {
  const { t } = useTranslation();

  // Nacteme si props
  const { modalDataParsers, modalErrors, modalIsOpen, modalIsSubmitting } = props;

  useEffect(() => {
    props.modalInit(props.deviceId);

    return () => {
      props.modalClear();
    };
  }, []);

  const handleSubmit = async (formApi) => {
    let values = formApi.getValues();
    values.idDevice = props.modalDataId;

    await props.modalSubmit(values);

    if (true === props.reloadImports) {
      await props.fetchImports(values.idDevice);
    }
  };

  const handleToggle = (e) => {
    e.preventDefault();

    props.modalClose();
  };

  const handleValidate = (formApi) => {
    if (formApi.hasValue("fileMetadata")) {
      const file = formApi.getValue("fileMetadata");
      const fileSize = file.size;
      const fileType = file.type;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      const allowedExtensions = ["txt"];
      const allowedMimeTypes = ["text/plain"];

      if (fileSize > 5000000) {
        formApi.setError("file", sprintf(ERROR_FILE_GREATER_MB, 5));
      }
      if (!allowedExtensions.includes(fileExtension)) {
        formApi.setError("file", sprintf(ERROR_FILE_MISMATCH_EXTENSION, ".txt"));
      }
      if (!allowedMimeTypes.includes(fileType)) {
        formApi.setError("file", sprintf(ERROR_FILE_MISMATCH_MIMETYPE, "TXT"));
      }
    }
  };

  if (!modalIsOpen) {
    return null;
  }

  return (
    <Modal backdrop="static" isOpen={modalIsOpen} toggle={handleToggle}>
      <Form encType="multipart/form-data" onSubmit={handleSubmit} onValidate={handleValidate}>
        <ModalHeader>Nahrání nového souboru</ModalHeader>
        <ModalBody>
          <AlertErrors errors={modalErrors} />
          <Field label="Soubor (TXT)" name="file" required type="file" validators={[new IsNotEmpty()]} />
          <Field
            label="Parser"
            name="idParser"
            options={modalDataParsers}
            optionsKey="id"
            optionsValue="name"
            required
            validators={[new IsNotEmpty()]}
            type="select"
          />
        </ModalBody>
        <ModalFooter>
          <ButtonUpload color="success" executing={modalIsSubmitting} text={t("action.upload")} />{" "}
          <ButtonCancel disabled={modalIsSubmitting} onClick={handleToggle} text={t("action.cancel")} />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

DeviceModalImport.defaultProps = {
  reloadImports: false,
};

DeviceModalImport.propTypes = {
  deviceId: PropTypes.string.isRequired,
  fetchImports: PropTypes.func.isRequired,
  modalClear: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
  modalDataId: PropTypes.number,
  modalDataParsers: PropTypes.array.isRequired,
  modalErrors: PropTypes.array.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  modalIsSubmitting: PropTypes.bool.isRequired,
  modalInit: PropTypes.func.isRequired,
  modalSubmit: PropTypes.func.isRequired,
  reloadImports: PropTypes.bool,
};

const mapDispatchToProps = {
  modalClear: clearModal,
  modalClose: closeModal,
  modalInit: initModal,
  modalSubmit: submitModal,
  fetchImports,
};

const mapStateToProps = (state) => {
  return {
    modalDataId: state.deviceImport.modalDataId,
    modalDataParsers: state.deviceImport.modalDataParsers,
    modalErrors: state.deviceImport.modalErrors,
    modalIsOpen: state.deviceImport.modalIsOpen,
    modalIsSubmitting: state.deviceImport.modalIsSubmitting,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceModalImport);
