import jwt from "jsonwebtoken";

// Auth
import {
  AUTH_FORGOTPASSWORD_CLEAR,
  AUTH_FORGOTPASSWORD_REQUEST,
  AUTH_FORGOTPASSWORD_RESPONSE_ERROR,
  AUTH_FORGOTPASSWORD_RESPONSE_SUCCESS,
  AUTH_REFRESH_REQUEST,
  AUTH_REFRESH_RESPONSE_ERROR,
  AUTH_REFRESH_RESPONSE_SUCCESS,
  AUTH_RESETPASSWORD_CLEAR,
  AUTH_RESETPASSWORD_REQUEST,
  AUTH_RESETPASSWORD_RESPONSE_ERROR,
  AUTH_RESETPASSWORD_RESPONSE_SUCCESS,
  AUTH_SIGNIN_CLEAR,
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_RESPONSE_ERROR,
  AUTH_SIGNIN_RESPONSE_SUCCESS,
  AUTH_SIGNOUT_RESPONSE_SUCCESS,
  AUTH_VERIFY_REQUEST,
  AUTH_VERIFY_RESPONSE_ERROR,
  AUTH_VERIFY_RESPONSE_SUCCESS,
} from "../constants/actionTypes";

let identity = {};
let identityAuthenticated = false;
let identityBlocked = false;
let identityPermissions = [];
let identityVerified = false;

if (localStorage.getItem("jwtAuth") && localStorage.getItem("jwtIdentity")) {
  try {
    let authDecoded = jwt.verify(localStorage.getItem("jwtAuth"), process.env.REACT_APP_JWT);
    let identityDecoded = jwt.verify(localStorage.getItem("jwtIdentity"), process.env.REACT_APP_JWT);

    if (authDecoded.data.id === identityDecoded.data.id) {
      identity = {
        id: identityDecoded.data.id,
        idRoles: identityDecoded.data.idRoles,
        email: identityDecoded.data.email,
        firstname: identityDecoded.data.firstname,
        lastname: identityDecoded.data.lastname,
      };
      identityAuthenticated = true;
      identityPermissions = identityDecoded.data.permissions;
    }
  } catch (err) {
    console.log(err);
    localStorage.removeItem("jwtAuth");
    localStorage.removeItem("jwtIdentity");
  }
}

const initialState = {
  forgotPasswordErrors: [],
  forgotPasswordSubmitting: false,
  forgotPasswordSubmittingResult: false,
  identity: identity,
  identityAuthenticated: identityAuthenticated,
  identityBlocked: identityBlocked,
  identityPermissions: identityPermissions,
  identitySigned: !!identity,
  identityVerified: identityVerified,
  refreshErrors: [],
  refreshSubmitting: false,
  resetPasswordErrors: [],
  resetPasswordSubmitting: false,
  resetPasswordSubmittingResult: false,
  signInErrors: [],
  signInSubmitting: false,
  verifyErrors: [],
  verifySubmitting: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    // FORGOT PASSWORD
    case AUTH_FORGOTPASSWORD_CLEAR:
      return {
        ...state,
        forgotPasswordErrors: [],
        forgotPasswordSubmitting: false,
        forgotPasswordSubmittingResult: false,
      };
    case AUTH_FORGOTPASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordSubmitting: true,
        forgotPasswordSubmittingResult: false,
      };
    case AUTH_FORGOTPASSWORD_RESPONSE_ERROR:
      return {
        ...state,
        forgotPasswordErrors: action.payload,
        forgotPasswordSubmitting: false,
        forgotPasswordSubmittingResult: false,
      };
    case AUTH_FORGOTPASSWORD_RESPONSE_SUCCESS:
      return {
        ...state,
        forgotPasswordErrors: [],
        forgotPasswordSubmitting: false,
        forgotPasswordSubmittingResult: true,
      };

    // REFRESH
    case AUTH_REFRESH_REQUEST:
      return {
        ...state,
        refreshErrros: [],
        refreshSubmitting: true,
      };
    case AUTH_REFRESH_RESPONSE_ERROR:
      return {
        ...state,
        refreshErrros: action.payload,
        refreshSubmitting: true,
      };
    case AUTH_REFRESH_RESPONSE_SUCCESS:
      const refreshDecoded = action.payload.identityDecoded;

      localStorage.setItem("jwtAuth", action.payload.auth);
      localStorage.setItem("jwtIdentity", action.payload.identity);

      return {
        ...state,
        identity: {
          id: refreshDecoded.id,
          idRoles: refreshDecoded.idRoles,
          email: refreshDecoded.email,
          firstname: refreshDecoded.firstname,
          lastname: refreshDecoded.lastname,
        },
        identityAuthenticated: true,
        identityBlocked: false,
        identityPermissions: refreshDecoded.permissions,
        refreshErrros: [],
        refreshSubmitting: false,
      };

    // RESET PASSWORD
    case AUTH_RESETPASSWORD_CLEAR:
      return {
        ...state,
        resetPasswordErrors: [],
        resetPasswordSubmittingResult: false,
      };
    case AUTH_RESETPASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordSubmitting: true,
        resetPasswordSubmittingResult: false,
      };
    case AUTH_RESETPASSWORD_RESPONSE_ERROR:
      return {
        ...state,
        resetPasswordErrors: action.payload,
        resetPasswordSubmitting: false,
        resetPasswordSubmittingResult: false,
      };
    case AUTH_RESETPASSWORD_RESPONSE_SUCCESS:
      return {
        ...state,
        resetPasswordErrors: [],
        resetPasswordSubmitting: true,
        resetPasswordSubmittingResult: true,
      };

    // SIGN IN
    case AUTH_SIGNIN_CLEAR:
      return {
        ...state,
        signInErrors: [],
        signInSubmitting: false,
      };
    case AUTH_SIGNIN_REQUEST:
      return {
        ...state,
        identityAuthenticated: false,
        identityBlocked: false,
        signInErrors: [],
        signInSubmitting: true,
      };
    case AUTH_SIGNIN_RESPONSE_SUCCESS:
      const identityDecoded = action.payload.identityDecoded;

      localStorage.setItem("jwtAuth", action.payload.auth);
      localStorage.setItem("jwtIdentity", action.payload.identity);

      return {
        ...state,
        identity: {
          id: identityDecoded.id,
          idRoles: identityDecoded.idRoles,
          email: identityDecoded.email,
          firstname: identityDecoded.firstname,
          lastname: identityDecoded.lastname,
        },
        identityAuthenticated: true,
        identityBlocked: false,
        identitySigned: true,
        identityPermissions: identityDecoded.permissions,
        signInErrors: [],
        signInSubmitting: false,
      };
    case AUTH_SIGNIN_RESPONSE_ERROR:
      return {
        ...state,
        identity: {},
        identityAuthenticated: false,
        identityBlocked: false,
        identitySigned: false,
        signInErrors: action.payload,
        signInSubmitting: false,
      };

    // SIGN OUT
    case AUTH_SIGNOUT_RESPONSE_SUCCESS:
      localStorage.removeItem("jwtAuth");
      localStorage.removeItem("jwtIdentity");

      return {
        ...state,
        identity: {},
        identityAuthenticated: false,
        identityBlocked: false,
        identityPermissions: [],
        identitySigned: false,
        identityVerified: false,
        identityToken: null,
      };

    // VERIFY
    case AUTH_VERIFY_REQUEST:
      return {
        ...state,
        verifySubmitting: true,
      };
    case AUTH_VERIFY_RESPONSE_ERROR:
      return {
        ...state,
        verifyErrors: action.payload,
        verifySubmitting: false,
      };
    case AUTH_VERIFY_RESPONSE_SUCCESS:
      return {
        ...state,
        verifyErrors: [],
        verifySubmitting: false,
      };

    default:
      return state;
  }
};

export default auth;
