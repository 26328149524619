import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// Auth
import { AUTH_SIGNOUT_RESPONSE_SUCCESS } from "./modules/auth/constants/actionTypes";

// Reducers
import appConfig from "./modules/core/reducers/appConfig";
import auth from "./modules/auth/reducers/auth";
import dashboardAccessRules from "./modules/dashboard/reducers/dashboardAccessRules";
import device from "./modules/device/reducers/device";
import deviceAccessRules from "./modules/device/reducers/deviceAccessRules";
import deviceGrid from "./modules/device/reducers/deviceGrid";
import deviceImport from "./modules/device/reducers/deviceImport";
import deviceImports from "./modules/device/reducers/deviceImports";
import devicePorts from "./modules/device/reducers/devicePorts";
import deviceTimeline from "./modules/device/reducers/deviceTimeline";
import organization from "./modules/organization/reducers/organization";
import organizationLocation from "./modules/organizationLocation/reducers/organizationLocation";
import role from "./modules/role/reducers/role";
import user from "./modules/user/reducers/user";

const reducers = combineReducers({
  appConfig,
  auth,
  dashboardAccessRules,
  device,
  deviceAccessRules,
  deviceGrid,
  deviceImport,
  deviceImports,
  devicePorts,
  deviceTimeline,
  organization,
  organizationLocation,
  role,
  user,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_SIGNOUT_RESPONSE_SUCCESS) {
    let newReducers = reducers(undefined, action);
    newReducers.appConfig = state.appConfig;
    return newReducers;
  }

  return reducers(state, action);
};

export const store = createStore(rootReducer, applyMiddleware(thunk));
