import jwt from "jsonwebtoken";

// App
import { fetchPost } from "../../../utilities";
import { URI_AUTH_FORGOTPASSWORD, URI_AUTH_REFRESH, URI_AUTH_RESETPASSWORD, URI_AUTH_SIGNIN } from "../constants/uris";

// Auth
import {
  AUTH_REFRESH_REQUEST,
  AUTH_REFRESH_RESPONSE_ERROR,
  AUTH_REFRESH_RESPONSE_SUCCESS,
  AUTH_FORGOTPASSWORD_CLEAR,
  AUTH_FORGOTPASSWORD_REQUEST,
  AUTH_FORGOTPASSWORD_RESPONSE_ERROR,
  AUTH_FORGOTPASSWORD_RESPONSE_SUCCESS,
  AUTH_RESETPASSWORD_CLEAR,
  AUTH_RESETPASSWORD_REQUEST,
  AUTH_RESETPASSWORD_RESPONSE_ERROR,
  AUTH_RESETPASSWORD_RESPONSE_SUCCESS,
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_CLEAR,
  AUTH_SIGNIN_RESPONSE_ERROR,
  AUTH_SIGNIN_RESPONSE_SUCCESS,
  AUTH_SIGNOUT_RESPONSE_SUCCESS,
} from "../constants/actionTypes";
import { generatePath } from "react-router";

export const authRefresh = () => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_REFRESH_REQUEST,
    });

    await fetchPost(URI_AUTH_REFRESH)
      .then(async (res) => {
        if (res.ok) {
          let authDecoded = null;
          let identityDecoded = null;

          try {
            authDecoded = jwt.verify(res.body.auth, process.env.REACT_APP_JWT);
            identityDecoded = jwt.verify(res.body.identity, process.env.REACT_APP_JWT);
          } catch (err) {
            console.log(err);
            return dispatch({
              type: AUTH_REFRESH_RESPONSE_ERROR,
              payload: ["Invalid JWT token."],
            });
          }

          await dispatch({
            type: AUTH_REFRESH_RESPONSE_SUCCESS,
            payload: {
              auth: res.body.auth,
              authDecoded: authDecoded.data,
              identity: res.body.identity,
              identityDecoded: identityDecoded.data,
            },
          });
        } else {
          dispatch({
            type: AUTH_REFRESH_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: AUTH_REFRESH_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

export const forgotPasswordClear = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH_FORGOTPASSWORD_CLEAR,
    });
  };
};

export const forgotPasswordSubmit = (data) => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_FORGOTPASSWORD_REQUEST,
    });

    await fetchPost(URI_AUTH_FORGOTPASSWORD, data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: AUTH_FORGOTPASSWORD_RESPONSE_SUCCESS,
          });
        } else {
          dispatch({
            type: AUTH_FORGOTPASSWORD_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: AUTH_FORGOTPASSWORD_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

export const resetPasswordClear = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH_RESETPASSWORD_CLEAR,
    });
  };
};

export const resetPasswordSubmit = (hash, data) => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_RESETPASSWORD_REQUEST,
    });

    await fetchPost(generatePath(URI_AUTH_RESETPASSWORD, { hash: hash }), data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: AUTH_RESETPASSWORD_RESPONSE_SUCCESS,
          });
        } else {
          dispatch({
            type: AUTH_RESETPASSWORD_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: AUTH_FORGOTPASSWORD_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

export const signInClear = () => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_SIGNIN_CLEAR,
    });
  };
};

export const signInSubmit = (data) => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_SIGNIN_REQUEST,
    });

    await fetchPost(URI_AUTH_SIGNIN, data)
      .then(async (res) => {
        if (res.ok) {
          let authDecoded = null;
          let identityDecoded = null;

          try {
            authDecoded = jwt.verify(res.body.auth, process.env.REACT_APP_JWT);
            identityDecoded = jwt.verify(res.body.identity, process.env.REACT_APP_JWT);
          } catch (err) {
            console.log("SIGNIN:", err);
            return dispatch({
              type: AUTH_SIGNIN_RESPONSE_ERROR,
              payload: ["Invalid JWT token."],
            });
          }

          await dispatch({
            type: AUTH_SIGNIN_RESPONSE_SUCCESS,
            payload: {
              auth: res.body.auth,
              authDecoded: authDecoded.data,
              identity: res.body.identity,
              identityDecoded: identityDecoded.data,
            },
          });
        } else {
          dispatch({
            type: AUTH_SIGNIN_RESPONSE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: AUTH_SIGNIN_RESPONSE_ERROR,
          payload: [err.message],
        });
      });
  };
};

export const signOutSubmit = () => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_SIGNOUT_RESPONSE_SUCCESS,
    });
  };
};
