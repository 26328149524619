import { generatePath } from "react-router";
import { fetchGet, fetchPatch } from "../../../utilities";

// Constants
import {
  DEVICEACCESSRULES_SUBMIT,
  DEVICEACCESSRULES_SUBMIT_ERROR,
  DEVICEACCESSRULES_SUBMIT_OK,
  DEVICEACCESSRULES_CLEAR,
  DEVICEACCESSRULES_FETCH,
  DEVICEACCESSRULES_FETCH_ERROR,
  DEVICEACCESSRULES_FETCH_OK,
  DEVICEACCESSRULES_REFRESH,
  DEVICEACCESSRULES_REFRESH_ERROR,
  DEVICEACCESSRULES_REFRESH_OK,
} from "../constants/actionTypes";
import { URI_DEVICE_X_ACCESSRULE, URI_DEVICE_X_ACCESSRULE_REFRESH, URI_DEVICE_X_ACCESSRULE_X } from "../constants/uris";

// Clear
export const clearAccessRules = () => {
  return (dispatch) => {
    dispatch({
      type: DEVICEACCESSRULES_CLEAR,
    });
  };
};

// Fetch
export const fetchAccessRules = (deviceId) => {
  return (dispatch, getState) => {
    const state = getState();

    if (false === state.deviceAccessRules.isFetching) {
      dispatch({
        type: DEVICEACCESSRULES_FETCH,
      });

      fetchGet(generatePath(URI_DEVICE_X_ACCESSRULE, { id: deviceId }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICEACCESSRULES_FETCH_OK,
              payload: {
                items: res.body,
              },
            });
          } else {
            dispatch({
              type: DEVICEACCESSRULES_FETCH_ERROR,
              payload: {
                errors: res.body,
              },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICEACCESSRULES_FETCH_ERROR,
            payload: {
              errors: [err.message],
            },
          });
        });
    }
  };
};

// REFRESH
export const refreshAccessRules = (deviceId) => {
  return (dispatch, getState) => {
    const state = getState();

    if (false === state.deviceAccessRules.isRefreshing) {
      dispatch({
        type: DEVICEACCESSRULES_REFRESH,
      });

      fetchGet(generatePath(URI_DEVICE_X_ACCESSRULE_REFRESH, { id: deviceId }))
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICEACCESSRULES_REFRESH_OK,
              payload: {
                items: res.body,
              },
            });
          } else {
            dispatch({
              type: DEVICEACCESSRULES_REFRESH_ERROR,
              payload: {
                errors: res.body,
              },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICEACCESSRULES_REFRESH_ERROR,
            payload: {
              errors: [err.message],
            },
          });
        });
    }
  };
};

// UPDATE
export const updateAccessRule = (deviceId, id, data) => {
  return async (dispatch) => {
    dispatch({
      type: DEVICEACCESSRULES_SUBMIT,
      payload: {
        id: id,
      },
    });

    await fetchPatch(generatePath(URI_DEVICE_X_ACCESSRULE_X, { id: deviceId, accessRuleId: id }), data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICEACCESSRULES_SUBMIT_OK,
            payload: {
              id: id,
              item: data,
            },
          });
        } else {
          dispatch({
            type: DEVICEACCESSRULES_SUBMIT_ERROR,
            payload: {
              id: id,
              errors: res.body,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICEACCESSRULES_SUBMIT_ERROR,
          payload: {
            id: id,
            errors: [err.message],
          },
        });
      });
  };
};
