import React, { Fragment } from "react";
import Alert from "reactstrap/es/Alert";

const ForgotPasswordSuccess = () => {
  return (
    <Fragment>
      <Alert className="text-center" color="success" transition={{ appear: false, timeout: 0 }}>
        Na zadaný e-mail jsme odeslali odkaz pro nastavení nového hesla.
      </Alert>
      Pokud vám nebude zpráva do několika minut doručena, ujistěte se, že jste zadali správný e-mail pro přístup do
      Klintské zóny.
    </Fragment>
  );
};

export default ForgotPasswordSuccess;
