import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faPencilAlt } from "@fortawesome/pro-regular-svg-icons/faPencilAlt";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faSave } from "@fortawesome/pro-regular-svg-icons/faSave";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import React from "react";

// App
import Button from "./Button";
import ButtonConfirm from "./ButtonConfirm";

export { Button, ButtonConfirm };

export function ButtonAdd(props) {
  return <Button icon={faPlus} {...props} />;
}

export function ButtonCancel(props) {
  return <Button icon={faTimes} {...props} />;
}

export function ButtonDownload(props) {
  return <Button icon={faDownload} {...props} />;
}

export function ButtonDelete(props) {
  return <Button color="danger" icon={faTrashAlt} {...props} />;
}

export function ButtonDeleteConfirm(props) {
  return (
    <ButtonConfirm
      buttonYesColor="danger"
      buttonYesIcon={faTrashAlt}
      buttonYesText="Smazat"
      buttonNoIcon={faTimes}
      buttonNoText="Zrušit"
      icon={faTrashAlt}
      modalTitle="Potvrzení"
      {...props}
    />
  );
}

export function ButtonDetail(props) {
  return <Button icon={faSearch} {...props} />;
}

export function ButtonEdit(props) {
  return <Button icon={faPencilAlt} {...props} />;
}

export function ButtonSave(props) {
  return <Button color="success" icon={faSave} type="submit" {...props} />;
}

export function ButtonUpload(props) {
  return <Button icon={faUpload} {...props} />;
}
