import { faBracketsCurly } from "@fortawesome/pro-regular-svg-icons/faBracketsCurly";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faEthernet } from "@fortawesome/pro-regular-svg-icons/faEthernet";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import CardBodyHeader from "../../core/components/shared/CardBodyHeader";
import { ROUTE_DEVICE } from "../../../constants";
import { DEVICE_TYPE_FIREWALL } from "../../../constants/codeLists";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Device
import { clearDevice, createDevice, initDevice } from "../actions/device";
import FieldsetAPI from "../components/FieldsetAPI";
import FieldsetGeneral from "../components/FieldsetGeneral";
import FieldsetOrganization from "../components/FieldsetOrganization";
import { PERMISSION_DEVICE_CREATE } from "../constants/permissions";

const DevicePageAdd = (props) => {
  const {
    dataDeviceManufacturers,
    dataDeviceModels,
    dataDeviceModelSeries,
    dataDeviceTypes,
    dataOrganizationLocations,
    dataOrganizations,
    deviceErrors,
    handleSubmit,
    isSubmitting,
    match,
    setFieldValue,
    values,
  } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.devices");

    props.initDevice();

    return () => {
      props.clearDevice();
    };
  }, []);

  // Aktualizace formulare pri zmene ID organizace
  useEffect(() => {
    props.initDevice(values, match.params.id);
  }, [values.idDeviceManufacturer, values.idDeviceModelSeries, values.idDeviceType, values.idOrganization]);

  // Aktualizace formulare pri zmene ID type
  useEffect(() => {
    if (DEVICE_TYPE_FIREWALL !== values.idDeviceType) {
      setFieldValue("apiUri", "");
      setFieldValue("apiUsernameRead", "");
      setFieldValue("apiUsernameWrite", "");
      setFieldValue("apiPasswordRead", "");
      setFieldValue("apiPasswordWrite", "");
    }
  }, [values.idDeviceType]);

  // Kontrola opravneni
  if (!hasPermission(PERMISSION_DEVICE_CREATE)) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faEthernet} title={t("title.devices")} />
        <ContentHeaderButtons>
          <ButtonSave disabled={isSubmitting} executing={isSubmitting} form="form-devices" text={t("action.save")} />
          <ButtonCancel text={t("action.cancel")} to={ROUTE_DEVICE} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={deviceErrors} />

      <ContentBody>
        <FormikForm id="form-devices" noValidate={true} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardBodyHeader icon={faFile} title={t("title.general")} />
                  <FieldsetGeneral
                    dataDeviceManufacturers={dataDeviceManufacturers}
                    dataDeviceModels={dataDeviceModels}
                    dataDeviceModelSeries={dataDeviceModelSeries}
                    dataDeviceTypes={dataDeviceTypes}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardBodyHeader icon={faBuilding} title={t("title.location")} />
                  <FieldsetOrganization
                    dataOrganizationLocations={dataOrganizationLocations}
                    dataOrganizations={dataOrganizations}
                  />
                </CardBody>
              </Card>
              {DEVICE_TYPE_FIREWALL === values.idDeviceType && (
                <Card>
                  <CardBody>
                    <CardBodyHeader icon={faBracketsCurly} title={t("title.api")} />
                    <FieldsetAPI passwordRequired={true} />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </FormikForm>
      </ContentBody>
    </Fragment>
  );
};

DevicePageAdd.propTypes = {
  clearDevice: PropTypes.func.isRequired,
  createDevice: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dataDeviceManufacturers: PropTypes.array.isRequired,
  dataDeviceModels: PropTypes.array.isRequired,
  dataDeviceModelSeries: PropTypes.array.isRequired,
  dataDeviceTypes: PropTypes.array.isRequired,
  dataOrganizationLocations: PropTypes.array.isRequired,
  dataOrganizations: PropTypes.array.isRequired,
  deviceErrors: PropTypes.array.isRequired,
  initDevice: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

const DevicePageAddFormik = withFormik({
  mapPropsToValues: () => ({
    apiUri: "",
    apiUsernameRead: "",
    apiUsernameWrite: "",
    apiPasswordRead: "",
    apiPasswordWrite: "",
    idDeviceManufacturer: "",
    idDeviceModel: "",
    idDeviceModelSeries: "",
    idDeviceType: "",
    idOrganization: "",
    idOrganizationLocation: "",
    mac: "",
    name: "",
    sn: "",
  }),
  handleSubmit: async (values, { props }) => {
    props.createDevice(values).then(() => {
      if (0 === props.deviceErrors.length) {
        props.history.push(ROUTE_DEVICE);
      }
    });
  },
})(DevicePageAdd);

const mapDispatchToProps = {
  clearDevice,
  createDevice,
  initDevice,
};

const mapStateToProps = (state) => ({
  data: state.device.data,
  dataDeviceManufacturers: state.device.dataDeviceManufacturers,
  dataDeviceModels: state.device.dataDeviceModels,
  dataDeviceModelSeries: state.device.dataDeviceModelSeries,
  dataDeviceTypes: state.device.dataDeviceTypes,
  dataOrganizationLocations: state.device.dataOrganizationLocations,
  dataOrganizations: state.device.dataOrganizations,
  deviceErrors: state.device.errors,
  isSubmitting: state.device.isSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicePageAddFormik);
