import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// Components
import DeviceTimelineFilter from "../../containers/DeviceTimelineFilter";
import DeviceTimelineRows from "../../containers/DeviceTimelineRows";

function Timeline(props) {
  const { deviceId } = props;

  return (
    <Fragment>
      <Row>
        <Col>
          <DeviceTimelineFilter deviceId={deviceId} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DeviceTimelineRows deviceId={deviceId} />
        </Col>
      </Row>
    </Fragment>
  );
}

Timeline.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

export default Timeline;
