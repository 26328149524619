import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonAdd,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { ROUTE_ORGANIZATIONLOCATION_ADD } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// Organization location
import {
  gridChangeFilter,
  gridChangePagination,
  gridChangeSort,
  gridDelete,
  gridFetch,
} from "../actions/organizationLocation";
import { OrganizationLocationGrid } from "../components";

const OrganizationLocationPageList = (props) => {
  const { gridErrors } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.organizationLocations");
  }, []);

  // Kontrola opravneni
  if (!hasPermission("organizationLocation.view")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faBuilding} title={t("title.organizationLocations")} />
        <ContentHeaderButtons>
          {hasPermission("organizationLocation.create") && (
            <ButtonAdd to={ROUTE_ORGANIZATIONLOCATION_ADD} text={t("action.add")} />
          )}
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={gridErrors} />

      <ContentBody>
        <Row>
          <Col>
            <Card>
              <OrganizationLocationGrid {...props} />
            </Card>
          </Col>
        </Row>
      </ContentBody>
    </Fragment>
  );
};

OrganizationLocationPageList.propTypes = {
  gridChangeFilter: PropTypes.func.isRequired,
  gridChangePagination: PropTypes.func.isRequired,
  gridChangeSort: PropTypes.func.isRequired,
  gridData: PropTypes.array.isRequired,
  gridDataTotal: PropTypes.number.isRequired,
  gridDelete: PropTypes.func.isRequired,
  gridErrors: PropTypes.array.isRequired,
  gridIsDeleting: PropTypes.bool.isRequired,
  gridIsFetching: PropTypes.bool.isRequired,
  gridFetch: PropTypes.func.isRequired,
  gridPage: PropTypes.number.isRequired,
  gridPageSize: PropTypes.number.isRequired,
  gridSortField: PropTypes.string.isRequired,
  gridSortOrder: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  gridChangeFilter,
  gridChangePagination,
  gridChangeSort,
  gridDelete,
  gridFetch,
};

const mapStateToProps = (state) => ({
  gridData: state.organizationLocation.gridData,
  gridDataTotal: state.organizationLocation.gridDataTotal,
  gridErrors: state.organizationLocation.gridErrors,
  gridIsDeleting: state.organizationLocation.gridIsDeleting,
  gridIsFetching: state.organizationLocation.gridIsFetching,
  gridPage: state.organizationLocation.gridPage,
  gridPageSize: state.organizationLocation.gridPageSize,
  gridSortField: state.organizationLocation.gridSortField,
  gridSortOrder: state.organizationLocation.gridSortOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLocationPageList);
