import { Field, getIn } from "formik";
import PropTypes from "prop-types";
import React from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Input from "reactstrap/es/Input";
import InputGroup from "reactstrap/es/InputGroup";

import FieldAddon from "./FieldAddon";
import FieldFeedback from "./FieldFeedback";
import FieldLabel from "./FieldLabel";
import FieldText from "./FieldText";

const FormikFieldSelect = (props) => {
  const {
    addonAppend,
    addonPrepend,
    disabledIfEmptyOptions,
    formik,
    label,
    name,
    normalize,
    onChange,
    options,
    optionsEmpty,
    optionsKeyLabel,
    optionsKeyValue,
    required,
    text,
    validate,
    visible,
    ...attributes
  } = props;

  if (!visible) {
    return null;
  }

  let disabled = props.disabled;
  if (disabledIfEmptyOptions && 0 === options.length) {
    disabled = true;
  }

  let id = props.id || name;

  return (
    <Field
      name={name}
      validate={validate}
      render={({ field, form: formik }) => {
        const error = getIn(formik.errors, name);
        const touched = getIn(formik.touched, name);

        return (
          <FormGroup>
            <FieldLabel for={id} label={label} required={required} />
            <InputGroup>
              {addonPrepend && <FieldAddon {...addonPrepend} addonType="prepend" />}
              <Input
                {...field}
                {...attributes}
                disabled={disabled}
                id={id}
                invalid={touched && !!error}
                onChange={(e) => {
                  const val = normalize ? normalize(e.target.value) : e.target.value;
                  field.onChange(e.target.name)(val);
                }}
                // onChange={async e => {
                //   const selectedIndex = (true === optionsEmpty) ? e.target.selectedIndex-1 : e.target.selectedIndex;
                //
                //   await formik.handleChange(e);
                //   formik.setFieldValue(name, options[selectedIndex][optionsKeyValue], false);
                //
                //   if (typeof onChange === "function") {
                //     onChange(e);
                //   }
                // }}
                type="select"
              >
                {optionsEmpty && <option value="">-</option>}
                {options.map((option, index) => {
                  const optLabel = option[optionsKeyLabel];
                  const optValue = option[optionsKeyValue];

                  return (
                    <option value={optValue} key={index}>
                      {optLabel}
                    </option>
                  );
                })}
              </Input>
              {addonAppend && <FieldAddon {...addonAppend} addonType="append" />}
              {touched && error && error.length > 0 && <FieldFeedback error={error} />}
            </InputGroup>
            {text && <FieldText text={text} />}
          </FormGroup>
        );
      }}
    />
  );
};

FormikFieldSelect.defaultProps = {
  disabledIfEmptyOptions: true,
  options: [],
  optionsEmpty: true,
  optionsKeyLabel: "label",
  optionsKeyValue: "value",
  required: false,
  visible: true,
};
FormikFieldSelect.propTypes = {
  addonAppend: PropTypes.shape({
    children: PropTypes.node,
    icon: PropTypes.object,
    iconFixedWidth: PropTypes.bool,
    iconSize: PropTypes.string,
    iconSpin: PropTypes.bool,
    text: PropTypes.string,
  }),
  addonPrepend: PropTypes.shape({
    children: PropTypes.node,
    icon: PropTypes.object,
    iconFixedWidth: PropTypes.bool,
    iconSize: PropTypes.string,
    iconSpin: PropTypes.bool,
    text: PropTypes.string,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledIfEmptyOptions: PropTypes.bool,
  id: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  invalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  optionsEmpty: PropTypes.bool,
  optionsKeyLabel: PropTypes.string,
  optionsKeyValue: PropTypes.string,
  plaintext: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  valid: PropTypes.bool,
  visible: PropTypes.bool,
};

export default FormikFieldSelect;
