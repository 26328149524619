import { generatePath } from "react-router";

// App
import { fetchDelete, fetchGet, fetchPatch, fetchPost } from "../../../utilities";

// Device
import {
  DEVICE_CLEAR,
  DEVICE_DELETE,
  DEVICE_DELETE_ERROR,
  DEVICE_DELETE_SUCCESS,
  DEVICE_FETCH,
  DEVICE_FETCH_ERROR,
  DEVICE_FETCH_SUCCESS,
  DEVICE_INIT,
  DEVICE_INIT_ERROR,
  DEVICE_INIT_SUCCESS,
  DEVICE_SUBMIT,
  DEVICE_SUBMIT_ERROR,
  DEVICE_SUBMIT_SUCCESS,
} from "../constants/actionTypes";
import { URI_DEVICE, URI_DEVICE_X, URI_DEVICE_X_FORM } from "../constants/uris";

// CLEAR
export const clearDevice = () => {
  return (dispatch) => {
    dispatch({
      type: DEVICE_CLEAR,
    });
  };
};

// CREATE
export const createDevice = (data) => {
  return async (dispatch) => {
    dispatch({
      type: DEVICE_SUBMIT,
    });

    await fetchPost(URI_DEVICE, data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICE_SUBMIT_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: DEVICE_SUBMIT_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICE_SUBMIT_ERROR,
          payload: [err.message],
        });
      });
  };
};

// DELETE
export const deleteDevice = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DEVICE_DELETE,
    });

    await fetchDelete(generatePath(URI_DEVICE_X, { id: id }))
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICE_DELETE_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: DEVICE_DELETE_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICE_DELETE_ERROR,
          payload: [err.message],
        });
      });
  };
};

// FETCH
export const fetchDevice = (id, action = null) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.device.isFetching) {
      dispatch({
        type: DEVICE_FETCH,
      });

      let query = "";
      if (null !== action) {
        query = "?a=" + action;
      }

      await fetchGet(generatePath(URI_DEVICE_X, { id: id }) + query)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICE_FETCH_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: DEVICE_FETCH_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICE_FETCH_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// INIT
export const initDevice = (data, id = null) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (false === state.device.isInitializing) {
      dispatch({
        type: DEVICE_INIT,
      });

      if (null === id) {
        id = 0;
      }

      await fetchPost(generatePath(URI_DEVICE_X_FORM, { id: id }), data)
        .then((res) => {
          if (res.ok) {
            dispatch({
              type: DEVICE_INIT_SUCCESS,
              payload: res.body,
            });
          } else {
            dispatch({
              type: DEVICE_INIT_ERROR,
              payload: res.body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: DEVICE_INIT_ERROR,
            payload: [err.message],
          });
        });
    }
  };
};

// UPDATE
export const updateDevice = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: DEVICE_SUBMIT,
    });

    await fetchPatch(generatePath(URI_DEVICE_X, { id: id }), data)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: DEVICE_SUBMIT_SUCCESS,
            payload: res.body,
          });
        } else {
          dispatch({
            type: DEVICE_SUBMIT_ERROR,
            payload: res.body,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DEVICE_SUBMIT_ERROR,
          payload: [err.message],
        });
      });
  };
};
