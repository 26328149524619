// USER - Form
export const USER_FORM_CLEAR = "USER_FORM_CLEAR";
export const USER_FORM_INIT_REQUEST = "USER_FORM_INIT_REQUEST";
export const USER_FORM_INIT_RESPONSE_ERROR = "USER_FORM_INIT_RESPONSE_ERROR";
export const USER_FORM_INIT_RESPONSE_SUCCESS = "USER_FORM_INIT_RESPONSE_SUCCESS";
export const USER_FORM_FETCH_REQUEST = "USER_FORM_FETCH_REQUEST";
export const USER_FORM_FETCH_RESPONSE_ERROR = "USER_FORM_FETCH_RESPONSE_ERROR";
export const USER_FORM_FETCH_RESPONSE_SUCCESS = "USER_FORM_FETCH_RESPONSE_SUCCESS";
export const USER_FORM_SUBMIT_REQUEST = "USER_FORM_SUBMIT_REQUEST";
export const USER_FORM_SUBMIT_RESPONSE_ERROR = "USER_FORM_SUBMIT_RESPONSE_ERROR";
export const USER_FORM_SUBMIT_RESPONSE_SUCCESS = "USER_FORM_SUBMIT_RESPONSE_SUCCESS";

// USER - Grid
export const USER_GRID_CHANGE_FILTER = "USER_GRID_CHANGE_FILTER";
export const USER_GRID_CHANGE_PAGINATION = "USER_GRID_CHANGE_PAGINATION";
export const USER_GRID_CHANGE_SORT = "USER_GRID_CHANGE_SORT";
export const USER_GRID_DELETE_REQUEST = "USER_GRID_DELETE_REQUEST";
export const USER_GRID_DELETE_RESPONSE_ERROR = "USER_GRID_DELETE_RESPONSE_ERROR";
export const USER_GRID_DELETE_RESPONSE_SUCCESS = "USER_GRID_DELETE_RESPONSE_SUCCESS";
export const USER_GRID_FETCH_REQUEST = "USER_GRID_FETCH_REQUEST";
export const USER_GRID_FETCH_RESPONSE_ERROR = "USER_GRID_FETCH_RESPONSE_ERROR";
export const USER_GRID_FETCH_RESPONSE_SUCCESS = "USER_GRID_FETCH_RESPONSE_SUCCESS";
