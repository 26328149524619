import PropTypes from "prop-types";
import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginator from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";

// App
import { ButtonDeleteConfirm, ButtonEdit } from "../../core/components";
import { ROUTE_USER_EDIT } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

const Grid = (props) => {
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  async function handleChange(type, { filters, page, sizePerPage, sortField, sortOrder }) {
    switch (type) {
      case "filter":
        await props.gridChangeFilter(filters);
        break;
      case "pagination":
        await props.gridChangePagination(page, sizePerPage);
        break;
      case "sort":
        await props.gridChangeSort(sortField, sortOrder);
        break;
      default:
        throw Error("Unknow type");
    }

    props.gridFetch();
  }

  function handleDelete(id) {
    props.gridDelete(id).then(() => {
      props.gridFetch();
    });
  }

  // Columns
  const columns = [
    {
      dataField: "email",
      filter: textFilter(),
      sort: true,
      text: t("title.email"),
    },
    {
      dataField: "firstname",
      filter: textFilter(),
      sort: true,
      text: t("title.firstname"),
    },
    {
      dataField: "lastname",
      filter: textFilter(),
      sort: true,
      text: t("title.lastname"),
    },
    {
      dataField: "organizationName",
      filter: textFilter(),
      sort: true,
      text: t("title.organization"),
    },
    {
      align: "right",
      classes: "react-bootstrap-table-buttons",
      dataField: "id",
      filterable: false,
      isDummyField: true,
      formatter: (cellContent, row) => {
        return (
          <Fragment>
            {hasPermission("user.update") && <ButtonEdit to={generatePath(ROUTE_USER_EDIT, { id: row.id })} />}
            {hasPermission("user.delete") && (
              <ButtonDeleteConfirm
                buttonNoDisabled={props.gridIsDeleting}
                buttonYesDisabled={props.gridIsDeleting}
                buttonYesExecuting={props.gridIsDeleting}
                buttonYesOnClick={() => handleDelete(row.id)}
                modalBody={t("text.doYouReallyWantToDeleteThisItem")}
                title={t("action.delete")}
              />
            )}
          </Fragment>
        );
      },
      text: "",
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      columns={columns}
      data={props.gridData}
      defaultSorted={[
        {
          dataField: props.gridSortField,
          order: props.gridSortOrder,
        },
      ]}
      filter={filterFactory()}
      keyField="id"
      onTableChange={handleChange}
      pagination={paginator({
        firstPageTitle: t("action.goToFirst"),
        lastPageTitle: t("action.goToLast"),
        nextPageTitle: t("action.goToNext"),
        prePageTitle: t("action.goToPrevious"),
        page: props.gridPage,
        showTotal: false,
        sizePerPage: props.gridPageSize,
        totalSize: props.gridDataTotal,
      })}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
      }}
    />
  );
};

Grid.propTypes = {
  gridChangeFilter: PropTypes.func.isRequired,
  gridChangePagination: PropTypes.func.isRequired,
  gridChangeSort: PropTypes.func.isRequired,
  gridData: PropTypes.array.isRequired,
  gridDataTotal: PropTypes.number.isRequired,
  gridDelete: PropTypes.func.isRequired,
  gridFetch: PropTypes.func.isRequired,
  gridIsDeleting: PropTypes.bool.isRequired,
  gridPage: PropTypes.number.isRequired,
  gridPageSize: PropTypes.number.isRequired,
  gridSortField: PropTypes.string.isRequired,
  gridSortOrder: PropTypes.string.isRequired,
};

export default Grid;
