export const DASHBOARDACCESSRULE_CHECKSTATE_START = "DASHBOARDACCESSRULE_CHECKSTATE_START";
export const DASHBOARDACCESSRULE_CHECKSTATE_STOP = "DASHBOARDACCESSRULE_CHECKSTATE_STOP";

export const DASHBOARDACCESSRULES_CLEAR = "DASHBOARDACCESSRULES_CLEAR";

export const DASHBOARDACCESSRULES_FETCH = "DASHBOARDACCESSRULES_FETCH";
export const DASHBOARDACCESSRULES_FETCH_ERROR = "DASHBOARDACCESSRULES_FETCH_ERROR";
export const DASHBOARDACCESSRULES_FETCH_OK = "DASHBOARDACCESSRULES_FETCH_OK";

export const DASHBOARDACCESSRULES_SUBMIT = "DASHBOARDACCESSRULES_SUBMIT";
export const DASHBOARDACCESSRULES_SUBMIT_ERROR = "DASHBOARDACCESSRULES_SUBMIT_ERROR";
export const DASHBOARDACCESSRULES_SUBMIT_OK = "DASHBOARDACCESSRULES_SUBMIT_OK";
