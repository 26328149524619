import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

// App
import {
  ButtonCancel,
  ButtonSave,
  ContentBody,
  ContentErrors,
  ContentHeader,
  ContentHeaderButtons,
  ContentHeaderTitle,
  Error403,
} from "../../core/components";
import { FormikForm } from "../../core/components/formik";
import { ROUTE_USER } from "../../../constants";

// Auth
import { usePermission } from "../../auth/hooks/usePermission";

// User
import { formClear, formFetch, formInit, formSubmitUpdate } from "../actions/user";
import { UserAuthFieldset, UserBaseFieldset } from "../components";

const UserPageEdit = (props) => {
  const { formData, formDataOrganizations, formDataRoles, formErrors, formIsSubmitting, history, match } = props;
  const [hasPermission] = usePermission();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("title.users");
  }, []);

  useEffect(() => {
    props.formFetch(match.params.id).then(() => {
      props.formInit(formData, match.params.id);
    });

    return () => {
      props.formClear();
    };
  }, []);

  const handleSubmit = (values) => {
    props.formSubmitUpdate(values, match.params.id).then(() => {
      if (0 === props.formErrors.length) {
        history.push(ROUTE_USER);
      }
    });
  };

  // Kontrola opravneni
  if (!hasPermission("user.update")) {
    return <Error403 />;
  }

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeaderTitle icon={faUsers} title={t("title.users")} />
        <ContentHeaderButtons>
          <ButtonSave
            disabled={formIsSubmitting}
            executing={formIsSubmitting}
            form="form-users"
            text={t("action.save")}
          />
          <ButtonCancel text={t("action.cancel")} to={ROUTE_USER} />
        </ContentHeaderButtons>
      </ContentHeader>

      <ContentErrors errors={formErrors} />

      <ContentBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: formData.email || "",
            firstname: formData.firstname || "",
            idOrganization: formData.idOrganization || "",
            idRoles: formData.idRoles || [],
            lastname: formData.lastname || "",
            password: formData.password || "",
          }}
          onSubmit={handleSubmit}
          render={(formik) => (
            <FormikForm id="form-users" onSubmit={formik.handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <UserBaseFieldset formDataOrganizations={formDataOrganizations} />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <UserAuthFieldset passwordRequired={false} roles={formDataRoles} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </FormikForm>
          )}
        />
      </ContentBody>
    </Fragment>
  );
};

UserPageEdit.propTypes = {
  formClear: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formDataOrganizations: PropTypes.array.isRequired,
  formDataRoles: PropTypes.array.isRequired,
  formErrors: PropTypes.array.isRequired,
  formFetch: PropTypes.func.isRequired,
  formInit: PropTypes.func.isRequired,
  formIsFetching: PropTypes.bool.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
  formSubmitUpdate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  formClear,
  formFetch,
  formInit,
  formSubmitUpdate,
};

const mapStateToProps = (state) => ({
  formData: state.user.formData,
  formDataOrganizations: state.user.formDataOrganizations,
  formDataRoles: state.user.formDataRoles,
  formErrors: state.user.formErrors,
  formIsFetching: state.user.formIsFetching,
  formIsSubmitting: state.user.formIsSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPageEdit);
